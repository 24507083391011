import React, { useState, useEffect, useRef } from "react"
import { FaUnderline } from "react-icons/fa";
import CanvasType from "../canvasgraphics";

export default function HeadToHeadView({ mobile, setPopup, teams, games })
{
    const [isPopupOpen, setIsPopupOpen] = useState(true);
    const [h2hLevel, seth2hLevel] = useState(0)

    const canvasRef = useRef(null)

    const closePopup = () => {
        setIsPopupOpen(false);
        setPopup(0)
    }
  
    const Changeh2hLevel = (val) => {
        seth2hLevel(val)
      }
    let proposedWidth=650;
    let proposedHeight=750;


    useEffect(() => { // formerly DrawGraph()
    {   const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        const cv = new CanvasType (canvas, context)

        console.log ("UseEffect to begin drawing...")

        // context.fillStyle = 'red';
        // context.fillRect (50,50,100,100)
        DrawMap (cv);         

        DrawTeams(cv, teams);

        DrawGames(cv, games)
    }
    },[])
       
        return (
            <div className={mobile?"popup-noscroll":'popup-noscroll-canvas'}>
                <div className='flex-container'>
                    <div className='popup-item full-width  fixed-height'>
                        <button className="close-icon" onClick={closePopup}>&times;</button>
                        <h2 className="popup-item full-width no-margin">HEAD TO HEAD MAP</h2>
                    </div>

                    <canvas ref={canvasRef} width="1200" height="600" style={{border: '1px solid black'}} />
                </div> 
            </div> 
            )
}





function DrawLegend(cv, x, y, xoff, yoff)
{

}


function DrawMap(cv)
{	
    cv.ClearScreen ('#303030');
        
    var x1 = 90;
    var y1 = 30;
    var cw = cv.GetCanvasWidth()
    var ch = cv.GetCanvasHeight()
    console.log ("Height="+ch+" Width="+cw)
    var w = cw*0.90;

    var h = (ch-y1)*0.95;

    cv.DrawBox(x1+8, y1, h-18, h-12, 'white');
    cv.DrawBox(x1+8+h-18, y1, h-18, h-12, 'white');
    var xoff = 40; // (pcv.GetCanvasWidth()*0.9) / (wk+2);
    var yoff = 35; // h / nt;

    DrawLegend(cv, x1, y1, xoff, yoff)

    DrawTickMarks (cv, x1+10, y1, 15*5)

}

function DrawTickMarks (cv, x1, y1, delta)
{
    let font=" 10px Arial";
    let color = 'white'
    // for (let i=0; i<18*delta; i+=delta)
    //     cv.DrawLine (x1+i, y1, x1+i, y1-5, color)            

    for (let i=1; i<=35; i++)
    {
        cv.DrawText(i, x1-15, y1+i*15, font, color)  
        cv.DrawText(i, x1+(70*15), y1+i*15, font, color)  

    }

    cv.DrawText('1', x1, y1, font, color)  
    x1+=15;
    cv.DrawText('2', x1, y1, font, color)  
    x1+=15;
    cv.DrawText('3', x1, y1, font, color)  
    x1+=15;
    cv.DrawText('4', x1, y1, font, color)  
    x1+=15;
    cv.DrawText('5', x1, y1, font, color)  
    x1+=15;
    cv.DrawText('6', x1, y1, font, color)  
    x1+=15;
    cv.DrawText('7', x1, y1, font, color)  
    x1+=15;
    cv.DrawText('8', x1, y1, font, color)  
    x1+=15;
    cv.DrawText('9', x1, y1, font, color)  
    x1+=15;
    for (let i=10; i<75; i+=5)
    {
        cv.DrawText(i, x1, y1, font, color)  
        x1+=75;
    }

}

function DrawTeams(cv, teams)
{
    let font=" 10px Arial";
    let color='white';

    var x=5, y=30;

    for (let i=0; i<35; i++)
    {
        y = y + 15;
        cv.DrawText (teams[i], x, y, font, color)        
    }

}

function DrawGames (cv, games)
{
    for (let r=0; r<35; r++)
        DrawResults (cv, r+1, games[r])
}

function DrawResults (cv, rnk1, results)
{
    DrawDiagonal (cv, rnk1, rnk1, 'black');
    
    // DrawBox (cv, 0, 0, 'orange');
    // DrawBox (cv, 2, 3, 'blue');
    // DrawBox (cv, 3, 2, 'purple');

    if (results != undefined)
    {
        for (let i=0; i<results.length; i++)
        {            
            let rnk2  = results[i].OppRank;
            let score = results[i].Result;

            console.log (rnk1 + " vs " + rnk2)

            if (score[0] === ' ')      DrawBox (cv, rnk1, rnk2, 'white')    
            if (score[0] === 'W')      DrawBox (cv, rnk1, rnk2, 'green')    
            else if (score[0] === 'L') DrawBox (cv, rnk1, rnk2, 'red')          
        }

        // Make sure bad losses are showing
        for (let i=0; i<results.length; i++)
        {            
            let rnk2  = results[i].OppRank;
            let score = results[i].Result;
            if (rnk2 > 70 && score[0] === 'L') DrawBox (cv, rnk1, rnk2, 'red')
        }                  
    }

}

function DrawDiagonal (cv, rnk1, rnk2, color)
{
    let w    = 8;
    let h    = 8;
    let cw   = cv.GetCanvasWidth()
    let xoff = 85;
    let yoff = 23;
    var x    = xoff+rnk2*15
    let y    = yoff+rnk1*15;

    if (x > cw) x = cw-15;

    cv.DrawLine(x-4, y-4, x+9, y+9, color);
}

function DrawBox (cv, rnk1, rnk2, color)
{
    let w    = 8;
    let h    = 8;
    let cw   = cv.GetCanvasWidth()
    let xoff = 85;
    let yoff = 23;
    var x    = xoff+rnk2*15
    let y    = yoff+rnk1*15;

    if (x > cw) x = cw-15;
    if (rnk2 > 70) x = cw-15;

    cv.FillBox(x, y, w, h, color)
}


