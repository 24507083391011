const FCS=12

// create a team object with team methods
const TeamType = {
	  Name       : "aa",
	  TID         : 0,
	  BgColor      : 0,
	  FgColor      : 0,
	  Conference: "",
	  ConfID : 12,
	  Division   : 0,
	  Rank       : 999,
	  RosterName   : "ncaa",
	  Nickname   : "ncaa",
	  SafeName   : "ncaa",
	  Coach      : "coach-name",
	  Star       : "star-name",
	  Wins          : parseInt(0), 
	  Losses        : parseInt(0), 
	  GamesPlayed   : parseInt(0),
	  C_Wins        : parseInt(0), 
	  C_Losses      : parseInt(0), 
	  ATS_Wins      : parseInt(0), 
	  ATS_Losses    : parseInt(0), 
	  ATS_Pushes    : parseInt(0), 
	  PointsScored  : parseInt(0),
	  PointsAllowed : parseInt(0),
	  Percent       : parseFloat(0.5), 
	  Q1     : 0, 
	  Q2     : 0, 
	  Q3     : 0, 
	  Q4     : 0, 
	  L1     : 0, 
	  L2     : 0, 
	  L3     : 0, 
	  L4     : 0,
	  FBSteam : function() {return (this.ConfID !==  FCS);},
	  FCSteam : function() {return (this.ConfID ===  FCS);},
	  GetNameWithRank : function() { if (this.FCSteam()) return (this.Name + " (FCS)"); else return ("#" + this.Rank + " " + this.Name)},
	  GetImageName : function() {return "img/" + this.SafeName + ".png";},
	  GetRecord : function() {return (this.Wins + "-" + this.Losses)},
	  GetConferenceRecord : function() {return (this.C_Wins + "-" + this.C_Losses)},
	  GetAtsRecord : function() {return (this.ATS_Wins + "-" + this.ATS_Losses + "-" + this.ATS_Pushes)},
	  BumpQuadCount : function(quad) 	{
		  switch(quad)
		  {
			  case 1: this.Q1++; break;
			  case 2: this.Q2++; break;
			  case 3: this.Q3++; break;
			  case 4: this.Q4++; break;
			  default: break;
		  }	},
	  
	  BumpLossCount :function(quad) 	{
		  switch(quad)
		  {
			  case 1: this.L1++; break;
			  case 2: this.L2++; break;
			  case 3: this.L3++; break;
			  case 4: this.L4++; break;
			  default: break;
		  }	},
  
	  
	  CalcQscore :function() {
		  return       4*this.Q1 + (-1*this.L1) +
						3*this.Q2 + (-2*this.L2) +
						2*this.Q3 + (-3*this.L3) +
						1*this.Q4 + (-4*this.L4);}
	  };


export default TeamType;