import React from "react";

export default function HorizontalLine({left, right, len})
{
    return (
        <div
          style={{
            borderBottom: "3px solid green", // Green line with 3px thickness
            width: len+"px",
           // width: "calc(100% - "+len+"px)", // Line width with offsets
            marginLeft:  left+"px", // Left offset of 20px
            // marginRight: right+"px" // Right offset of 20px
//            marginTop: "1px", // Optional: Adds space above the line
          }}
        />
      )
}
