import React, { useState, useEffect } from "react"
import * as GlobalData from '../data';
import * as Images from '../images';

export default function Highlight ({placement, GID, TID, gameInfo})
{
    const [highlight, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
      useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_HIGHLIGHT&GID="+GID;
          console.log (url)
          const response = await fetch(url, {
            method: 'GET', // or 'POST', 'PUT', etc.
            headers: {
                'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                'Content-Type': 'application/json'
            }
        });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setData(result);
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      if (GID > 0) fetchData();
  
  }, [GID]);  
  
    if (loading) 
      return (<h2>Loading...</h2>);

    else if (error) 
      return (<h2>Error: {error}</h2>);
    else if (gameInfo.HomeTID === TID && gameInfo.HomeScore > gameInfo.AwayScore) // WINNING A HOME GAME
      return <HomeWin passHighlight={PassingHighlight(highlight.Passing, TID)} 
                      rushHighlight={RushingHighlight(highlight.Rushing, TID)} 
                      recvHighlight={ReceivingHighlight(highlight.Receiving, TID)} 
                      game={gameInfo} 
                      />        

    else if (gameInfo.AwayTID === TID && gameInfo.AwayScore > gameInfo.HomeScore) // WINNING A ROAD GAME
      return <RoadWin passHighlight={PassingHighlight(highlight.Passing, TID)} 
                      rushHighlight={RushingHighlight(highlight.Rushing, TID)} 
                      recvHighlight={ReceivingHighlight(highlight.Receiving, TID)} 
                      game={gameInfo} 
                      />   

    else if (gameInfo.HomeTID === TID && gameInfo.HomeScore < gameInfo.AwayScore) // LOSING A HOME GAME
      return <HomeLoss  passHighlight={PassingHighlight(highlight.Passing, TID)} 
                        rushHighlight={RushingHighlight(highlight.Rushing, TID)} 
                        recvHighlight={ReceivingHighlight(highlight.Receiving, TID)} 
                        game={gameInfo} 
                        />

    else if (gameInfo.AwayTID === TID && gameInfo.AwayScore < gameInfo.HomeScore) // LOSING A ROAD GAME
      return <RoadLoss  passHighlight={PassingHighlight(highlight.Passing, TID)} 
                        rushHighlight={RushingHighlight(highlight.Rushing, TID)} 
                        recvHighlight={ReceivingHighlight(highlight.Receiving, TID)} 
                        game={gameInfo} 
                        />        
    else  
      return (<h2>AI FAILURE...</h2>);
}



function FindBestPlayer (leaders, TID)
{
  var best=0, idx=0;

  for (let i=0; i<leaders.length; i++)
    if (leaders[i].TID === TID && leaders[i].Yds > best)
    {
       idx = i;
       best = leaders[i].Yds;
    }

    return idx;
}

function PassingHighlight (Passing, TID)
{
  if (Passing.length == 0) return '';

  let p = FindBestPlayer (Passing, TID);
  if (Passing[p].Yds > 100)
    return " QB " + Passing[p].CommonName + " threw for " + Passing[p].Yds + " yards,";
  else
    return '';
}

function RushingHighlight (Rushing, TID)
{
  if (Rushing.length == 0) return '';

  let r = FindBestPlayer (Rushing, TID);
  if (Rushing[r].Yds > 100)
    return " RB " + Rushing[r].CommonName + " rushed for " + Rushing[r].Yds + " yards,"
  else
    return '';
}

function ReceivingHighlight (Receiving, TID)
{
  if (Receiving.length == 0) return '';

  let v = FindBestPlayer (Receiving, TID);
  if (Receiving[v].Yds > 100 )
    return " WR " + Receiving[v].CommonName + " gained " + Receiving[v].Yds + " yards in the air,"
  else
    return '';
}

function HomeWin ({passHighlight, rushHighlight, recvHighlight, game})    
{
    // console.log ("Highlight...WINNING A HOME GAME")
    var str = game.HomeTeam;

    str = str + passHighlight;
    str = str + rushHighlight;
    str = str + recvHighlight;
    
    str = str + " in their home victory against " + game.AwayTeam + " " + game.HomeScore + "-" + game.AwayScore;       
 
    return ( <p className='lefty small-row'>{str}</p> )
}
  
function HomeLoss ({passHighlight, rushHighlight, recvHighlight, game})
{
  // console.log ("Highlight...LOSING A HOME GAME")
  var str = "Despite";
  
  // console.log (passHighlight)
  // console.log (rushHighlight)
  // console.log (recvHighlight)

  str = str + passHighlight;
  str = str + rushHighlight;
  str = str + recvHighlight;

  str = str + game.HomeTeam + " lost their home game against " + game.AwayTeam + " " + game.AwayScore + "-" + game.HomeScore;       

  return ( <p className='lefty small-row'>{str}</p> )
}

function RoadWin ({passHighlight, rushHighlight, recvHighlight, game})
{
  // console.log ("Highlight...WINNING A ROAD GAME")
  var str = game.AwayTeam;

  str = str + passHighlight;
  str = str + rushHighlight;
  str = str + recvHighlight;

  str = str + " in their road victory against " + game.HomeTeam + " " + game.AwayScore  + "-" + game.HomeScore;       

  return ( <p className='lefty small-row'>{str}</p> )
}


function RoadLoss ({passHighlight, rushHighlight, recvHighlight, game})
{
  // console.log ("Highlight...LOSING A ROAD GAME")
  var str = "Despite";

  str = str + passHighlight;
  str = str + rushHighlight;
  str = str + recvHighlight;
  
  str = str + game.AwayTeam + " lost their road game against " + game.HomeTeam + " " + game.HomeScore + "-" + game.AwayScore;       
  
  return ( <p className='lefty small-row'>{str}</p> )
}