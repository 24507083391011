import { MenuChoices } from "../MenuChoices";

export default function StatsSelector({mobile, statLevel, setStatLevel, confLabel, teamLabel})
{

    // console.log ("...SWS:  ScoresLevel = " + scoresLevel + "activeMenu=" + activeMenu)
    return (
        <div className={mobile?"box btn-container":"box btn-container-desktop"}>
            <button className={`${statLevel===0 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setStatLevel(0)} >FBS</button>
            <button className={`${statLevel===1 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setStatLevel(1)} >{confLabel}</button>
            <button className={`${statLevel===2 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setStatLevel(2)} >{teamLabel}</button>
        </div>
    )
}