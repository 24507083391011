import { MenuChoices } from '../MenuChoices';


export default function SDPview ({getPopup, GID})
{
    const handlePopupSelect = (event) => {
        event.preventDefault();
        console.log ("SDP: GID="+GID+ " Popup="+event.target.value)
        getPopup (event.target.value, GID)
    }

    return (
        <select className="khaki coalbk med-font bold"  value={MenuChoices.TEST5} onChange={handlePopupSelect}>
            <option value={0}>S/D/P</option>
            <option value={MenuChoices.GAME_STATS}>STATS</option>
            <option value={MenuChoices.DRIVES}>DRIVES</option>
            <option value={MenuChoices.PLAYS}>PLAYS</option>
        </select>
    )
}