const sqr3=1.73205080757;

// function CalculateCanvasSize(proposedWidth, proposedHeight, padWidth, padHeight)
// {
// 	// console.log ("w="+screen.width+ " h="+screen.height)
// 	var canvasWidth = proposedWidth
// 	if (screen.width < (canvasWidth+padWidth)) 
// 		canvasWidth=screen.width-padWidth;

// 	var canvasHeight=screen.height-padHeight;
// 	if (canvasHeight > proposedHeight) canvasHeight=proposedHeight;  
	
// 	return {cw : canvasWidth, ch : canvasHeight}
// }

class CanvasType
{
	constructor(canvas, context)
	{
		this.cvs = canvas;
		this.ctx = context;
	}
	
	
	AddPixelPicker(method)
	{
		this.cvs.addEventListener('click', function(event)
			{
				method(event.pageX, event.pageY)
			})
	}

	GetCanvasWidth() { return (this.cvs.width); }
	GetCanvasHeight() { return (this.cvs.height); }
	ClearScreen(color)
	{
		this.ctx.fillStyle = color;
		this.ctx.fillRect (0, 0, this.cvs.width, this.cvs.height);
		console.log ("screen cleared "+color)
	}

	DrawRoundedRectangle(x1,y1,w,h,color)
	{
		let x2 = x1 + w;
		let x3 = x2;
		let x4 = x1;
		let y2 = y1;
		let y3 = y2 + h;
		let y4 = y3;
		this.ctx.strokeStyle = color;
		this.ctx.setLineDash([]);
		this.ctx.beginPath();
		this.ctx.moveTo(x1, y1+5);
		this.ctx.arc(x1+5, y1+5, 5, Math.PI, 1.5 * Math.PI, false);
		this.ctx.lineTo(x2-5, y2);
		this.ctx.arc(x2-5, y2+5, 5, 1.5 * Math.PI, 0, false);
		this.ctx.lineTo(x3, y3-5);
		this.ctx.arc(x3-5, y3-5, 5, 0, 0.5 * Math.PI, false);
		this.ctx.lineTo(x4+5, y4);
		this.ctx.arc(x4+5, y4-5, 5, 0.5 * Math.PI, Math.PI, false);
		this.ctx.lineTo(x1, y1+5);
		this.ctx.stroke();	   
	}


	FillRoundedRectangle(x1,y1,w,h,color)
	{
		this.ctx.fillStyle = color;
		this.DrawRoundedRectangle(x1,y1,w,h,color)
		this.ctx.fill();
	}

	DrawPolygon (x1,y1,x2,y2,x3,y3,x4,y4,color)
	{
		this.ctx.strokeStyle = color;
		this.ctx.lineWidth = 1;
		this.ctx.setLineDash([]);
		this.ctx.beginPath();
		this.ctx.moveTo(x1, y1);
		this.ctx.lineTo(x2, y2);
		this.ctx.lineTo(x3, y3);
		this.ctx.lineTo(x4, y4);
		this.ctx.lineTo(x1, y1);
		this.ctx.stroke();	   
	}

	FillPolygon (x1,y1,x2,y2,x3,y3,x4,y4,color)
	{
		this.ctx.strokeStyle = color;
		this.ctx.fillStyle = color;
		this.ctx.beginPath();
		this.ctx.moveTo(x1, y1);
		this.ctx.lineTo(x2, y2);
		this.ctx.lineTo(x3, y3);
		this.ctx.lineTo(x4, y4);
		this.ctx.lineTo(x1, y1);
		this.ctx.fill();
	}

	DrawTriangle(x1,y1,x2,y2,x3,y3,color)
	{
		this.ctx.strokeStyle = color;
		this.ctx.lineWidth = 1;
		this.ctx.setLineDash([]);
		this.ctx.beginPath();
		this.ctx.moveTo(x1, y1);
		this.ctx.lineTo(x2, y2);
		this.ctx.lineTo(x3, y3);
		this.ctx.lineTo(x1, y1);
		this.ctx.stroke();	   
	}

	FillTriangle(x1,y1,x2,y2,x3,y3,color)
	{
		this.ctx.strokeStyle = color;
		this.ctx.fillStyle = color;
		this.ctx.lineWidth = 1;
		this.ctx.setLineDash([]);
		this.ctx.beginPath();
		this.ctx.moveTo(x1, y1);
		this.ctx.lineTo(x2, y2);
		this.ctx.lineTo(x3, y3);
		this.ctx.lineTo(x1, y1);
		this.ctx.stroke();	   
		this.ctx.fill();
	}

	DrawHexagon (xc,yc,rad,color)
	{
		let x1=xc-rad;
		let x2=xc-(rad/2)
		let x3=xc+(rad/2)
		let x4=xc+rad;
		let x5=xc+(rad/2)
		let x6=xc-(rad/2)
		let y1=yc;
		let y2=yc+rad*sqr3/2
		let y3=yc+rad*sqr3/2
		let y4=yc;
		let y5=yc+rad*(-1)*sqr3/2
		let y6=yc+rad*(-1)*sqr3/2
		
		this.ctx.strokeStyle = color;
		this.ctx.beginPath();
		this.ctx.moveTo(x1, y1);
		this.ctx.lineTo(x2, y2);
		this.ctx.lineTo(x3, y3);
		this.ctx.lineTo(x4, y4);
		this.ctx.lineTo(x5, y5);
		this.ctx.lineTo(x6, y6);
		this.ctx.lineTo(x1, y1);
		this.ctx.stroke();	   
	}

	FillHexagon (xc,yc,rad,color)
	{
		let x1=xc-rad;
		let x2=xc-(rad/2)
		let x3=xc+(rad/2)
		let x4=xc+rad;
		let x5=xc+(rad/2)
		let x6=xc-(rad/2)
		let y1=yc;
		let y2=yc+rad*sqr3/2
		let y3=yc+rad*sqr3/2
		let y4=yc;
		let y5=yc+rad*(-1)*sqr3/2
		let y6=yc+rad*(-1)*sqr3/2

		this.ctx.strokeStyle = color;
		this.ctx.fillStyle = color;
		this.ctx.beginPath();
		this.ctx.moveTo(x1, y1);
		this.ctx.lineTo(x2, y2);
		this.ctx.lineTo(x3, y3);
		this.ctx.lineTo(x4, y4);
		this.ctx.lineTo(x5, y5);
		this.ctx.lineTo(x6, y6);
		this.ctx.lineTo(x1, y1);
		this.ctx.fill();
	}


	FillCircle(x1, y1, rad, color)
	{
		this.ctx.beginPath();
		this.ctx.setLineDash([]);
		this.ctx.arc(x1, y1, rad, 0, 2 * Math.PI, false);
		this.ctx.fillStyle = color;
		this.ctx.strokeStyle = color;
		this.ctx.fill();
		this.ctx.stroke();
	}

	FillCircleOpacity(x1, y1, rad, color, opacity)
	{
		this.ctx.beginPath();
		this.ctx.setLineDash([]);
		this.ctx.globalAlpha = opacity;
		this.ctx.arc(x1, y1, rad, 0, 2 * Math.PI, false);
		this.ctx.fillStyle = color;
		this.ctx.fill();
		this.ctx.stroke();
		this.ctx.globalAlpha = 1.0;
	}


	DrawCircle(x1, y1, rad, color)
	{
		this.ctx.beginPath();
		this.ctx.setLineDash([]);
		this.ctx.strokeStyle = color;
		this.ctx.arc(x1, y1, rad, 0, 2 * Math.PI);
		this.ctx.stroke();
	}

	DrawArc(x1,y1, rad, start, end, color)
	{
		const deg2rad=Math.PI/180.0;
		this.ctx.beginPath();
		this.ctx.setLineDash([]);
		this.ctx.strokeStyle = color;
		this.ctx.arc(x1, y1, rad, start*deg2rad, end*deg2rad);
		this.ctx.stroke();
	}

	DrawOval(x,y, radx, rady, rot, start, end, color)
	{
		this.ctx.beginPath();
		this.ctx.setLineDash([]);
		this.ctx.strokeStyle = color;
		const deg2rad=Math.PI/180.0;
		this.ctx.ellipse (x,y,radx, rady, rot*deg2rad, start*deg2rad, end*deg2rad)
		this.ctx.stroke();
	}
	FillOval(x,y, radx, rady, rot, start, end, color)
	{
		this.ctx.beginPath();
		this.ctx.setLineDash([]);
		this.ctx.fillStyle = color;
		const deg2rad=Math.PI/180.0;
		this.ctx.ellipse (x,y,radx, rady, rot*deg2rad, start*deg2rad, end*deg2rad)
		this.ctx.fill();
	}

	DrawThinLine (x1, y1, x2, y2, color)
	{
		this.ctx.fillStyle = color;
		this.ctx.strokeStyle = color;
		this.ctx.lineWidth = 1;
		this.ctx.beginPath();
		this.ctx.setLineDash([]);
		this.ctx.moveTo(x1, y1);
		this.ctx.lineTo(x2, y2);
		this.ctx.stroke();	   
	}

	DrawLine (x1, y1, x2, y2, color)
	{
		this.ctx.fillStyle = color;
		this.ctx.strokeStyle = color;
		this.ctx.lineWidth = 3;
		this.ctx.beginPath();
		this.ctx.setLineDash([]);
		this.ctx.moveTo(x1, y1);
		this.ctx.lineTo(x2, y2);
		this.ctx.stroke();	   
		this.ctx.lineWidth = 1;
	}

	DrawCurvedLine (x1, y1, x2, y2, x3, y3, color)
	{
		this.ctx.fillStyle = color;
		this.ctx.strokeStyle = color;
		this.ctx.lineWidth = 1;
		this.ctx.beginPath();
		this.ctx.setLineDash([]);
		this.ctx.moveTo(x1, y1);
		this.ctx.quadraticCurveTo(x2, y2, x3, y3)
		this.ctx.stroke();	   
	}

	DrawThickLine (x1, y1, x2, y2, color)
	{
		this.ctx.fillStyle = color;
		this.ctx.strokeStyle = color;
		this.ctx.lineWidth = 6;
		this.ctx.beginPath();
		this.ctx.setLineDash([]);
		this.ctx.moveTo(x1, y1);
		this.ctx.lineTo(x2, y2);
		this.ctx.stroke();	   
		this.ctx.lineWidth = 1;
	}

	DrawDashedLine (x1, y1, x2, y2, color)
	{
		this.ctx.fillStyle = color;
		this.ctx.strokeStyle = color;
		this.ctx.beginPath();
		this.ctx.setLineDash([10,10]);
		this.ctx.moveTo(x1, y1);
		this.ctx.lineTo(x2, y2);
		this.ctx.stroke();	   
	}

	DrawBox(x, y, w, h, color)
	{
		this.ctx.fillStyle = color;
		this.ctx.strokeStyle = color;
		this.ctx.lineWidth = 1;  // Set the line width here

		this.ctx.beginPath();
		this.ctx.setLineDash([]);
		this.ctx.moveTo(x, y);
		this.ctx.lineTo(x+w, y);
		this.ctx.lineTo(x+w, y+h);
		this.ctx.lineTo(x, y+h);
		this.ctx.lineTo(x, y);
		this.ctx.stroke();	   
	}

	FillBox(x, y, w, h, color)
	{
		this.ctx.fillStyle = color;
		this.ctx.fillRect (x, y, w, h);
	}


	DrawText (str, x, y, font, color)
	{
		this.ctx.font = font;
		this.ctx.textAlign = 'left';
		this.ctx.fillStyle = color;

		this.ctx.fillText(str, x, y);		
	}



	SaveContext()
	{
		this.ctx.save();
	}

	RestoreContext()
	{
		this.ctx.restore();
	}

	RotateCanvas(degs)
	{
		var rads=parseFloat(degs)*parseFloat(0.0174533);
		this.ctx.rotate(rads);
	}


	// DrawImage (imgname, dx, dy, dw, dh)
	// {
	// 	image.src = imgname;
	// 	image.onload = () => {
	// 		ctx.drawImage(image, dx, dy, dw, dh);
	// 	}
	// }

	DrawSubImage (imgname, sx, sy, sw, sh, dx, dy, dw, dh)
	{
		// console.log ("DrawSubImage sx=" +sx+ " sy="+sy +" sw=" +sw +" sh=" +sh + " dx=", dx +" dy="+ dy +" dw=" + dw + " dh=" +dh);
		this.ctx.drawImage(document.getElementById(imgname), sx, sy, sw, sh, dx, dy, dw, dh);
		// this.ctx.drawImage(document.getElementById(imgname), dx, dy);
	}

	DrawImageRotated (imgname, x, y, w, h, degs)
	{
	//	console.log ("drawing image " + imgname + " at x="+ parseInt(x) + " y="+parseInt(y));
		this.ctx.save();
		this.ctx.translate(x+w/2,y+h/2);
		this.ctx.rotate(degs*Math.PI/180);
		this.ctx.drawImage(document.getElementById(imgname), -w/2, -h/2, w, h);
		// this.ctx.drawImage(document.getElementById(imgname),-image.width/2,-image.width/2);
		this.ctx.restore();
	}

}

export default CanvasType

