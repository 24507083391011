import * as Images from '../images';

export default function TeamRecord({ teamRecord })
{

    const teamImg = Images.TeamImagePaths[teamRecord.TID];

    return (
    <tr className='small-row'>
        {/* <td><img className='btn-xsq' onClick={() =>  setAndMoveUp(teamRecord.Name)} src={teamImg}></img></td>  */}
    {teamRecord.Rank<=25?<td style={{textAlign: 'right', color: 'khaki', fontSize: '10px'}}>{teamRecord.Rank}</td>:<td style={{textAlign: 'right', color: 'khaki'}}></td>}        
    <td  style={{textAlign: 'left'}}>{teamRecord.Name}</td> 
    <td>{teamRecord.Conference}</td> 
    <td>{teamRecord.Season}</td>
    </tr>
    )
}//

