import { useNavigate } from "react-router-dom"
import { FaCaretRight } from "react-icons/fa6";

import * as GlobalData from '../data';
import * as Images from '../images';

export default function TeamBar({placement, setActive, confID, TID, mobile, rev, toggleRight})
{
    const navigate = useNavigate();

    const goBack = () => {
        navigate("/FBS")
    }    

    const setAndMoveUp = (nm) => {
        navigate("/"+nm)
        // setActive(MenuChoices.HOME)
        // toggleRight();
      }

    // console.log ("TeamBar: ConfID="+confID+" TID="+TID)

    var ConfTeams1 = [];
    var ConfTeams2 = [];
    
    if (GlobalData.ConfTeams.length === 0)
    {
        ConfTeams1.push ({"TID": 1, "Name": "Air Force"})
        ConfTeams2.push ({"TID": 1, "Name": "Air Force"})
    }
    else
    {
        ConfTeams1 = []
        ConfTeams2 = []
        for (let i=0; i<GlobalData.ConfTeams.length; i++)
        if (i < 8)
            ConfTeams1.push (GlobalData.ConfTeams[i]);
        else
            ConfTeams2.push (GlobalData.ConfTeams[i]);        
    }

    let confImg = Images.ConfImagePaths[confID]

    var numTeams = GlobalData.ConfTeams.length;
    var teamButtons = GlobalData.ConfTeams.map((data,id)=>{
        let teamImg = Images.TeamImagePaths[data.TID]
        var layout = "box"
        if (parseInt(TID) === parseInt(data.TID)) { 
            layout = "box graybk"; 
            // console.log (layout); 
        }

        return <TeamButton key={id} layout={layout} teamImg={teamImg} teamName={data.Name} setAndMoveUp={setAndMoveUp}/>
    })
   
      return (
          
        <div className={placement}>
            {/* <button className="btn-item whitebk" onClick={() =>  toggleRight()} ><FaCaretRight size={36}/></button> */}
            <button className="graybk"></button>
            <button className="graybk"></button>
            {teamButtons}
            <TeamPad count={18-numTeams} />
        </div>
    )
}

function TeamButton({layout, teamImg, teamName, setAndMoveUp})
{
    if (teamName === "Texas A&M") teamName="Texas%20AM"

    return (
        <button className="image-button graybk border-black "><img onClick={() =>  setAndMoveUp(teamName)} src={teamImg} alt="Button Image"/></button>    
    )
}

function TeamPad({count})
{
    return (
        <>
        {Array.from({ length: count }, (_, index) => (
            <button key={index} className="graybk"></button>
          ))}
        </>
    )
}