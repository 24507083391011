export const MenuChoices = {
    HOME:       0,
    RANKINGS:   1,
    STANDINGS:  2,
    LIVE_SCORES: 3,
    STATS:      4,
    SCHEDULE:   5,
    ROSTER:     6,
    TV:         7,
    PREV_WEEK:  8,
    DEPTH:      9,
    PLAYS:      10,
    GAME_STATS: 11,
    LIVE:       12,
    PREVIEW:    13,
    GAMELOG:    14,
    FBS:        15,
    DRIVES:     16,
    CCG:        30,
    BOWLS:      31,
    HEADLINES:  32,
    FUTURE:     33,
    RECRUITS:   34,
    PORTAL:     35,
    HEAD2HEAD:  36,
    HISTORY:    37,
    FINAL_RANK: 38,
    HOME_STRETCH: 39,
    LIVE_DRIVES:  50,
    LIVE_PLAYS:   51,
    LIVE_TEAMS:   52,
    LIVE_PLAYERS: 53,
    LIVE_GAME:    54,
    ABOUT: 94,
    TEST:       95
};

