import GameInfo from './GameInfo';
import TeamInfo from './TeamInfo'
import GameInfoComplete from './GameInfoComplete';
import Highlights from './Highlights';

import { FilterGamesByTID } from '../utilities'
import { MenuChoices } from '../MenuChoices';
import * as GlobalData from '../data'
import * as Images from '../images'
import React, { useState, useEffect } from "react"


export default function HomeTeam({ TID, setPopup, setGID, setGameInfo, updaterTime, currWeek })
{
    const [lastWeeksGame, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
        let lastWeek = currWeek - 1;
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_SPECIFIC_SCORE&Week="+lastWeek+"&TID="+TID;
          console.log (url)
          const response = await fetch(url, {
            method: 'GET', // or 'POST', 'PUT', etc.
            headers: {
                'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                'Content-Type': 'application/json'
            }
        });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setData(result);
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      fetchData();
  
    }, [TID]);  


    if (loading) 
        return (<h2>Loading...</h2>);
    else if (error) 
        return (<h2>Error: {error}</h2>);
    else 
    {
        
        const getPlays = (val, GID)  => {
            setGID(GID)
            setPopup(val)
        }
        
        const getPreview = (val, GID) => {
            setGID(GID)
            setPopup(val)
        }

        const getGameStats = (val, GID) => {
            setGID(GID)
            setPopup(val)
        }
    
        const getLive = (val, GID) => {
            setGID(GID)
            setPopup(val)
        }

        const getDrives = (val, GID) => {
            setGID(GID)
            setPopup(val)
        }

        var ThisWeeksGame = FilterGamesByTID (GlobalData.ThisWeeksGames, TID);

        let networkIdx = GlobalData.networkNames.indexOf(ThisWeeksGame.TV)
        if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
        let network=Images.networkImagePaths[networkIdx];


        // console.log ("HomeTeam:" + updaterTime)
        // console.log ("   LastWeeksGame...")
//        lastWeeksGame === -1 ? console.log ("      BYE WEEK") : console.log ("      "+lastWeeksGame.AwayTeam + " at " + lastWeeksGame.HomeTeam)
  //      console.log ("   ThisWeeksGame...")
    //    ThisWeeksGame === -1 ? console.log ("      BYE WEEK") : console.log ("      "+ThisWeeksGame.AwayTeam + " at " + ThisWeeksGame.HomeTeam)
        // teamName = teamName.toUpperCase();


        // setTeamMenu (MenuChoices.HOME)

        return (
            <>
            <div className={'full-width'}>
                <h2>Last Weeks Game</h2>
                {(lastWeeksGame === -1) ? 
                <>
                <div className='popup-item full-width  fixed-height'>
                    <h2 className='white'>BYE WEEK</h2>            
                </div>
                </>
            
                :
                <>
                <GameInfoComplete   gameInfo={lastWeeksGame} 
                                    getPlays={getPlays} 
                                    getGameStats={getGameStats}
                                    getDrives={getDrives}/>

                <h2>Game Summary</h2>
                <div className='popup-item full-width  fixed-height'>
                    <Highlights         gameInfo={lastWeeksGame}
                                        GID={lastWeeksGame.GID}
                                        TID={TID}
                                        />
                </div>
                <br></br>

                </>
                }
            </div>
            </>

        )
    }
}