import React from "react";

export default function HorizontalLabel({left, label1, color1})
{
    return (
        // <div style={{ display: 'flex', justifyContent: 'space-between' }}>

        <p style={{ marginLeft:  left+"px",
                    marginTop : "5px",
                    marginBottom : "1px",
                    fontWeight: 'bold',
                    color: color1,
                    textAlign: 'left',
                    fontSize: '10px'}} >{label1}</p>
        // </div>
      )
}