import React, { useState, useEffect, useRef } from "react"
import { FaUnderline } from "react-icons/fa";
import CanvasType from "../canvasgraphics";
import * as Images from '../images'

export default function PollHistoryView ({ mobile, setPopup, historyData })
{
    const last = historyData[0].length - 1;
    const zoom = (last < 9);

    const [isPopupOpen, setIsPopupOpen] = useState(true);
    const canvasRef = useRef(null)
    const [images, setImages] = useState(
        Array.from({ length: 25 }, (_, index) => ({
            src: Images.TeamImagePaths[historyData[index][last].TID], // Adjust the image paths accordingly
            x: CalcX(index, last, zoom), // Random initial x position
            y: CalcY(index, last), // Random initial y position
        }))
    );
    const imageRef = useRef(new Image())

    const closePopup = () => {
        setIsPopupOpen(false);
        setPopup(0)
    }
  

    useEffect(() => { // formerly DrawGraph()
    {   const canvas = canvasRef.current;
        const image = imageRef.current;

        const context = canvas.getContext("2d");
        const pcv = new CanvasType (canvas, context)
        const bgcolor = 'white';
        const fgcolor = 'black';
        
        
        console.log ("UseEffect to begin drawing...")

        pcv.ClearScreen (bgcolor); // '#303030'');

        DrawGraph (pcv, fgcolor);   
        
        let tHistory = historyData[0];
        let last = tHistory.length - 1;
        let latest = tHistory[last].Week;    

        for (let r=0; r<25; r++)
            DrawTeamHistory (pcv, historyData[r], fgcolor, zoom);

       DrawImages (historyData, zoom)
    }
    },[])

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const pcv = new CanvasType (canvas, ctx)
    
        const drawImages = () => {

            images.forEach(({ src, x, y }) => {
                const image = new Image();
                image.src = src;

                image.onload = () => {
                    ctx.drawImage(image, x, y+12, 24, 24); // Draw each image with a 50x50 size
                };
          });
        };
    
        drawImages();
      }, [images]);


      function DrawImages (history, zoom)
      {
        const updatedImages = images.map((image, index) => {
            let rnk = history[index].Rank;
            let wk = history[index].Week;
            let tid = history[index].TID;
        
            // Calculate new positions based on rank, week, and zoom
            let newX = CalcX(rnk, wk, zoom);
            let newY = CalcY(rnk, wk);
        
            // Return a new object with updated x and y
            return {
              ...image,
              src: Images.TeamImagePaths[tid],
              x: newX,
              y: newY
            };
          });
        
          // Update the state with the new array of images
          setImages(updatedImages);
}
      

       
        return (
            <div className={mobile?"popup-noscroll":'popup-noscroll-canvas'}>
                <div className='flex-container'>
                    <div className='popup-item full-width  fixed-height'>
                        <button className="close-icon" onClick={closePopup}>&times;</button>
                        <h2 className="popup-item full-width no-margin">POLL HISTORY GRAPH</h2>
                    </div>

                    <canvas ref={canvasRef} width="1200" height="600" style={{border: '1px solid black'}} />
                </div> 
            </div> 
            )
}

function CalcX (rnk, wk, zoom)
{
    let xoff = 40;
    let woff = (zoom ? 80 : 40);

    return (xoff + wk  * woff);
}

function CalcY (rnk)
{
    let yoff = 10;
    let roff = 22;

    return (yoff + rnk * roff);
}

function DrawGraph(pcv, color)
{			
	var x1 = 40;
	var y1 = 26;
	var cw = pcv.GetCanvasWidth()
    var ch = pcv.GetCanvasHeight()

    var w = cw*0.85;
    var h = (ch-y1)*0.95;

	
	pcv.DrawBox(x1, y1, w, h, color);
    // pcv.DrawText ("WEEKLY POLL TRENDS - "+(phLevel==0?"FBS":(phLevel==1?"CONF":"TEAM")), 100, 20, 'normal 1.0em arial', 'white');

    DrawYaxis(pcv, color, false);
}

function DrawHistory (pcv, history)
{
    // let tid = 0;
    // ConnectRankings (pcv, tid, 0, 1, 1, 25);
    // ConnectRankings (pcv, tid, 1, 2, 25, 10);
    // ConnectRankings (pcv, tid, 2, 3, 10, 25);
    // ConnectRankings (pcv, tid, 3, 4, 25, 10);
    // ConnectRankings (pcv, tid, 4, 5, 10, 1);
    // ConnectRankings (pcv, tid, 5, 6, 1, 10);
    // ConnectRankings (pcv, tid, 6, 7, 10, 25);
    // ConnectRankings (pcv, tid, 7, 8, 25, 1);
}

function DrawTeamHistory (pcv, tHistory, color, zoom)
{

    DrawXaxis(pcv, color, zoom);


    for (let w1=1; w1<tHistory.length; w1++)
    {
        let w0 = w1 - 1;
        let rnk0 = tHistory[w0].Rank;
        let rnk1 = tHistory[w1].Rank;
        let tid  = tHistory[w1].TID;
        let color = tHistory[w1].Color;
        
        
        if (rnk0 < 26 && rnk1 < 26)
            ConnectRankings (pcv, tid, w0, w1, rnk0, rnk1, color, zoom);
    }

}


function ConnectRankings (pcv, tid, wk0, wk1, rnk0, rnk1, color, zoom)
{
    let xoff = 40;
    let woff = (zoom ? 80 : 40);
    let yoff = 10;
    let roff = 22;

    var x0    = xoff + wk0  * woff;
    let y0    = yoff + rnk0 * roff;
    var x1    = xoff + wk1  * woff
    let y1    = yoff + rnk1 * roff;

    pcv.DrawLine(x0, y0, x1, y1, color);
}


function DrawYaxis(pcv, color, zoom)
{
	var x = 40;
	var y = 26;
	var xoff = 40; // (pcv.GetCanvasWidth()*0.9) / (wk+2);
	var yoff = 22; // h / nt;

    const textYoffset = 10;
    const font=" 10px Arial";

    for (let rnk=1; rnk<=25; rnk++)
        pcv.DrawText ("# " + rnk, 0, y+rnk*yoff-textYoffset, font, color);
}

function DrawXaxis(pcv, color, zoom)
{
    const textYoffset = 10;
    const font=" 10px Arial";
	var x = 40;
	var y = 26;
	var xoff = (zoom ? 80 : 40); // (pcv.GetCanvasWidth()*0.9) / (wk+2);
	var yoff = 22; // h / nt;

    for (let wk=0; wk<=15; wk++)
        pcv.DrawText (wk, x + wk*xoff-3, y-textYoffset, font, color);
}

/*
function PlotTrend (pcv, x, y, xoff, yoff, t, thruweek, nt)
{
    // console.log ("PlotTrend TID="+t)
	var iconw=30;
	var iconh=25;

	var textYoffset = 10;
	var imgYoffset  = 25;
	var boxYoffset  = 15;
	var lineYoffset = 12;
	var font='normal 1.0em arial';
	
	var rnkcurr = GetRankByTeamAndWeek (t, 0); 
    // console.log ("  Rank="+rnkcurr)
	
    var tmcolor = teams[t].BgColor
    if (tmcolor == 'white' || tmcolor == 'black') tmcolor = teams[t].FgColor

    // console.log ("tid="+t+" "+teams[t].FgColor+"/"+teams[t].BgColor)
	
	pcv.DrawText ("0", x, y-textYoffset, font, 'white');

    // Show the Preseason Poll
	if (rnkcurr > 0 && rnkcurr <= nt)
	{
        // Rank legend along the left
		pcv.DrawText ("# " + rnkcurr, 0, y+rnkcurr*yoff-textYoffset, font, 'white');

		pcv.DrawImage (teams[t].SafeName, x-40, y+rnkcurr*yoff-imgYoffset, iconw, iconh);
		pcv.FillBox (x, y + rnkcurr*yoff-boxYoffset, 6, 6, tmcolor); // teams[t].Color);
	}

	for (var wk=1; wk<=thruweek; wk++)
	{
		var rnklast = GetRankByTeamAndWeek (t,wk-1);
		var rnkcurr = GetRankByTeamAndWeek (t,wk);
		
        // Week legend along the top
		pcv.DrawText (wk, x + wk*xoff-3, y-textYoffset, font, 'white');

		if (rnkcurr > 0 && rnkcurr <= nt)
        {
			pcv.FillBox (x + wk*xoff-3, y + rnkcurr*yoff-boxYoffset, 6, 6, tmcolor)
        }

		// Ranked team two weeks in a row
		//
		if (rnklast > 0 && rnklast <=nt &&
		    rnkcurr > 0 && rnkcurr <=nt)
		{
			pcv.DrawLine (x + (wk-1)*xoff, y + rnklast*yoff - lineYoffset, x+(wk)*xoff, y + rnkcurr*yoff - lineYoffset, tmcolor); // );	
		}

        // if the last week...
        if (wk == thruweek && rnkcurr > 0 && rnkcurr <= nt)
        {
            // console.log ("Plot1: t="+t+" wk="+wk+" rnk="+rnkcurr)
            pcv.DrawImage (teams[t].SafeName, x + wk*xoff+15,y+rnkcurr*yoff-imgYoffset, iconw, iconh);

            // Current Week rank along the right
            pcv.DrawText ("# " + rnkcurr, x + wk*xoff+55, y+rnkcurr*yoff-textYoffset, font, 'white');
        }
        // Ranked team for the first time
		//
		else if (rnkcurr > 0 && rnkcurr <= nt && rnklast == 0)
		{
            // console.log ("Plot2: t="+t+" wk="+wk+" rnk="+rnkcurr)
			pcv.DrawImage (teams[t].SafeName, x + wk*xoff-35,y+rnkcurr*yoff-imgYoffset, iconw, iconh);
		}
        else
        {
            // console.log ("Plot3: t="+t+" wk="+wk+" rnk="+rnkcurr)
        }
	}
}
*/

