import * as Images from '../images';
import * as GlobalData from '../data';

import { MenuChoices } from '../MenuChoices';
export default function GameInfoFuture({gameInfo, getPreview})
{
    let GID      = gameInfo.GID;
    let awayPath=Images.TeamImagePaths[gameInfo.AwayTID];;
    let homePath=Images.TeamImagePaths[gameInfo.HomeTID];;

    let awayTeam = gameInfo.AwayTeam;
    let awayRank = gameInfo.AwayRank;
    let awayScore = gameInfo.AwayScore;
    let homeTeam = gameInfo.HomeTeam;;
    let homeRank = gameInfo.HomeRank;
    let homeScore = gameInfo.HomeScore;
    let status   = gameInfo.Status;
    let complete = gameInfo.Complete;
    let spread=gameInfo.Spread;
    if (spread === 99) spread="TBD"

    const days=["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    let time = FormatTime(gameInfo.Time);
    let day = days[gameInfo.Day];
    let day_time = day + " " + time;
    
    return (<>
        {gameInfo.GID > 0?
        <table className="blacktbl">
            <colgroup>
            <col style={{ width: '10%', textAlign: 'left'}} />
            <col style={{ width: '3%',  textAlign: 'right'}} />
            <col style={{ width: '30%', textAlign: 'left' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '20%', textAlign: 'right' }} />
            <col style={{ width: '20%', textAlign: 'right' }} />
            </colgroup>
            <tbody>
            <tr>
            <td><img className='icon-tiny' src={awayPath} alt={""}></img></td>
            {awayRank <= 25?<td className='gray px10'>{awayRank}</td>:<td></td>}
            <td style={{fontSize: '12px'}}>{awayTeam}</td>
            <td style={{fontSize: '12px'}}>{awayScore}</td> 
            <td  className='px10' style={{textAlign: 'center', color: 'khaki'}}>{day}</td> 
            <td  className='px10' style={{textAlign: 'center', color: 'khaki'}}>{time}</td> 
            </tr>
            <tr>
            <td><img className='icon-tiny' src={homePath} alt={""}></img></td> 
            {homeRank <= 25?<td className='gray px10'>{homeRank}</td>:<td></td>}
            <td style={{fontSize: '12px'}}>{homeTeam}</td>
            <td style={{fontSize: '12px'}}>{homeScore}</td> 
            <td className='px10' style={{textAlign: 'center'}} >{spread>0?"+"+spread:spread}</td> 
            <td><button className='ltblueButtonInv' onClick={() => getPreview(MenuChoices.PREVIEW, GID)}>PREVIEW</button></td>
            </tr>
            </tbody>
        </table> 
        :
        <h2>No Data</h2>
        }
    </>)
}

function FormatTime(t)
{
    if      (t === '11:00') return '11:00 am';
    else if (t === '11:30') return '11:30 am';
    else if (t === '12:00') return '12:00 pm';
    else if (t === '12:30') return '12:30 pm';
    else if (t === '13:00') return '1:00 pm';
    else if (t === '13:30') return '1:30 pm';
    else if (t === '14:00') return '2:00 pm';
    else if (t === '14:30') return '2:30 pm';
    else if (t === '15:00') return '3:00 pm';
    else if (t === '15:30') return '3:30 pm';
    else if (t === '16:00') return '4:00 pm';
    else if (t === '16:15') return '4:15 pm';
    else if (t === '16:30') return '4:30 pm';
    else if (t === '17:00') return '5:00 pm';
    else if (t === '17:30') return '5:30 pm';
    else if (t === '18:00') return '6:00 pm';
    else if (t === '18:30') return '6:30 pm';
    else if (t === '19:00') return '7:00 pm';
    else if (t === '19:30') return '7:30 pm';
    else if (t === '19:45') return '7:45 pm';
    else if (t === '20:00') return '8:00 pm';
    else if (t === '20:30') return '8:30 pm';
    else if (t === '21:00') return '9:00 pm';
    else if (t === '21:30') return '9:30 pm';
    else if (t === '22:00') return '10:00 pm';
    else if (t === '22:15') return '10:15 pm';
    else if (t === '22:30') return '10:30 pm';
    else if (t === '23:00') return '11:00 pm';
    else if (t === '23:30') return '11:30 pm';
    else return (t)
}
