import HorizontalLabel from "./HorizontalLabel";
import React from "react";


export default function LastPlay({lastPlay})
{
    const downNames = ["0", "1st","2nd","3rd","4th"];
    
    let label = downNames[lastPlay.down] + " & " + lastPlay.dist + " on " + lastPlay.side + " " + lastPlay.ydLn;

    return (
        <div className="blackbk">
            <h3 className='no-margin'></h3>
            <HorizontalLabel left={15} label1={"Last Play"} color1="khaki" />
            <HorizontalLabel left={15} label1={label} color1="white" />
            <HorizontalLabel left={15} label1={lastPlay.playText} color1="gray" />
            <h3 className=''></h3>
        </div>
      )
}