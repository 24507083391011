export default function RosterSelector({mobile, rosterLevel, setRosterLevel})
{
    return (
        <div className={mobile?"box btn-container":"box btn-container-desktop"}>
            <button className={"whiteButtonInv"} ></button>
            <button className={`${rosterLevel===0 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setRosterLevel(0)} >Offense</button>
            <button className={`${rosterLevel===1 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setRosterLevel(1)} >Defense</button>
            <button className={`${rosterLevel===2 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setRosterLevel(2)} >S/T</button>
            <button className={"whiteButtonInv"} ></button>
        </div>
    )
}
