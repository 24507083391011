import * as Images from '../images';
import * as GlobalData from '../data';

import { MenuChoices } from '../MenuChoices';
export default function GameInfoComplete({gameInfo, getPlays, getGameStats, getDrives, getPreview})
{
    let GID      = gameInfo.GID;
    let awayPath=Images.TeamImagePaths[gameInfo.AwayTID];;
    let homePath=Images.TeamImagePaths[gameInfo.HomeTID];;

    let awayTeam = gameInfo.AwayTeam;
    let awayRank = gameInfo.AwayRank;
    let awayScore = gameInfo.AwayScore;
    let homeTeam = gameInfo.HomeTeam;;
    let homeRank = gameInfo.HomeRank;
    let homeScore = gameInfo.HomeScore;
    let status   = gameInfo.Status;
    let complete = gameInfo.Complete
    
    return (
        <table className="blacktbl">
            <colgroup>
            <col style={{ width: '10%', textAlign: 'left'}} />
            <col style={{ width: '3%',  textAlign: 'right'}} />
            <col style={{ width: '30%', textAlign: 'left' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '20%', textAlign: 'right' }} />
            <col style={{ width: '20%', textAlign: 'right' }} />
            </colgroup>
            <tbody>
            <tr className='small-row'>
            <td><img className='icon-tiny2' src={awayPath} alt={""}></img></td>
            {awayRank <= 25?<td className='gray px10'>{awayRank}</td>:<td></td>}
            {complete && awayScore > homeScore ?
            <>
            <td style={{fontSize: '12px', color: 'khaki'}}>{awayTeam}</td>
            <td style={{fontSize: '12px', color: 'khaki'}}>{awayScore}</td>
            </>
            :
            <>
            <td style={{fontSize: '12px', color: 'white'}}>{awayTeam}</td>
            <td style={{fontSize: '12px', color: 'white'}}>{awayScore}</td>
            </>
            }
            <td><button className='khakiButtonInv' >{status}</button></td> 
            {complete
                ?<td><button className='ltblueButtonInv' onClick={() => getGameStats(MenuChoices.GAME_STATS, GID)}>STATS</button></td> 
                :<td><button className='ltblueButtonInv' onClick={() => getPreview(MenuChoices.PREVIEW, GID)}>PREVIEW</button></td>
            }
            </tr>
            <tr className='small-row'>
            <td><img className='icon-tiny2' src={homePath} alt={""}></img></td> 
            {homeRank <= 25?<td className='gray px10'>{homeRank}</td>:<td></td>}

            {complete && homeScore > awayScore ?
            <>
            <td style={{fontSize: '12px', color: 'khaki'}}>{homeTeam}</td>
            <td style={{fontSize: '12px', color: 'khaki'}}>{homeScore}</td>
            </>
            :
            <>
            <td style={{fontSize: '12px', color: 'white'}}>{homeTeam}</td>
            <td style={{fontSize: '12px', color: 'white'}}>{homeScore}</td>
            </>
            }

            {complete
                ?<td><button className='ltblueButtonInv' onClick={() => getDrives(MenuChoices.DRIVES, GID)}>DRIVES</button></td> 
                :<td></td> 
            }
            {complete
                ?<td><button className='ltblueButtonInv' onClick={() => getPlays(MenuChoices.PLAYS, GID)}>PLAYS</button></td>
                :<td></td> 
            }
            </tr>
            </tbody>
        </table> 
    )
}

