import * as GlobalData from './data';

// dungeonapi.js

function Post(str)
{
    var xmlhttp = new XMLHttpRequest();

    console.log (str)
    xmlhttp.open("GET", str, true);

	// Set the Authorization header with the token
	xmlhttp.setRequestHeader("Authorization", `Bearer ${GlobalData.apiKey}`);

	xmlhttp.send();    
}

// // Create a new XMLHttpRequest object
// const xhr = new XMLHttpRequest();

// // Open a connection (GET request in this example)
// xhr.open("GET", "https://example.com/api/endpoint", true);

// // Set the Authorization header with the token
// const token = "your-auth-token"; // Replace with your actual token
// xhr.setRequestHeader("Authorization", `Bearer ${token}`);

// // Set up the onload callback function
// xhr.onload = function() {
//   if (xhr.status === 200) {
//     console.log("Request successful:", xhr.responseText);
//   } else {
//     console.error("Request failed:", xhr.status, xhr.statusText);
//   }
// };

// // Send the request
// xhr.send();


function PostAndReceive(str, ProcessData, setRetrieved)
{
    var xmlhttp = new XMLHttpRequest();

    xmlhttp.onreadystatechange = function() 
	{
		if (this.readyState === 4 && this.status === 200) 
		{
			let dbdata = JSON.parse(this.responseText);
            //  console.log ("recvd Transactions = ", dbdata.length)
            ProcessData(dbdata)
            setRetrieved(true)
		}
	};

    setRetrieved(false)

    console.log (str)
    xmlhttp.open("GET", str, true);

	// Set the Authorization header with the token
	xmlhttp.setRequestHeader("Authorization", `Bearer ${GlobalData.apiKey}`);

    xmlhttp.send();    
}

// RETRIEVE ALL GAMES FROM DATABASE
export function RetrieveGames (year, Loadfunc, setRetrievedFunc)
{
	let path = "https://www.cfbdungeon.com/php/" 
	let str = path+ "cfbx.php?Xaction=GET_ALL_GAMES";
	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
}
	
//  RETRIEVE ALL TEAMS 
export function RetrieveTeams (year, Loadfunc, setRetrievedFunc)
{
	let path = "https://www.cfbdungeon.com/php/" 
	let str = path+ "cfbx.php?Xaction=GET_TEAMS";
	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
}
//  RETRIEVE ALL TEAMS 
export function RetrieveConfTeams (confid, Loadfunc, setRetrievedFunc)
{
	let path = "https://www.cfbdungeon.com/php/" 
	let str = path+ "cfbx.php?Xaction=GET_TEAMS&Conf="+confid;
	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
}

export function RetrieveCurrentScoreboard (year, week, Loadfunc, setRetrievedFunc)
{
	let path = "https://www.cfbdungeon.com/php/" 
	let str = path+ "cfbx.php?Xaction=GET_SCOREBOARD&Week="+week;

	console.log ("*************  RETRIEVE ************");
	PostAndReceive(str, Loadfunc, setRetrievedFunc)    
}

