import PlayTable from './PlayTable';
import * as GlobalData from '../data';

import React, { useState, useEffect, useRef } from "react"

export default function LivePlays({ GID })
{
    const [plays, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [error, setError] = useState(null);
    const [awayTeam, setAwayTeam] = useState(0);
    const [awayTID, setAwayTID] = useState(0);
    const [homeTeam, setHomeTeam] = useState(0);
    const [homeTID, setHomeTID] = useState(0);

    const [tmSelected, setTmSelected] = useState(0);
    const [tidSelected, setTidSelected] = useState(0);

    const saveTeam = (tid,name)  => {
        setTidSelected(tid);
        setTmSelected(name);
    }

    useEffect(() => {
        // Function to fetch data
        const fetchData = async () => {
          try {
              const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_GAME_INFO&GID="+GID;
              console.log (url)
              const response = await fetch(url, {
                method: 'GET', // or 'POST', 'PUT', etc.
                headers: {
                    'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                    'Content-Type': 'application/json'
                }
            });
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              const results = await response.json();
              {
                  setAwayTeam( results.AwayTeam);
                  setAwayTID( results.AwayTID);
                  setHomeTeam( results.HomeTeam);
                  setHomeTID( results.HomeTID);
                  saveTeam(results.HomeTID, results.HomeTeam)
              }
          } 
          catch (error) {
            setError(error.message);
          } 
          finally {
            setLoadingInfo(false);
          }
        };
    
        fetchData();
    
      }, [GID]);  
      
      useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
            const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_LIVE_PLAYS2&GID="+GID;
            console.log (url)
            const response = await fetch(url, {
                method: 'GET', // or 'POST', 'PUT', etc.
                headers: {
                    'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const results = await response.json();
            {
                setData( results);
            }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      fetchData();
  
    }, [GID]);  

    // Use useEffect to scroll when the component updates (i.e., when messages change)
//   useEffect(() => {
//     if (containerRef != null) scrollToBottom();
//   }, [playCount]); // This dependency ensures it runs every time messages change



    
    if (loading) 
        return (<h2>Loading...</h2>);
    else if (error) 
        return (<h2>Error: {error}</h2>);
    else if (GID==0)
        return (<h2>No Game SELECTED...</h2>);

    else
        {        
        return (
            <>
            <div className="box btn-container-desktop">
                <button className={"noButton"} ></button>
                <button className={`${tmSelected===awayTeam? "ltblueButtonInv" : "whiteButtonInv"}`} 
                                onClick={() =>  saveTeam(awayTID, awayTeam)} >{awayTeam}</button>
                <button className={`${tmSelected===homeTeam? "ltblueButtonInv" : "whiteButtonInv"}`} 
                                onClick={() =>  saveTeam(homeTID, homeTeam)} >{homeTeam}</button>   
                <button className={"noButton"} ></button>
            </div>
            <div  className="full-width scroll" style={{height: '50%'}}>
                <PlayTable tidSelected={tidSelected} plays={plays} />
            </div>
            </>
        )
    }
}

