import React, { useState, useEffect } from "react"

export default function PossessionInfo ({possInfo})
{
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    console.log (possInfo)
  
        let side = possInfo.side;
        let ydln =  possInfo.ydLine;
        let stat = possInfo.down + " & " + possInfo.dist ;
        let rz = (ydln > 0 && ydln <= 20 ) ? "RZ" : "on";
          
        return (
          <tr className='small-row'>
          <td style={{color: 'cyan', backgroundColor: 'navy'}}>Q{possInfo.period}</td>
            <td style={{color: 'cyan', backgroundColor: 'navy'}}>{possInfo.clock}</td>
            <td style={{color: 'cyan', backgroundColor: 'navy'}}></td>
            <td style={{color: 'cyan', backgroundColor: 'navy'}}>{stat}</td>
            <td style={rz=='RZ'?{textAlign: 'center', backgroundColor: 'red', color: 'white'}:{color: 'cyan', backgroundColor: 'navy'}}>{rz}</td>
            <td style={{color: 'cyan', backgroundColor: 'navy'}}>{side}{ydln}</td>
            <td style={{color: 'cyan', backgroundColor: 'navy'}}>{possInfo.status}</td>
          </tr>  
        )
    
}