import SDPview from './SDPview';
import { MenuChoices } from '../MenuChoices';
import * as Images from '../images';
import * as GlobalData from '../data';

import React, { useState, useEffect } from "react"

export default function ScheduleView({ placement, setActive, setPopup, setGID, TID, teamLabel, currYear, currWeek })
{
    const [scheduleData, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

        useEffect(() => {
            // Function to fetch data
            const fetchData = async () => {
              try {
                const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_SCHEDULE&TID="+TID
                console.log (url)
                const response = await fetch(url, {
                    method: 'GET', // or 'POST', 'PUT', etc.
                    headers: {
                        'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                        'Content-Type': 'application/json'
                    }
                });
        
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData( LoadSchedule(result, currYear));
              } 
              catch (error) {
                setError(error.message);
              } 
              finally {
                setLoading(false);
              }
            };
        
            if (TID > 0) fetchData();
        
        }, [TID]);  
      
    if (loading) 
        return (<h2>Loading...</h2>);
    else if (error) 
        return (<h2>Error: {error}</h2>);
    else
    {
        const schedule=scheduleData.map((data,id)=>{
            let imgpath=(data.Opp==229?Images.blank:Images.TeamImagePaths[data.Opp]);
                return <ScheduleItem key={id} imgpath={imgpath} scheduleItem={data} setPopup={setPopup} setGID={setGID} currWeek={currWeek}/>
            })
    
        return (
            <>
                <table className="table table-striped tight-table full-width sticky-table">
                <colgroup>
                    <col style={{ width: '10%', textAlign: 'left'}} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                    <col style={{ width: '5%', textAlign: 'center' }} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                    <col style={{ width: '25%', textAlign: 'center' }} />
                    <col style={{ width: '20%', textAlign: 'center' }} />
                </colgroup>
                <tbody>
                <tr><th>Wk</th><th>Date</th><th></th><th>Opp</th><th>Result</th><th>More</th></tr>
                {schedule}
                </tbody>
                </table>
            </>
        )
        }

}

function ScheduleItem({ imgpath, scheduleItem, setPopup, setGID, currWeek})
{
    const getPopup = (val, GID) => {
        console.log ("ScheduleItem GID="+GID+" Popup="+val)
        setGID(parseInt(GID))
        setPopup(parseInt(val))
        }

    // const getGameStats = (val, GID) => {
    //     setGID(GID)
    //     setPopup(val)
    //     }
    
        // console.log (scheduleItem)

    return (
            <tr>
                {currWeek === scheduleItem.Week ? <td style={{backgroundColor:'navy'}}>{scheduleItem.Week}</td> : <td>{scheduleItem.Week}</td>}
                {currWeek === scheduleItem.Week ? <td style={{backgroundColor:'navy'}}>{scheduleItem.Date}</td> : <td>{scheduleItem.Date}</td>}
                {currWeek === scheduleItem.Week ? <td style={{backgroundColor:'navy'}}>{scheduleItem.Vs}</td> : <td>{scheduleItem.Vs}</td> }
                {currWeek === scheduleItem.Week ? 
                <td style={{backgroundColor:'navy'}}><img className='icon-xs' src={imgpath} alt=""></img></td>
                : <td><img className='icon-xs' src={imgpath} alt=""></img></td> }
                {currWeek === scheduleItem.Week ?
                    <>
                    {scheduleItem.Complete === true ? 
                        <td style={{backgroundColor:'navy'}}>{scheduleItem.Score}</td> : <td style={{backgroundColor:'navy'}}>{scheduleItem.Spread}</td> 
                    }
                    </>
                :
                    <>
                    {scheduleItem.Complete ? <td>{scheduleItem.Score}</td> :  <td >{scheduleItem.Spread==99?'TBD':scheduleItem.Spread}</td> }
                    </>
                }
                {currWeek === scheduleItem.Week ?
                    <>
                    {scheduleItem.Complete ?
                    <td><SDPview getPopup={getPopup} GID={scheduleItem.GID} /></td>
                    :<td style={{backgroundColor:'navy'}}>{scheduleItem.Bye?"BYE":<button className="ltblueButtonInv" onClick={() =>  getPopup(MenuChoices.PREVIEW, scheduleItem.GID)} >PREVIEW</button>}</td>
                    }
                    </>
                :
                    <>
                    {scheduleItem.Complete ?
                    <td><SDPview getPopup={getPopup} GID={scheduleItem.GID} /></td>
                    // <td><button className="ltblueButtonInv" onClick={() =>  getGameStats(MenuChoices.GAME_STATS, scheduleItem.GID)} >STATS</button> </td>
                    :<td>{scheduleItem.Bye?"BYE":<button className="ltblueButtonInv" onClick={() =>  getPopup(MenuChoices.PREVIEW, scheduleItem.GID)} >PREVIEW</button>}</td>
                    }
                    </>
                }        
            </tr>
    )
}


export function LoadSchedule(dbGames, currYear)
{
    const  SchedItemType = {
        GID: 0,
        Week  : 0,
        Date  : "",
        Vs    : "",
        Opp   : 229,
        Score : " ",
        Complete: false,
        Neutral: false,
        Bye: false,
        Status : " "
    }

    
    var Schedule = []
    for (let w=0; w<17; w++)
    {
        Schedule.push(Object.create(SchedItemType));
        Schedule[w].GID = 0;	
        Schedule[w].Date = CalculateDateStr(currYear, w, 6) 
        Schedule[w].Opp = 229;
        Schedule[w].Spread = '';
        Schedule[w].Week = w;
        Schedule[w].Bye = true;
    }

    for (var i=0; i<dbGames.length; i++)
    {
        let w = dbGames[i].Week;
        Schedule[w].GID      = dbGames[i].GID;	
        Schedule[w].Week     = dbGames[i].Week;
        Schedule[w].Date     = CalculateDateStr(currYear, dbGames[i].Week, parseInt(dbGames[i].Day)) 
        Schedule[w].Complete = dbGames[i].Complete;
        Schedule[w].Neutral  = dbGames[i].Neutral;
        Schedule[w].Score    = dbGames[i].Score;
        Schedule[w].Status   = dbGames[i].Status;
        Schedule[w].Spread   = dbGames[i].Spread;
        Schedule[w].Opp      = dbGames[i].Opp;
        Schedule[w].Vs       = dbGames[i].Vs;        
        Schedule[w].Bye      = false;
    }
    
    return Schedule;

}    



function CalculateDateStr (yr, wk, dy)
{
    //            2021 2022 2023 2024 2025
    let julianWeeks = [235,234,233,232,231,236];

    let firstMonday = julianWeeks[yr-2021];
     
    let thisDay = firstMonday + wk*7 + dy - 1;

    let obj = JulianToDate (yr, thisDay) // Mon Aug 23  to Sat Aug 28

    let m = obj['mon'];
    let d = obj['day'];
    let str1 = m + " / " + d;

    return str1;
}

function JulianToDate (yr, jdy)
{
    var mon, day;        
    let daysInMonth=[0,31,28,31,30,31,30,31,31,30,31,30,31];

    if (yr % 4 === 0)  daysInMonth[2] = 29; // leap year

    var mjday=0;
    var prev=0;

    for (let i=1; i<=12; i++)
    {
        prev = mjday;
        mjday = mjday + daysInMonth[i];
        if (jdy <= mjday)
        {
            mon = i;
            day = jdy - prev;
            break;
        }
    }

    return {'mon': mon, 'day': day}
}
