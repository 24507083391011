import { FaFootballBall } from "react-icons/fa";
import * as GlobalData from '../data';
import * as Images from '../images';
import { MenuChoices } from '../MenuChoices';
import PossessionInfo from "./PossessionInfo";
import React, { useState } from "react"

//
// Live Box Score with Possession Info 
//
export default function GameInfoLive({gameInfo, possInfo, getPreview, getLive, getPlays, getGameStats, updaterTime })
{
    let GID=gameInfo.GID;

    let awayPath=Images.TeamImagePaths[gameInfo.AwayTID];
    let awayRank=gameInfo.AwayRank;
    let awayTeam=gameInfo.AwayTeam;
    let homePath=Images.TeamImagePaths[gameInfo.HomeTID];;
    let homeTeam=gameInfo.HomeTeam;
    let homeRank=gameInfo.HomeRank;
    let active=gameInfo.Active;
    let complete=gameInfo.Complete
    let spread=gameInfo.Spread;
    let time = FormatTime(gameInfo.Time)

    let networkIdx = GlobalData.networkNames.indexOf(gameInfo.TV)
    if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
    let network=Images.networkImagePaths[networkIdx];

    let possession=possInfo.possession

return (
      <table className="blacktbl">
      <colgroup>
        <col style={{ width: '10%', textAlign: 'left'}} />
        <col style={{ width: '5%', textAlign: 'left'}} />
        <col style={{ width: '3%',  textAlign: 'right'}} />
        <col style={{ width: '30%', textAlign: 'left' }} />
        <col style={{ width: '10%', textAlign: 'right' }} />
        <col style={{ width: '15%', textAlign: 'right' }} />
        <col style={{ width: '15%', textAlign: 'right' }} />
      </colgroup>
      <tbody>
      <tr className='small-row'>
            <td><img className='icon-tiny2' src={awayPath} alt={""}></img></td>
            <td style={{textAlign: 'center'}}>{possession === awayTeam?<FaFootballBall />:''}</td>
            {awayRank <= 25?<td className='gray px10'>{awayRank}</td>:<td></td>}
            <td>{awayTeam}</td>
            <td>{(active || complete)?gameInfo.AwayScore:"0"}</td> 
            <td style={{color: 'khaki'}}>{(active || complete)? gameInfo.Status : time}</td> 
            <td style={{textAlign: 'center'}}>{(complete)?
                <button className='ltblueButtonInv' onClick={() => getPlays(MenuChoices.PLAYS, GID)}>PLAYS</button>
                :<img className='icon-tiny'  src={network} alt=""></img>}</td> 
      </tr>
      <tr className='small-row'>
      <td><img className='icon-tiny2' src={homePath} alt={""}></img></td> 
      <td style={{textAlign: 'center'}}>{possession === homeTeam?<FaFootballBall />:''}</td>
      {homeRank <= 25?<td className='gray px10'>{homeRank}</td>:<td></td>}
      <td>{homeTeam}</td>
      <td>{(active || complete)?gameInfo.HomeScore:"0"}</td> 
      <td>{(active ? <button className='redButtonInv' onClick={() => getLive(MenuChoices.LIVE, GID)}>LIVE</button>:(complete?'':spread))}</td> 
      {(active || complete)
            ?<td><button className='khakiButtonInv' onClick={() => getGameStats(MenuChoices.GAME_STATS, GID, gameInfo)}>STATS</button></td>
            :<td><button className="ltblueButtonInv" onClick={() =>  getPreview(MenuChoices.PREVIEW, GID)} >PREVIEW</button></td>
            } 
      </tr>
      <PossessionInfo possInfo={possInfo} />
        <tr><td colSpan="7"><hr /></td></tr>
      </tbody>
      </table> 
  )
}

function FormatTime(t)
{
    if      (t === '11:00') return '11:00 am';
    else if (t === '11:30') return '11:30 am';
    else if (t === '12:00') return '12:00 pm';
    else if (t === '12:30') return '12:30 pm';
    else if (t === '13:00') return '1:00 pm';
    else if (t === '13:30') return '1:30 pm';
    else if (t === '14:00') return '2:00 pm';
    else if (t === '14:30') return '2:30 pm';
    else if (t === '15:00') return '3:00 pm';
    else if (t === '15:30') return '3:30 pm';
    else if (t === '16:00') return '4:00 pm';
    else if (t === '16:30') return '4:30 pm';
    else if (t === '17:00') return '5:00 pm';
    else if (t === '17:30') return '5:30 pm';
    else if (t === '18:00') return '6:00 pm';
    else if (t === '18:30') return '6:30 pm';
    else if (t === '19:00') return '7:00 pm';
    else if (t === '19:30') return '7:30 pm';
    else if (t === '19:45') return '7:45 pm';
    else if (t === '20:00') return '8:00 pm';
    else if (t === '20:30') return '8:30 pm';
    else if (t === '21:00') return '9:00 pm';
    else if (t === '21:30') return '9:30 pm';
    else if (t === '22:00') return '10:00 pm';
    else if (t === '22:30') return '10:30 pm';
    else if (t === '23:00') return '11:00 pm';
    else if (t === '23:30') return '11:30 pm';
    else return (t)
}
