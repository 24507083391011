import HorizontalLabel from "./HorizontalLabel";
import HorizontalLine from "./HorizontalLine";
import React, { useState, useEffect, useRef } from "react"

export default function DriveGraphic({startYdsToGoal, side, endYdsToGoal})
{
    const w = 420;
    const pxPerYd = w / 120;
    const leftEndZone = 10;
    let leftpx  = (leftEndZone + 100 - startYdsToGoal) * pxPerYd;
    let labelpx = (leftEndZone + 100 - endYdsToGoal) * pxPerYd;
    let len = (startYdsToGoal - endYdsToGoal) * pxPerYd;
    let ydLn =  (endYdsToGoal > 50 ? -1*(endYdsToGoal-100) : endYdsToGoal);
    let label1 = side + " " + ydLn;
    let color = 'khski';
        
    return (
      <>
        <HorizontalLabel left={labelpx-30} label1={label1} color1={color}  />

        <HorizontalLine  left={leftpx} right={0} len={len} />
      </>
      )
}
      

function useWindowWidth() 
{
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

