import { MenuChoices } from "../MenuChoices";
import React, { useState, useEffect } from "react"
import * as GlobalData from '../data';

export default function RosterView ({ placement, rosterLevel, TID, teamLabel, setPID, setPopup })
{
  const [roster, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const getGameLog = (val, PID) => {
    // console.log ("getGameLog Val="+val+" PID="+PID)
    setPID(PID)
    setPopup(val)
}

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_ROSTER&TID="+TID
        console.log (url)
        const response = await fetch(url, {
          method: 'GET', // or 'POST', 'PUT', etc.
          headers: {
              'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
              'Content-Type': 'application/json'
          }
      });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        {
          setData(result);
            // console.log ("Roster length = "+result.length)
        }
      } 
      catch (error) {
        setError(error.message);
      } 
      finally {
        setLoading(false);
      }
    };

    if (TID > 0) fetchData();

}, [TID]);  

  if (loading) 
    return (<h2>Loading...</h2>);
  else if (error) 
    return (<h2>Error: {error}</h2>);
  else if (roster === 0)
    return (<h2>roster not set</h2>);
  else
  {
      const offense = ["QB","RB","FB","WR","TE","OL"];
      const defense = ["DE","DT","DL","LB","DB","CB","S"];
      const special = ["ATH","K","P","LS","KR","PR","PK"];
  
      let RosterOff = FilterRoster (roster, offense);
      let RosterDef = FilterRoster (roster, defense);
      let RosterST  = FilterRoster (roster, special);

      // console.log ("RosterView Level: "+rosterLevel)
      // console.log ("RosterView Off: "+RosterOff.length)
      // console.log ("RosterView Off: "+RosterDef.length)
      // console.log ("RosterView Off: "+RosterST.length)
      
    const offplayers = RosterOff.map((data,id)=>{
        return <RosterSpot key={id} player={data} getGameLog={getGameLog}/>
      })
    const defplayers = RosterDef.map((data,id)=>{
        return <RosterSpot key={id} player={data} getGameLog={getGameLog}/>
      })
    const stplayers = RosterST.map((data,id)=>{
        return <RosterSpot key={id} player={data} getGameLog={getGameLog}/>
      })

    return (
        <>
        <div className={placement}>
            <br></br>
            <table className="table table-dark table-striped tight-table full-width scroll" >
            <colgroup>
                <col style={{ width: '20%', textAlign: 'center'}} />
                <col style={{ width: '50%', textAlign: 'left'}} />
                <col style={{ width: '30%', textAlign: 'left'}} />
                </colgroup>
                <tbody>
                    <tr><th style={{textAlign: 'center', color: 'khaki'}}>#</th>
                        <th style={{textAlign: 'left', color: 'khaki'}}>Name</th>
                        <th style={{textAlign: 'center', color: 'khaki'}}>Pos</th>
                        <th style={{textAlign: 'center', color: 'khaki'}}>Log</th></tr> 
                    {rosterLevel==0?offplayers:<></>}
                    {rosterLevel==1?defplayers:<></>}
                    {rosterLevel==2?stplayers:<></>}
                </tbody>
            </table>
            
            {rosterLevel===2? // Adds space for ST
              <>
                    <div className='popup-item full-width  fixed-height'> </div>
                    <div className='popup-item full-width  fixed-height'> </div>
                    <div className='popup-item full-width  fixed-height'> </div>
                    <div className='popup-item full-width  fixed-height'> </div>
              </>
              :<></>
            }
        </div>
        </>
        )
    }
}

function RosterSpot({ player, getGameLog })
{
    const labels=["--","FR","SO","JR","SR","--"]
    return (
      <tr className='small-row'>
        <td>{player.Jersey}</td> 
        <td style={{textAlign: 'left', color: 'white'}}>{player.CommonName}</td> 
        <td style={{textAlign: 'center', color: 'white'}}>{player.Position}</td>
        <td><button className="ltblueButtonInv" onClick={() =>  getGameLog(MenuChoices.GAMELOG, player.PID)} >GAMES</button></td>
        <td></td>
        </tr>
        )
}

export function FilterRoster(data, positions)
{
          var list=[]
          for (let player of data)
              if (positions.includes(player.Position))
                  list.push(player)
          return list;
}      

