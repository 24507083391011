import * as Images from '../images'
import * as GlobalData from '../data';
import React, { useState, useEffect } from "react"


function TeamInfo ({TID}) 
{
  const [info, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

    useEffect(() => {
        // Function to fetch data
        const fetchData = async () => {
        try {
            const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_TEAM_INFO&TID="+TID;
            console.log (url)
            const response = await fetch(url, {
                method: 'GET', // or 'POST', 'PUT', etc.
                headers: {
                    'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                    'Content-Type': 'application/json'
                }
            });
        
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const result = await response.json();
                setData(result);
        } 
        catch (error) {
            setError(error.message);
        } 
        finally {
            setLoading(false);
        }
        };

        if (TID > 0) fetchData();

    }, [TID]);  

if (loading) 
    return (<h2>Loading...</h2>);
else if (error) 
    return (<h2>Error: {error}</h2>);

else
    return (
        <>
            <table>
            <tbody>
            <tr>
            <td className='coalbk'><img className='icon-sm' src={Images.TeamImagePaths[TID]} alt=""></img></td>
            <td  className='coalbk'>
            {(info.Rank < 30)?
            <h2 className='no-margin'> #{info.Rank} {info.Name} {info.Nickname} ({info.Wins}-{info.Losses})</h2>
            :
            <h2 className='no-margin'> {info.Name} {info.Nickname} ({info.Wins}-{info.Losses})</h2>
            }
            <h3 className='white no-margin'>Coach {info.Coach}</h3>
            <h3 className='white no-margin'>{info.Conference} Conference, {info.Place} place ({info.C_Wins}-{info.C_Losses}) </h3>
            </td>
            <td className='coalbk'><img className='icon-sm' src={Images.TeamImagePaths[TID]} alt=""></img></td>
            </tr>
            </tbody>
            </table>
        </>
        )
}
export default TeamInfo;