import { MenuChoices } from '../MenuChoices';
import * as Images from '../images'


export default function ViewSelector ({value, setView, currWeek, chosenWeek, confLabel, confID, TID})
{
    const handleXactSelect = (event) => {
        event.preventDefault();
        setView(parseInt(event.target.value))
    }

    const imgpaths = {
        [MenuChoices.RANKINGS]: Images.AP,
        [MenuChoices.PREV_WEEK]: Images.Scores,
        [MenuChoices.STANDINGS]: Images.Standings,
        [MenuChoices.STATS]: Images.Stats,
        [MenuChoices.LIVE_SCORES]: Images.Live,
        [MenuChoices.SCHEDULE]: Images.Schedule,
        [MenuChoices.HOME]: Images.Live
      };
    var imgpath;

    // console.log ("VS: " + value + " ConfID="+confID + " TID="+TID)

    if (value === MenuChoices.ROSTER || value === MenuChoices.DEPTH || value === MenuChoices.SCHEDULE || value === MenuChoices.HOME)
        imgpath = Images.TeamImagePaths[TID]

    else if ((value === MenuChoices.STANDINGS || value === MenuChoices.HOME_STRETCH) && confID > 0)
        imgpath = Images.ConfImagePaths[confID]

    else
        imgpath = imgpaths[value]

    return (
        <div className='fixed-height-60 full-width'>
        <table><tbody>
        <tr>
        <td className='coalbk'><img className='icon-xsq' src={imgpath} alt=""></img></td>
        <td className='coalbk'> 
        <select className="khaki coalbk large-font bold "  value={value} onChange={handleXactSelect}>
            <option value={MenuChoices.ABOUT}>ABOUT</option>
            <option value={MenuChoices.RANKINGS}>AP POLL - WEEK {chosenWeek}</option>
            <option value={MenuChoices.PREV_WEEK}>GAMES - WEEK {chosenWeek}</option>
            <option value={MenuChoices.STANDINGS}>STANDINGS - {confLabel}</option>
            <option value={MenuChoices.STATS}>STATISTICS</option>
            <option value={MenuChoices.TV}>TV</option>
            <option value={MenuChoices.HOME}>TEAM HOME</option>
            <option value={MenuChoices.SCHEDULE}>SCHEDULE</option>
            <option value={MenuChoices.HOME_STRETCH}>HOME STRETCH</option>
            <option value={MenuChoices.ROSTER}>ROSTER</option>
            <option value={MenuChoices.DEPTH}>DEPTH CHART</option>
            <option value={MenuChoices.LIVE_SCORES}>LIVE SCORES</option>
            <option value={MenuChoices.LIVE_GAME}>LIVE GAME</option>
            <option value={MenuChoices.LIVE_DRIVES}>LIVE DRIVES</option>
            <option value={MenuChoices.LIVE_PLAYS}>LIVE PLAYS</option>
            <option value={MenuChoices.LIVE_TEAMS}>LIVE TEAMS</option>
            <option value={MenuChoices.LIVE_PLAYERS}>LIVE PLAYERS</option>
            <option value={MenuChoices.TEST}>TEST</option>
        </select>

        </td>
        <td className='coalbk'><img className='icon-xsq' src={imgpath} alt=""></img></td>
        </tr>
        </tbody></table>                
        </div>        

    )
}