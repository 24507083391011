export function FilterGamesByConf (games, confID)
{
    var list = []
    for (var i=0; i<games.length; i++) 
        if (games[i].AwayConf === confID || games[i].HomeConf === confID)
            list.push (games[i]);

    // console.log ("Filtered "+games.length + " games by conf ID="+confID+" #confgames="+list.length)

    return list;
}

export function FilterGamesByDay (games, currDay)
{
    var list = []
    for (var i=0; i<games.length; i++) 
        if (games[i].Day === currDay )
            list.push (games[i]);

    console.log ("Filtered "+games.length + " games by day="+currDay+" #games="+list.length)

    return list;
}

export function FilterGamesByTID (games, TID)
{
    for (var i=0; i<games.length; i++) 
        if (games[i].AwayTID === TID || games[i].HomeTID === TID)
        {
            // console.log ("FILTER "+ games[i].AwayCoach + " " + games[i].HomeCoach)
            return (games[i]);
        }

    return -1;
}

export function FormatTime(t)
{
    if      (t === '11:00') return '11:00 am';
    else if (t === '11:30') return '11:30 am';
    else if (t === '12:00') return '12:00 pm';
    else if (t === '12:30') return '12:30 pm';
    else if (t === '13:00') return '1:00 pm';
    else if (t === '13:30') return '1:30 pm';
    else if (t === '14:00') return '2:00 pm';
    else if (t === '14:30') return '2:30 pm';
    else if (t === '15:00') return '3:00 pm';
    else if (t === '15:30') return '3:30 pm';
    else if (t === '16:00') return '4:00 pm';
    else if (t === '16:30') return '4:30 pm';
    else if (t === '17:00') return '5:00 pm';
    else if (t === '17:30') return '5:30 pm';
    else if (t === '18:00') return '6:00 pm';
    else if (t === '18:30') return '6:30 pm';
    else if (t === '19:00') return '7:00 pm';
    else if (t === '19:30') return '7:30 pm';
    else if (t === '20:00') return '8:00 pm';
    else if (t === '20:30') return '8:30 pm';
    else if (t === '21:00') return '9:00 pm';
    else if (t === '21:30') return '9:30 pm';
    else if (t === '22:00') return '10:00 pm';
    else if (t === '22:30') return '10:30 pm';
    else if (t === '23:00') return '11:00 pm';
    else if (t === '23:30') return '11:30 pm';
    else return (t)
}

export function CountGamesByDay(tmpgames, day)
{
    var cnt=0;
    for (let g=0; g<tmpgames.length; g++)
    {
        if (tmpgames[g].Day === day || day === -1) cnt++
    }
    return cnt;
}

