import * as Images from '../images'

export default function DriveTable({tidSelected, drives})
{
    return (
        <>
        <table>
        <thead>
        <tr style={{textAlign: 'center', fontSize: '0.5em'}}>
            <th>Team</th>
            <th>Drive</th>
            <th>Plays</th>
            <th>Rush Yds</th>
            <th>Pass Yds</th>
            <th>Pen Yds</th>
            <th>Time</th>
            <th>Result</th>
        </tr>
        </thead>
        <tbody>
        {drives.map((item, id) => 
        parseInt(tidSelected) === parseInt(item.TID) ? (
        <tr style={{ textAlign: 'center', fontSize: '0.5em' }} key={id}>
        <td className='blackbk'>
            <img className='icon-tiny' src={Images.TeamImagePaths[item.TID]} alt="" />
        </td>
        <td>{item.DriveNum}</td>
        <td>{item.PlayCnt}</td>
        <td>{item.RushYds}</td>
        <td>{item.PassYds}</td>
        <td>{item.PenaltyYds}</td>
        <td>{item.Time}</td>
        <td>{item.Result}</td>
        </tr>
        ) : null // No need for empty <> elements; `null` is simpler and doesn't render anything
        )}
        </tbody>
        </table>
        </>
    )
}

