
export default function DriveSelector({ driveSelected, setDriveSelected, awayTeam, homeTeam})
{
    // console.log ("...SWS:  ScoresLevel = " + scoresLevel + "activeMenu=" + activeMenu)
    return (
        <div className="box btn-container">
            <button className={"noButton"} ></button>
            <button className={`${driveSelected===awayTeam? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setDriveSelected(awayTeam)} >{awayTeam}</button>
            <button className={`${driveSelected==="BOTH"? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setDriveSelected("BOTH")} >BOTH</button>
            <button className={`${driveSelected===homeTeam? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setDriveSelected(homeTeam)} >{homeTeam}</button>
            <button className={"noButton"} ></button>
        </div>
    )
}