export default function ScoringByQuarter ({drives})
{
    var awayRow=[];
    var homeRow=[];
    var prevAway = 0;
    var prevHome = 0;
    var awayTemp;
    var homeTemp;

    console.log ("# drives = " + drives.length)

    awayRow.push (drives[0].awayTeam);
    homeRow.push (drives[0].homeTeam);


    for (let i = 0; i < drives.length; ++i) 
    {
        console.log (drives[i].drive_number + ". " + drives[i].start_period + "Q " + drives[i].end_period + "Q " + drives[i].end_time + " " + drives[i].drive_result + " " + drives[i].scoring);

        if (drives[i].end_period != drives[i].start_period)
        {
            console.log ("drive crossed quarters " + drives[i].start_period + "/" + drives[i].end_period)
            if (drives[i].offense === drives[i].awayTeam)
            {
                awayTemp = drives[i].start_offense_score;
                homeTemp = drives[i].start_defense_score;
            }
            else
            {
                homeTemp = drives[i].start_offense_score;
                awayTemp = drives[i].start_defense_score;
            }
            console.log ("Score " + awayTemp + "-" + homeTemp)
            awayRow.push (awayTemp - prevAway);
            homeRow.push (homeTemp - prevHome);

            prevAway = awayTemp;
            prevHome = homeTemp;
        }

        else if (drives[i].end_time === "0:00" || drives[i].result === "END OF GAME" )
        {
            console.log ("end of quarter")
            if (drives[i].offense === drives[i].awayTeam)
            {
                awayTemp = drives[i].end_offense_score;
                homeTemp = drives[i].end_defense_score;
            }
            else
            {
                homeTemp = drives[i].end_offense_score;
                awayTemp = drives[i].end_defense_score;
            }
            console.log ("Score " + awayTemp + "-" + homeTemp)
            awayRow.push (awayTemp - prevAway);
            homeRow.push (homeTemp - prevHome);

            prevAway = awayTemp;
            prevHome = homeTemp;
        }

        // console.log ("start: " + drives[i].start_period + "Q " + drives[i].start_time + " " + drives[i].offense + " " + drives[i].start_offense_score + " " + drives[i].defense + " " + drives[i].start_defense_score)
        // console.log ("end: " + drives[i].end_period + "Q "  + drives[i].end_time + " " + drives[i].offense + " " + drives[i].end_offense_score + " " + drives[i].defense + " " + drives[i].end_defense_score)
    }

    awayRow.push (awayTemp);
    homeRow.push (homeTemp);

    console.log ("AwayRow - " + awayRow.length)
    console.log ("HomeRow - " + homeRow.length)

    var awayLine = awayRow.map((data,id)=>{
        return <TDline key={id} val={data} /> 
    })
    var homeLine = homeRow.map((data,id)=>{
        return <TDline key={id} val={data} /> 
    })

    return (
        <table>
            <colgroup>
            <col style={{ width: '25%', textAlign: 'left'}} />
            <col style={{ width: '15%', textAlign: 'center'}} />
            <col style={{ width: '15%', textAlign: 'center' }} />
            <col style={{ width: '15%', textAlign: 'center' }} />
            <col style={{ width: '15%', textAlign: 'center' }} />
            <col style={{ width: '15%', textAlign: 'center' }} />
            </colgroup>
            <tbody>
                <tr style={{textAlign:'center', fontSize: '14px'}}><td>Team</td><td>1</td><td>2</td><td>3</td><td>4</td><td>F</td></tr>
                <tr style={{textAlign:'center', fontSize: '14px'}}>{awayLine}</tr>
                <tr style={{textAlign:'center', fontSize: '14px'}}>{homeLine}</tr>
            </tbody>
        </table>
    )
}

function TDline ({val})
{
    return (<td style={{textAlign: 'center', color: 'khaki'}}>{val}</td>)
}
