import Roster from "./RosterView";
import DepthChartView from "./DepthChartView";
import HomeTeam from "./HomeTeam";
import LiveGameView from "./LiveGameView";
import PlayGameView2 from "./PlayGameView2";
import GamePlaysPopup from "./GamePlaysPopup";
import GameStatistics3 from "./GameStatistics3";
import Preview2 from "./Preview2";
import DriveView from "./DriveView";
import GameLog from './GameLog'
import HeadToHeadView from "./HeadToHeadView";
import PollHistoryView from "./PollHistoryView";

import * as GlobalData from '../data'


import { MenuChoices } from '../MenuChoices';


import React, { useState, useEffect } from "react"

export default function PopupHandler({parent, mobile, currYear, currWeek, updaterTime,  popupState, setPopup, PID,  GID})
{    
    const [rankedTeams, setRankedTeams] = useState(0);
    const [gameResults, setGameResults] = useState(0);
    const [historyData, setHistory] = useState(0);
    
    const [loading1, setLoadingTeams] = useState(true);
    const [loading2, setLoadingGames] = useState(true);
    const [loading3, setLoadingHistory] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Function to fetch data
        const fetchTeams = async () => {
            try {
                const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_TEAMS_BY_RANK";
                console.log (url)
                const response = await fetch(url, {
                    method: 'GET', // or 'POST', 'PUT', etc.
                    headers: {
                        'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            {
              setRankedTeams(result);
            }
            } 
            catch (error) {
                setError(error.message);
            } 
            finally {
                setLoadingTeams(false);
            }
        };

        const fetchGames = async () => {
            try {
                const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_GAME_RESULTS";
                console.log (url)
                const response = await fetch(url, {
                    method: 'GET', // or 'POST', 'PUT', etc.
                    headers: {
                        'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            {
              setGameResults(result);
            }
            } 
            catch (error) {
                setError(error.message);
            } 
            finally {
                setLoadingGames(false);
            }
        };

        const fetchHistory = async () => {
            try {
                const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_POLL_HISTORY";
                console.log (url)
                const response = await fetch(url, {
                    method: 'GET', // or 'POST', 'PUT', etc.
                    headers: {
                        'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            {
              setHistory(result);
            }
            } 
            catch (error) {
                setError(error.message);
            } 
            finally {
                setLoadingHistory(false);
            }
        };

        fetchTeams();
        fetchGames();
        fetchHistory();
    }, []);  

    return (
        <>
            {popupState===MenuChoices.LIVE  ? 
                <div className={"full-width full-height scroll"}>
                    <LiveGameView 
                            placement="box-container"
                            mobile={mobile}
                            setPopup={setPopup}
                            GID={GID}
                            updaterTime={updaterTime}
                            />
                </div>
                : <></> }

            {popupState===MenuChoices.DRIVES  ? 
                <div className={"full-width full-height scroll"}>
                    <DriveView 
                            placement="box-container"
                            mobile={mobile}
                            setPopup={setPopup}
                            GID={GID}
                            />
                </div>
                : <></> }

            {popupState===MenuChoices.PLAYS  ? 
                <div className={"full-width full-height scroll"}>
                    <GamePlaysPopup 
                            placement="box-container"
                            mobile={mobile}
                            setPopup={setPopup}
                            GID={GID}
                            />
                </div>
                : <></> }

            {popupState===MenuChoices.GAME_STATS  ? 
                <div className={"full-width full-height scroll"}>
                    <GameStatistics3
                            placement={""}
                            mobile={mobile}
                            label={"GAME STATISTICS"}
                            setPopup={setPopup}
                            GID={GID}
                            />
                </div>
                : <></> }
            
            {popupState===MenuChoices.PREVIEW  ? 
                <div className={"full-width full-height scroll"}>
                    <Preview2 
                            placement="box-container" 
                            mobile={mobile}
                            setPopup={setPopup}
                            GID={GID}
                            />
                </div>
                : <></> }

            {popupState===MenuChoices.GAMELOG  ? 
                <div className={"full-width full-height scroll"}>
                    <GameLog
                            placement={""}
                            mobile={mobile}
                            label={"GAME LOG"}
                            setPopup={setPopup}
                            PID={PID}
                            />
                </div>
                : <></> }
            {popupState===MenuChoices.HEAD2HEAD  ? 
                <div className={"full-width full-height scroll"}>
                    <HeadToHeadView
                            mobile={mobile}
                            setPopup={setPopup}
                            teams={rankedTeams}
                            games={gameResults}
                            />
                </div>
                : <></> }
            {popupState===MenuChoices.HISTORY  ? 
                <div className={"full-width full-height scroll"}>
                    <PollHistoryView
                            mobile={mobile}
                            setPopup={setPopup}
                            historyData={historyData}
                            />
                </div>
                : <></> }
        </>
    )
}


