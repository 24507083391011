import { MenuChoices } from "../MenuChoices";

export default function GraphSelector({mobile, setPopup})
{
    const callPopup = (val) => {
        setPopup(val)
      }


    // console.log ("...SWS:  ScoresLevel = " + scoresLevel + "activeMenu=" + activeMenu)
    return (
        <div className="box btn-container">
            <button className="whiteButtonInv" ></button>
            <button className="whiteButtonInv" onClick={() =>  callPopup(MenuChoices.HISTORY)} >POLL HISTORY</button>
            <button className="whiteButtonInv" onClick={() =>  callPopup(MenuChoices.HEAD2HEAD)} >HEAD-TO-HEAD</button>
            <button className="whiteButtonInv" ></button>
        </div>
    )
}