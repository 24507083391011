import * as Images from '../images'

export default function PlayTable({tidSelected, plays})
{
    return (
        <>
        <table>
        <thead>
        <tr style={{textAlign: 'center', fontSize: '0.5em'}}>
            <th>Team</th>
            <th>Time</th>
            <th>Down</th>
            <th>YdLn</th>
            {/* <th>Play</th>
            <th>Result</th> */}
            <th>Text</th>
        </tr>
        </thead>
        <tbody>
            {plays.map((item,id) => {
            if (parseInt(tidSelected) === parseInt(item.TID) ) 
            {
                const downdist = item.Down + " & " + item.Dist;
                return (
                <tr style={{textAlign: 'center', fontSize: '0.5em'}} key={id}>
                <td className='blackbk'><img className='icon-tiny' src={Images.TeamImagePaths[item.TID]} alt=""></img></td>
                <td>{item.Time}</td>
                <td>{downdist}</td>
                <td>{item.YardLine}</td>
                <td>{item.PlayText}</td>
                </tr>
                ) ;
            }
            else {
                return null;
            }
        })}
        </tbody>
        </table>
        </>
    )
}
