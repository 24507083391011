export default function StatTable ({placement, layout, fontclass, label, hdrs, rows})
{
    return (
        <div className={placement}>
            <h2 className={fontclass}>{label}</h2>
            <table className={layout}>
            <colgroup>
                <col style={{ width: '70%', textAlign: 'left'}} />
                <col style={{ width: '30%', textAlign: 'left' }} />
            </colgroup>
            <tbody style={{'height': 'auto'}}>
                <tr>
                {hdrs}
                </tr>
                {rows}
            </tbody>
            </table>
        </div>
    )
}
