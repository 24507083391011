import GameType from "./games"
import Cookies from 'universal-cookie';

import { RetrieveCurrentScoreboard} from "./dungeonapi"
import { CountGamesByDay } from "./utilities";
// import {LoadConfMember} from "./images";


//********************* 
//   MAJOR CONSTANTS
//********************* 

const cookies = new Cookies()

export var defaultConfSelected=1;
export var defaultTeamSelected=4;
export var OffSeason=false;

export const ConfLabels=["TBD", "SEC","B1G","ACC","PAC","XII","AAC","CUSA","MWC","SBC","MAC","INDEPENDENTS"];
export const ConfNames=["TBD", "SEC","Big Ten","ACC","PAC-12","Big XII","American","Conference USA","Mountain West","Sun Belt","MAC","INDEPENDENTS"];
export const SEC=1, B1G=2, ACC=3, PAC=4, XII=5, AAC=6, CUSA=7, MWC=8, SBC=9, MAC=10, INDY=11; 
export var Div1Label = "West";
export var Div2Label = "East";

export var currYear=2024;
export var currDay;
export var currWeek=-1;
export var lastWeek;
export var currHour;
export var currMin;

export var apiKey="CFBdungeon2024";

export var GamesByChannel =  
	[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]];

// export var TeamRankByWeek = []
export var Power = []
// var prevRankings = [];               

// export var TwitterLists = [];
// export const majorNetworks=["ABC","CBS","FOX","NBC","ESPN","ESPN2","FS1"];
export const networkNames=["ABC","CBS","FOX","NBC","ESPN","ESPN2","ESPNU","FS1","FS2","SECN","ACC NETWOR","PACN","CBSSN","BIG10","LHN","ESPN+","ESPN3","Stadium","None"]

//********************* 
//   DOWNLOADED DATA
//********************* 

export var Games=[]
export var Teams=[]
export var ConfTeams=[]

// export var LastWeeksGames = []
export var ThisWeeksGames = []

export const emptyTeam = { TID: 0, 
                    Name: '',
                    Logo: '',
                    Record: '',
                    ConfName: '',
                    Spread: 0,
                    Date: '',
                    Time: '',
                    Loc: ' ',
                    Coach: ' ',
                    PassingStats: [{Name: '', Yds: 0, TDs: 0}],
                    RushingStats: [{Name: '', Yds: 0, TDs: 0}],
                    ReceivingStats: [{Name: '', Yds: 0, TDs: 0}],
                    DefensiveStats: [{Name: '', Yds: 0, TDs: 0}]
}
export const emptyInfo = { GID: 0, 
                    AwayTID: 0,
                    HomeTID: 0,
                    Date: '',
                    Time: '',
                    TV: '',
                    Spread: 0,
                    Neutral: false,
                    Complete: false
                }

export var Preview = {Info: emptyInfo, 
                      Away: emptyTeam,
                      Home: emptyTeam}

export var NextOpponent = {TID: 0, 
                           Name: '',
                           Logo: '',
                           Record: '',
                           ConfName: '',
                           Spread: 0,
                           Date: '',
                           Time: '',
                           Loc: ' ',
                           Coach: ' ',
                           PassingStats: [{Name: '', Yds: 0, TDs: 0}],
                           RushingStats: [{Name: '', Yds: 0, TDs: 0}],
                           ReceivingStats: [{Name: '', Yds: 0, TDs: 0}],
                           DefensiveStats: [{Name: '', Yds: 0, TDs: 0}]
}

export var OurRecord = 0;
export var LastGame = 0;
export var NextGame = 0;

export var TempPlays = [{"Offense" :"-", 
                         "Down"     : null, 
                         "Distance" : null,
                         "YardLine" : null,
                         "PlayText" : null}];

export var DriveTime = "00:00:00";
export var ScoreboardTime = "00:00:00";

export var DriveData = {"Status"   : "Upcoming",
                        "Period"   : "TBD",
                        "Clock"    : " ", 
                        "Poss"     : null,
                        "Down"     : null,
                        "Dist"     : null,
                        "YardLine" : null,
                        "HomeID"   : null,
                        "HomeTeam" : null,
                        "HomeScore": 0,
                        "AwayID"   : null,
                        "AwayTeam" : null,
                        "AwayScore": 0, 
                        "Plays"    : TempPlays}
export var ActiveGame = 0;
export function SetActiveGame(x){ActiveGame=x;}

export var AllStandings = []
export var ConfStandings = []
export var CCGames = []
export var BowlGames = []
export var Headlines=[];  
export var Rankings = [];
export var Quarters = [];
export var Plays = [];

export var Recruits = [];
export var Coaches = [];
export var PollHistory;
    
export var PassingStats = []
export var RushingStats = []
export var ReceivingStats = []
export var DefensiveStats = []

export var GamePassingStats = []
export var GameRushingStats = []
export var GameReceivingStats = []
export var GameDefensiveStats = []

export var Roster=[]
export var FutureOpps = []

export var Portal_In  = [];
export var Portal_Out = [];

export var DepthChart;
// export var Highlight;


//********************* 
//   DERIVED DATA
//********************* 

export var Schedule = [];

export var TuesdayGames = []
export var WednesdayGames = []
export var ThursdayGames = []
export var FridayGames = []

export var EarlyGames = []
export var AfternoonGames = []
export var EveningGames = []
export var LateGames = []

export var SortedEarlyGames = []
export var SortedAfternoonGames = []
export var SortedEveningGames = []
export var SortedLateGames = []

export var FbsPassingStats = []
export var FbsRushingStats = []
export var FbsReceivingStats = []

export var ConfPassingStats = []
export var ConfRushingStats = []
export var ConfReceivingStats = []

export var TeamPassingStats = []
export var TeamRushingStats = []
export var TeamReceivingStats = []

export var FbsHeadlines=[];  
export var ConfHeadlines=[];  
export var TeamHeadlines=[];  

export var RosterOff=[]
export var RosterDef=[]
export var RosterST=[]

export var allLen = 0;
export var tueLen = 0;
export var wedLen = 0;
export var thuLen = 0;
export var friLen = 0;
export var satLen = 0;
export var sunLen = 0;
export var monLen = 0;

export var DivRecords1 = []
export var DivRecords2 = []

export var RefreshCnt=0;

export const ConfMemberIDs=[[0],[],[],[],[],[],[],[],[],[],[],[],[]];
    
export function Refresh()
{
    RefreshCnt++;
    console.log ("Data refresh="+RefreshCnt)
}


export function InitDates()
{ 
    var d = new Date();
    var mon = d.getMonth() + 1;
    var day = d.getDate();
    currHour = d.getHours();
    
    currMin = d.getMinutes();

    currYear = d.getFullYear();
 
    currDay = d.getDay() ;
   
    // currWeek = CalculateCurrWeek (parseInt(mon), parseInt(day));
    
    // if (currWeek === -1) OffSeason = true;

    // // *********************************  
    //     // currYear = 2021
    //     // currWeek = 13
    // // *********************************  

    // if (currWeek === 0)
    //   lastWeek = 0;
    // else
    //   lastWeek = currWeek - 1;     

    // // console.log ("..."+currYear+"-"+mon+"-"+day+" Week="+currWeek+" Day="+currDay+" Time="+currHour+":"+currMin)
}
 

export function SetWeekDay(week, day) 
{
    currWeek = week;
    if (currWeek === 0)
        lastWeek = 0;
    else
        lastWeek = currWeek - 1;     
    currDay  = day;

    console.log ("SetWeekDay "+currWeek)
}


export function GetTime()
{
    var d = new Date();
    let hh = d.getHours();
    let mm = d.getMinutes();
    let ss = d.getSeconds();

    if (hh < 10) hh = "0"+hh;
    if (mm < 10) mm = "0"+mm;
    if (ss < 10) ss = "0"+ss;
    
    return (hh + ":" + mm+ ":" + ss);
}

/*****************************************
 * THIS IS SPECIFIC TO 2023 SEASON
 * DETERMINES WEEK OF SEASON 
 * WEEKS END ON SUNDAY (EXCEPT LABOR DAY)
**************************************** */
function CalculateCurrWeek (month, day)
{
    var wk=0;

    if (month === 8)
    {
        if (day < 26)
            wk = 0;
        else
            wk = 1;
    }
    else if (month === 9)
    {
        if      (day <= 1)  wk = 1;  // LABOR DAY IS IN WEEK 1
        else if (day <= 7) wk = 2;
        else if (day <= 14) wk = 3;
        else if (day <= 21) wk = 4;
        else if (day <= 28) wk = 5;
        else                wk = 6;
    }
    else if (month === 10)
    {
        if (day <= 5)  wk = 6;
        else if (day <= 12) wk = 7;
        else if (day <= 19) wk = 8;
        else if (day <= 26) wk = 9;
        else                wk = 10;
    }
    else if (month === 11)
    {
        if (day <= 2)  wk = 10;
        else if (day <= 9) wk = 11;
        else if (day <= 16) wk = 12;
        else if (day <= 23) wk = 13;
        else                wk = 14;
    }
    else if (month === 12)
    {
        if      (day <= 7)  wk = 15;
        else if (day <= 14) wk = 16;
        else wk = 17;  // POST-SEASON
    }
    else if (month === 1)
    {    
        wk = 16; // POST-SEASON
        currYear = currYear - 1;
    }
    else
    {
        wk = 0;
    }

    return wk;
}


export function SetCookie(cname, cvalue, exdays) 
{
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	// var expires = "expires="+d.toUTCString();
	//document.cookie = cname + "=" + cvalue + ";" + "SameSite=Lax;" + expires + ";path=/";
  }
  


export function ProcessCookies()
{
    var c=1, t=4;
	if (cookies.get("confSelected") === undefined)
	{
        console.log ("No cookie set - default ConfSelected ="+ c + " teamSelected=" + t)
		defaultConfSelected = 1;
		defaultTeamSelected = 4;
	}
	else
	{
		c = cookies.get("confSelected");	
		t = cookies.get("teamSelected");	
		defaultConfSelected = 1; // parseInt(c);
		defaultTeamSelected = 4; //parseInt(t);
        console.log ("cookies found ConfSelected ="+ c + " teamSelected=" + t)
	}
}



export function CountGames()
{
    tueLen = CountGamesByDay(ThisWeeksGames, 2)
    wedLen = CountGamesByDay(ThisWeeksGames, 3)
    thuLen = CountGamesByDay(ThisWeeksGames, 4)
    friLen = CountGamesByDay(ThisWeeksGames, 5)
    satLen = CountGamesByDay(ThisWeeksGames, 6)
    sunLen = CountGamesByDay(ThisWeeksGames, 0)
    monLen = CountGamesByDay(ThisWeeksGames, 1)
}





// export function LoadGames(dbGames)
// {
//     for (var i=0; i<dbGames.length; i++)
//     {
//         let g = parseInt(dbGames[i].GID);
        
//         Games[g].Basics (
//                             parseInt (dbGames[i].GID),
//                             parseInt (dbGames[i].AwayTID),
//                             parseInt (dbGames[i].HomeTID),
//                             parseInt (dbGames[i].AwayScore),
//                             parseInt (dbGames[i].HomeScore),
//                             parseInt (dbGames[i].Week),
//                             parseInt (dbGames[i].Day),
//                             dbGames[i].Active,
//                             dbGames[i].Complete);
        
//     }

//     console.log ("...Games loaded cnt="+dbGames.length)

//     RetrieveCurrentScoreboard (currYear, currWeek, LoadThisWeeksGames, Dummy)
// }

export function UpdateThisWeeksScores(dbGames)
{
    for (var i=0; i<dbGames.length; i++)
    {
        for (let j = 0; j<ThisWeeksGames.length; j++)
        {
            if (ThisWeeksGames[j].GID == dbGames[i].GID) 
            {
                ThisWeeksGames[j].AwayScore = dbGames[i].AwayScore;
                ThisWeeksGames[j].HomeScore = dbGames[i].HomeScore;
            }
        }
    }
}

export function LoadThisWeeksGames(dbGames)
{
    ScoreboardTime = GetTime();

    ThisWeeksGames = []


    for (var i=0; i<dbGames.length; i++)
    {
         const Game = Object.create(GameType);	

        Game.Initialize   (parseInt (dbGames[i].GID),
                            parseInt (dbGames[i].AwayTID),
                            dbGames[i].AwayTeam,
                            dbGames[i].AwayRank,
                            dbGames[i].AwayCoach,
                            dbGames[i].AwayStar,
                            parseInt (dbGames[i].AwayConf),
                            parseInt (dbGames[i].AwayScore),

                            parseInt (dbGames[i].HomeTID),
                            dbGames[i].HomeTeam,
                            dbGames[i].HomeRank,
                            dbGames[i].HomeCoach,
                            dbGames[i].HomeStar,
                            parseInt (dbGames[i].HomeConf),
                            parseInt (dbGames[i].HomeScore),
                            
                            parseFloat (dbGames[i].Spread),
                            parseInt (dbGames[i].Week),
                            parseInt (dbGames[i].Day),
                            dbGames[i].TV,
                            dbGames[i].Time,
                            dbGames[i].Status,
                            dbGames[i].Neutral,
                            dbGames[i].Active,
                            dbGames[i].Complete);
    
                            // console.log (Game)
        ThisWeeksGames.push(Game)
    }

    tueLen = CountGamesByDay(ThisWeeksGames, 2)
    wedLen = CountGamesByDay(ThisWeeksGames, 3)
    thuLen = CountGamesByDay(ThisWeeksGames, 4)
    friLen = CountGamesByDay(ThisWeeksGames, 5)
    satLen = CountGamesByDay(ThisWeeksGames, 6)
    sunLen = CountGamesByDay(ThisWeeksGames, 0)
    monLen = CountGamesByDay(ThisWeeksGames, 1)

    // UpdateTvSchedule()
}

export function GetThisGame(gid)
{
    for (let i = 0; i<ThisWeeksGames.length; i++)
        if (ThisWeeksGames[i].GID == gid) return ThisWeeksGames[i];
}



export function LoadConfTeams(dbteams)
{
    ConfTeams=[]

    for (var i=0; i<dbteams.length; i++)
    {
        ConfTeams.push (dbteams[i])
    }

    console.log ("...Conf Teams Loaded  = "+ConfTeams.length)

}

export function Dummy() {}




