import { MenuChoices } from "../MenuChoices";

export default function ScoresSelector({mobile, scoresLevel, setScoresLevel, confLabel})
{
    // console.log ("...SWS:  ScoresLevel = " + scoresLevel + "activeMenu=" + activeMenu)
    return (
        <div className={mobile?"box btn-container":"box btn-container-desktop"}>
            <button className={"noButton"} ></button>
            <button className={`${scoresLevel===0 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setScoresLevel(0)} >FBS</button>
            <button className={`${scoresLevel===1 ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  setScoresLevel(1)} >{confLabel}</button>
            <button className={"noButton"} ></button>
        </div>
    )
}
