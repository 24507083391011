export default function About({currYear, mobile, rev})
{
    return (
        <div className="full-width">
            <h2>CFB Dungeon</h2>
            <p style={{margin: '50px'}}>all of your FBS college football information for the {currYear} season</p>
            <h3>polls</h3>
            <h3>scores</h3>
            <h3>season stats</h3>
            <h3>game stats</h3>
            <h3>previews</h3>
            <h3>schedules</h3>
            <h3>rosters</h3>
            <h3>depth charts</h3>
            <h3>game logs</h3>
            <h3>live data</h3>
            <p style={{margin: '50px'}}>{mobile} version {rev} </p>            
        </div>
    )
}