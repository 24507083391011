import * as GlobalData from '../data';
import { useNavigate } from "react-router-dom"
import { MenuChoices } from '../MenuChoices';
import * as Images from '../images';
import React, { useState, useEffect } from "react"
import GraphSelector from './GraphSelector';

export default function Rankings({ placement, label, mobile, currYear, chosenWeek, setActive, setPopup })
{
    const [rankingData, setData] = useState(null);
    const [undefeatedData, setUndefeated] = useState(null);
    const [oneLossData, setOneLoss] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const setAndMoveUp = (nm) => {
        navigate("/"+nm)
        setActive(MenuChoices.HOME)
      }


    useEffect(() => {
        // Function to fetch data
        const fetchData = async () => {
            try {
                const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_RANKINGS&Year=" + currYear + "&Week=" + chosenWeek;
                console.log (url)
                const response = await fetch(url, {
                    method: 'GET', // or 'POST', 'PUT', etc.
                    headers: {
                        'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            {
              setData(result);
            }
            } 
            catch (error) {
                setError(error.message);
            } 
            finally {
                setLoading(false);
            }
        };
        
        const fetchUndefeated = async () => {
            try {
                const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_UNDEFEATED_TEAMS";
                console.log (url)
                const response = await fetch(url, {
                    method: 'GET', // or 'POST', 'PUT', etc.
                    headers: {
                        'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            {
              setUndefeated(result);
            }
            } 
            catch (error) {
                setError(error.message);
            } 
            finally {
                setLoading2(false);
            }
        };


        const fetchOneLoss = async () => {
            try {
                const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_ONELOSS_TEAMS";
                console.log (url)
                const response = await fetch(url, {
                    method: 'GET', // or 'POST', 'PUT', etc.
                    headers: {
                        'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            {
              setOneLoss(result);
            }
            } 
            catch (error) {
                setError(error.message);
            } 
            finally {
                setLoading3(false);
            }
        };


        fetchData();
        fetchUndefeated();
        fetchOneLoss();
        
    }, [chosenWeek]);  
      
    if (loading || loading2 || loading3) 
        return (<h2>Loading...</h2>);
    else if (error) 
        return (<h2>Error: {error}</h2>);
    else if (rankingData.length < 25)
        return (<h2>Week {chosenWeek} poll is not yet available</h2>);
    else
    {
        const rankings = rankingData.map((data,id)=>{
            var lastpath, opppath;
            let teamName=data.Name;
            let imgpath=Images.TeamImagePaths[data.TID];
            let trendpath = (data.Trend===0) ? Images.flat_path : ((data.Trend===1) ? Images.down_path : Images.up_path);
            if (data.LastOpp === null) 
            {
                data.LastGame = 'BYE';
                lastpath=Images.bye;
            } 
            else
                lastpath=Images.TeamImagePaths[data.LastOpp];;
            
            if (data.NextTID === 229) 
                opppath=Images.bye;
            else
                opppath = Images.TeamImagePaths[data.NextTID];

            return <RankInfo key={id} imgpath={imgpath} lastpath={lastpath} trendpath={trendpath} opppath={opppath}  rankInfo={data} teamName={teamName} setAndMoveUp={setAndMoveUp}/>
        })

        const undefeatedRows = undefeatedData.map((data,id)=>{
            let teamName=data.Name;
            let imgpath=Images.TeamImagePaths[data.TID];
            let record=data.Wins + "-" + data.Losses;

            return <UnrankedInfo key={id} imgpath={imgpath} record={record} rank={data.Rank} teamName={teamName} setAndMoveUp={setAndMoveUp}/>
        })

        const oneLossRows = oneLossData.map((data,id)=>{
            let teamName=data.Name;
            let imgpath=Images.TeamImagePaths[data.TID];
            let record=data.Wins + "-" + data.Losses;

            return <UnrankedInfo key={id} imgpath={imgpath} record={record} rank={data.Rank} teamName={teamName} setAndMoveUp={setAndMoveUp}/>
        })


        return (
            <>
            <RankTable rankings={rankings} />
            <UnRankedTable label="UNRANKED BUT UNDEFEATED" rows={undefeatedRows} />
            <UnRankedTable label="UNRANKED WITH ONE LOSS" rows={oneLossRows} />

            <GraphSelector mobile={mobile} setPopup={setPopup} />
            </>
            )
    }
}

function RankTable({rankings})
{
    return (
    <table className="table table-dark full-width">
        <colgroup>
        <col style={{ width: '2%', textAlign: 'right'}} />
        <col style={{ width: '10%', textAlign: 'center' }} />
        <col style={{ width: '15%', textAlign: 'left'}} />
        <col style={{ width: '15%', textAlign: 'center' }} />
        <col style={{ width: '20%', textAlign: 'left' }} />
        <col style={{ width: '30%', textAlign: 'center' }} />
        </colgroup>
        <tbody>
        <tr><th>Rank</th><th>+/-</th><th>Team</th><th colspan="2">Last Week</th><th>This week</th></tr>
        {rankings}
        </tbody>
    </table>
    )
}

function RankInfo({ imgpath, lastpath, trendpath, opppath, rankInfo, teamName, setAndMoveUp })
{
    return (
        <tr>
        <td style={{ textAlign: 'center', padding: '2px 0', fontSize: '12px'  }}># {rankInfo.Rank}</td> 
        <td ><img className='icon-xsq' src={trendpath}  alt=""></img></td> 
        <td><img className='icon-xsq' src={imgpath} alt="" onClick={() =>  setAndMoveUp(teamName)}></img><p className='no-margin white tiny-font'>{rankInfo.Record}</p></td> 
        <td style={{backgroundColor:'black'}}><img className='icon-tiny' src={lastpath} alt=""></img> </td> 
        { rankInfo.LastGame[0] === 'W' ? <td style={{backgroundColor:'green', fontSize: '10px' }}>{rankInfo.LastGame}</td> :<></> }
        { rankInfo.LastGame[0] === 'L' ? <td style={{backgroundColor:'red', fontSize: '10px' }}>{rankInfo.LastGame}</td> :<></> }
        { rankInfo.LastGame[0] != 'W' &&  rankInfo.LastGame[0] != 'L' ? <td style={{backgroundColor:'black', fontSize: '10px' }}>{rankInfo.LastGame}</td> : <></> }
        <td><img className='icon-xsq' src={opppath} alt=""></img><p className='no-margin white tiny-font'>{rankInfo.Record}</p></td> 
        </tr>
     )
}


function UnRankedTable({label,rows})
{
    return (
        <>
        <br></br>
        <h2>{label}</h2>
        <table className="table table-dark full-width">
            <tbody>
            <tr style={{backgroundColor:'black', fontSize: '12px' }}><th>Rank</th><th>Team</th><th>Record</th></tr>
            {rows}
            </tbody>
        </table>
    </>
    )
}



function UnrankedInfo({ imgpath, record, rank, teamName, setAndMoveUp })
{
    // const path = "./img/"+rankInfo.SafeName+".png";

    return (
        <>
        {rank > 25 ?
            <tr style={{backgroundColor:'black', fontSize: '12px' }}>
                <td># {rank}</td>
            <td><img className='icon-xsq' src={imgpath} alt="" onClick={() =>  setAndMoveUp(teamName)}></img></td> 
            <td style={{ textAlign: 'center', padding: '2px 0', fontSize: '12px' }}>{record}</td> 
            </tr>
        :
        <></>
        }
        </>
     )
}