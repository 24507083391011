import React, { useState, useEffect } from "react"
import * as GlobalData from '../data';
import * as Images from '../images';


export default function LiveGameView({placement, mobile, GID, setPopup, updaterTime})
{
    const [liveData, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(true);

    const closePopup = () => {
        setIsPopupOpen(false);
        setPopup(0)
    }

    console.log ("LiveGameView GID="+GID)
    


  useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_LIVE_PLAYS2&GID="+GID;
          // const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_DRIVE&GID="+GID;
          console.log (url)
          const response = await fetch(url, {
            method: 'GET', // or 'POST', 'PUT', etc.
            headers: {
                'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                'Content-Type': 'application/json'
            }
        });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setData(result);
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      if (GID > 0) fetchData();
  
  }, [updaterTime]);  
  
    if (loading) 
      return (<h2>Loading...</h2>);

    else if (error) 
      return (<h2>Error: {error}</h2>);
      
    else 
    {
      var drives = liveData.Drives.map ((data, id) => {
          return <DriveView key={id} driveData={data} />
        }) 

      return (
        <div className={mobile?"popup-noscroll":'popup-noscroll-desktop'}>
          <div className='flex-container'>
            <div className='popup-item full-width  fixed-height'>
                <button className="close-icon" onClick={closePopup}>&times;</button>
                <br></br>
                <table>
                <tr><td style={{  width: '15%', textAlign: 'left'}}></td>
                    <td style={{  width: '25%', textAlign: 'left'}}>{liveData.AwayTeam} </td>
                    <td style={{  width: '20%', textAlign: 'right'}}>{liveData.AwayScore}</td>
                    <td style={{  width: '25%', textAlign: 'center'}}>{liveData.Status}</td>
                    <td style={{  width: '15%', textAlign: 'left'}}></td></tr>
                <tr><td style={{  width: '15%', textAlign: 'left'}}></td>
                    <td style={{  width: '25%', textAlign: 'left'}}>{liveData.HomeTeam}</td>
                    <td style={{  width: '20%', textAlign: 'right'}}>{liveData.HomeScore}</td>
                    <td style={{  width: '25%', textAlign: 'center'}}>{liveData.Period}Q {liveData.Clock}</td>
                    <td style={{  width: '15%', textAlign: 'left'}}></td></tr>
                </table>
                <br></br>
            </div>
            <div className="popup-item-scroll full-width flexible-height">
            {drives}
            </div>
        </div>
      </div>    
      )
    }
}

function DriveView ({driveData})
{
  var plays = driveData.Plays.map ((data, id) => {
      let time = data.Clock;
      let img = Images.TeamImagePaths[data.OffTID];
      let side = (data.YardsToGoal > 50 ? "on own " : "on opp ");
      let ydln = data.YardLine ;
      let downdist = data.Down + " & " + data.Distance;
      let ptxt = data.PlayText
      return <PlayViewLOS key={id} time={time} img={img} downdist={downdist} ptxt={ptxt}  ydln={ydln} side={side} />  
    })

  return (
     <>
     {/* <h2>{driveData.startPeriod} {driveData.startClock}   Offense: {driveData.offense}</h2>
     <h2># plays = {driveData.playCount}</h2> */}
     <table>
     <colgroup>
                <col style={{ width: '10%', textAlign: 'left'}} />
                <col style={{ width: '10%', textAlign: 'center'}} />
                <col style={{ width: '10%', textAlign: 'center' }} />
                <col style={{ width: '15%', textAlign: 'center' }} />
                <col style={{ width: '55%', textAlign: 'left' }} />
        </colgroup>        
       <tbody>
       {plays}
       </tbody>
     </table>
     <hr></hr>
     </>
   )
}

function PlayView ({playData})
{
  return (
    <>
    <h3>{playData.Clock} {playData.Down} and {playData.Distance} on the  {playData.YardLine} :</h3> 
    <h3>{playData.PlayText}</h3>
    </>
  )
}

function PlayViewLOS({time, img, downdist, ptxt, ydln, side, scoring})
{
    return (
        <tr>
            <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{time}</td>
            <td><img className='icon-tiny' src={img} alt={""}></img></td>
            <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{downdist}</td>
            <td style={{  padding: '2px 0', fontSize: '0.7em' }}>{side}{ydln}</td>
            <td style={{ textAlign: 'left', padding: '2px 0', fontSize: '0.7em' }}>{ptxt}</td>
        </tr>
    )
}

