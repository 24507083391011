import React, { useState,useEffect } from "react"
import * as Images from '../images';
import * as GlobalData from '../data';


export default function HomeStretch ({confID, currYear, currWeek})
{
    const [stretchData, setData] = useState(0);
    const [error, setError] = useState(0);
    const [loading, setLoading] = useState(true);

useEffect(() => {
    // Function to fetch data

    const fetchData = async () => {
      try {
        const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=HOME_STRETCH&Week="+currWeek+"&Conf="+confID;
        const response = await fetch(url, {
          method: 'GET', // or 'POST', 'PUT', etc.
          headers: {
              'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
              'Content-Type': 'application/json'
          }
      });
        console.log (url)
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        {
          setData(result);
        }
      } 
      catch (error) {
        setError(error.message);
      } 
      finally {
        setLoading(false);
      }
    };

    fetchData();

}, [confID]);  

if (loading)
    return (<h2>...loading</h2>)
else
{
    return (
        <>
        {/* <h2>CONF ID = {stretchData[0].ConfID}</h2> */}
        <table style={{margin:'6px' }}>
        <tbody>
            <tr><th>Team</th><th>{currWeek}</th><th>{currWeek+1}</th><th>{currWeek+2}</th><th>{currWeek+3}</th></tr>
            {stretchData.map((team, key1) => (
            <tr key={key1} style={{backgroundColor:'black' }}>
            <td style={{backgroundColor:'white' }}><img className='icon-xsq' src={Images.TeamImagePaths[team.TID]} alt=""></img></td> 
                {team.Last4Games.map((opp, key2) => (
                    <td key={key2} style={{ backgroundColor: opp.Home ? 'black' : '#404040' }}>
                    <img className="icon-xsq" src={Images.TeamImagePaths[opp.Opp]} alt="" />
                    </td>
                ))}
            </tr>
            ))}
        </tbody>
        </table>
        <br></br>
        <table>
            <tbody>
            <tr style={{backgroundColor:'black' }}>
            <th style={{backgroundColor:'black' }}></th>
            <th style={{backgroundColor:'black' }}></th>
            <th style={{backgroundColor:'black', border: '1px solid gray', fontSize: '10px' }}>home</th>
            <th style={{backgroundColor:'black' }}></th>
            <th style={{backgroundColor:'black' }}></th>
            <th style={{backgroundColor:'#404040', border: '1px solid gray' , fontSize: '10px' }}>away</th>
            <th style={{backgroundColor:'black' }}></th> 
            <th style={{backgroundColor:'black' }}></th>
            </tr>
        </tbody>
        </table>
        <br></br>
        </>
    )
}
}