import PlayTable from "./PlayTable";
import React, { useState, useEffect } from "react"
import * as GlobalData from '../data';
import * as Images from '../images';

export default function GamePlaysPopup({placement, setPopup, GID})
{
    const [playLevel, setPlayLevel] = useState(0);
    const [plays, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [error, setError] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(true);

    const closePopup = () => {
        setIsPopupOpen(false);
        setPopup(0)
    }
    
    const ChangePlayLevel = (val) => {
        setPlayLevel(val)
      }

      const [awayTeam, setAwayTeam] = useState(0);
      const [awayTID, setAwayTID] = useState(0);
      const [homeTeam, setHomeTeam] = useState(0);
      const [homeTID, setHomeTID] = useState(0);
  
      const [tmSelected, setTmSelected] = useState(0);
      const [tidSelected, setTidSelected] = useState(0);
  
      const saveTeam = (tid,name)  => {
          setTidSelected(tid);
          setTmSelected(name);
      }
  
      useEffect(() => {
          // Function to fetch data
          const fetchData = async () => {
            try {
                const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_GAME_INFO&GID="+GID;
                console.log (url)
                const response = await fetch(url, {
                  method: 'GET', // or 'POST', 'PUT', etc.
                  headers: {
                      'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                      'Content-Type': 'application/json'
                  }
              });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const results = await response.json();
                {
                    setAwayTeam( results.AwayTeam);
                    setAwayTID( results.AwayTID);
                    setHomeTeam( results.HomeTeam);
                    setHomeTID( results.HomeTID);
                    saveTeam(results.HomeTID, results.HomeTeam)
                }
            } 
            catch (error) {
              setError(error.message);
            } 
            finally {
              setLoadingInfo(false);
            }
          };
      
          fetchData();
      
        }, [GID]);  
        
  

      useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
//            const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_PLAYS_BY_GID&GID="+GID;  // 
            const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_LIVE_PLAYS2&GID="+GID;  
            console.log (url)
            const response = await fetch(url, {
                method: 'GET', // or 'POST', 'PUT', etc.
                headers: {
                    'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                    'Content-Type': 'application/json'
                }
            });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setData(result);
            
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      if (GID > 0) fetchData();
  
  }, [GID]);  
  
    if (loading) 
      return (<h2>Loading...</h2>);
    else if (error) 
      return (<h2>Error: {error}</h2>);
    else 
    {

        return (
            <div className="popup-noscroll">
                <div className='flex-container'>
                    <div className='popup-item full-width  fixed-height'>
                        <button className="close-icon" onClick={closePopup}>&times;</button>
                        <h2 className='khaki'>PLAYS</h2>
                        <div className="box btn-container-desktop">
                            <button className={"noButton"} ></button>
                            <button className={`${tmSelected===awayTeam? "ltblueButtonInv" : "whiteButtonInv"}`} 
                                    onClick={() =>  saveTeam(awayTID, awayTeam)} >{awayTeam}</button>
                            <button className={`${tmSelected===homeTeam? "ltblueButtonInv" : "whiteButtonInv"}`} 
                                    onClick={() =>  saveTeam(homeTID, homeTeam)} >{homeTeam}</button>   
                            <button className={"noButton"} ></button>
                        </div>
                    </div>
                    <div  className="full-width scroll" style={{height: '50%'}}>
                        <PlayTable tidSelected={tidSelected} plays={plays} />
                    </div>
                </div>
            </div>    
        )
    }
}
