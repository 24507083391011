import * as GlobalData from '../data';
import * as Images from '../images';
import GameInfoTV from './GameInfoTV';

import React from "react"

export default function TvSchedule({placement})
{
  var TuesdayGames ;
  var WednesdayGames ;
  var ThursdayGames ;  
  var FridayGames ;
  var SaturdayGames ;
  var EarlyGames ;
  var AfternoonGames;
  var EveningGames;
  var LateGames;
  var TuesdayTVGames;
  var WednesdayTVGames ;
  var ThursdayTVGames;  
  var FridayTVGames;
  var EarlyTVGames ;
  var AfternoonTVGames;
  var EveningTVGames;
  var LateTVGames;

  const earlyTimes = ["11:00", "11:30","12:00", "12:30","13:00", "13:30"]; 
  const afternoonTimes = ["14:00", "14:30","15:00", "15:30","16:00", "16:30","17:00", "17:30"]; 
  const eveningTimes = ["18:00", "18:30", "19:00", "19:30","20:00", "20:30","21:00"]; 
  const lateTimes = ["21:30","22:00", "22:15","22:30","23:00", "23:30"]; 
  const otaNetworks=["ABC","CBS","FOX","NBC"];
  const majorNetworks=["ESPN","ESPN2","FS1"];

  // console.log ("ThisWeeksGames count="+GlobalData.ThisWeeksGames.length)
  TuesdayGames   = FilterGamesByDay (GlobalData.ThisWeeksGames, 2);
  WednesdayGames = FilterGamesByDay (GlobalData.ThisWeeksGames, 3);
  ThursdayGames  = FilterGamesByDay (GlobalData.ThisWeeksGames, 4);
  FridayGames    = FilterGamesByDay (GlobalData.ThisWeeksGames, 5);
  SaturdayGames  = FilterGamesByDay (GlobalData.ThisWeeksGames, 6);
  EarlyGames     = FilterGamesByTime (SaturdayGames, earlyTimes);
  AfternoonGames = FilterGamesByTime (SaturdayGames, afternoonTimes);
  EveningGames   = FilterGamesByTime (SaturdayGames, eveningTimes);
  LateGames      = FilterGamesByTime (SaturdayGames, lateTimes);

  TuesdayGames   = SortMajor (TuesdayGames, otaNetworks, majorNetworks);
  WednesdayGames = SortMajor (WednesdayGames, otaNetworks, majorNetworks);
  ThursdayGames  = SortMajor (ThursdayGames, otaNetworks, majorNetworks);
  FridayGames    = SortMajor (FridayGames, otaNetworks, majorNetworks);
  EarlyGames     = SortMajor (EarlyGames, otaNetworks, majorNetworks);
  AfternoonGames = SortMajor (AfternoonGames, otaNetworks, majorNetworks);
  EveningGames   = SortMajor (EveningGames, otaNetworks, majorNetworks);
  LateGames      = SortMajor (LateGames, otaNetworks, majorNetworks);
  
  // console.log ("E="+EarlyGames.length + " AF="+AfternoonGames.length + " EV="+EveningGames.length + " L="+LateGames.length);

  TuesdayTVGames = TuesdayGames.map((data,id)=>{
    return <GameInfoTV key={id} gameInfo={data}/>
  })
  
  WednesdayTVGames = WednesdayGames.map((data,id)=>{
    return <GameInfoTV key={id} gameInfo={data}/>
  })
    
  ThursdayTVGames =ThursdayGames.map((data,id)=>{
    return <GameInfoTV key={id} gameInfo={data}/>
  })
      
  FridayTVGames = FridayGames.map((data,id)=>{
    return <GameInfoTV key={id} gameInfo={data}/>
  })
        
  EarlyTVGames = EarlyGames.map((data,id)=>{
    return <GameInfoTV key={id} gameInfo={data}/>
  })

  AfternoonTVGames = AfternoonGames.map((data,id)=>{
    return <GameInfoTV key={id} gameInfo={data}/>
  })

  EveningTVGames = EveningGames.map((data,id)=>{
    return <GameInfoTV key={id} gameInfo={data}/>
  })

  LateTVGames = LateGames.map((data,id)=>{
    return <GameInfoTV key={id} gameInfo={data}/>
  })

    const tueLen = TuesdayGames.length;
    const wedLen = WednesdayGames.length;
    const thuLen = ThursdayGames.length;
    const friLen = FridayGames.length;
    const earlyLen = EarlyGames.length;
    const afternoonLen = AfternoonGames.length;
    const eveningLen = EveningGames.length;
    const lateLen = LateGames.length;
    

    return (
      <>
            <div className={"full-width full-height scroll"}>
              {tueLen>0?<br></br>:<></>}
              {tueLen>0?<h3>{"TUESDAY NIGHT GAMES"}</h3>:<></>}
              {tueLen>0?<>{TuesdayTVGames}</>:<></>}
              {wedLen>0?<br></br>:<></>}
              {wedLen>0?<h3>{"WEDNESDAY NIGHT GAMES"}</h3>:<></>}
              {wedLen>0?<>{WednesdayTVGames}</>:<></>}
              {thuLen>0?<br></br>:<></>}
              {thuLen>0?<h3>{"THURSDAY NIGHT GAMES"}</h3>:<></>}
              {thuLen>0?<>{ThursdayTVGames}</>:<></>}
              {friLen>0?<br></br>:<></>}
              {friLen>0?<h3>{"FRIDAY NIGHT GAMES"}</h3>:<></>}
              {friLen>0?<>{FridayTVGames}</>:<></>}
              {earlyLen>0?<br></br>:<></>}
              {earlyLen>0?<h3>{"SATURDAY - EARLY GAMES"}</h3>:<></>}
              {earlyLen>0?<>{EarlyTVGames}</>:<></>}
              {afternoonLen>0?<br></br>:<></>}
              {afternoonLen>0?<h3>{"SATURDAY - AFTERNOON GAMES"}</h3>:<></>}
              {afternoonLen>0?<>{AfternoonTVGames}</>:<></>}
              {eveningLen>0?<br></br>:<></>}
              {eveningLen>0?<h3>{"SATURDAY - EVENING GAMES"}</h3>:<></>}
              {eveningLen>0?<>{EveningTVGames}</>:<></>}
              {lateLen>0?<br></br>:<></>}
              {lateLen>0?<h3>{"SATURDAY - LATE GAMES"}</h3>:<></>}
              {lateLen>0?<>{LateTVGames}</>:<></>}
            </div>
        </>
    )
}


export function FilterGamesByDay (games, day)
{
  var list=[]
  for (let game of games)
    if (game.Day === day)
      list.push(game)
  return list;
}

export function FilterGamesByTime (games, times)
{
  var list=[];
  for (let game of games)
    if (times.includes(game.Time))
      list.push(game);
  
  return list;
}

export function SortMajor (games, networksOTA, networksMajor)
{
  var ota=[];
  var major=[];
  var minor=[];

  var last = games.length;
  for(let idx=0; idx<last; idx++) 
      if (networksOTA.includes(games[idx].TV) )
          ota.push(games[idx]);

      else if (networksMajor.includes(games[idx].TV) )
          major.push(games[idx]);
      else
          minor.push(games[idx]);
  
  var list=[];
  list = ota.concat(major);
  list = list.concat(minor);
  return list;
}
