import { MenuChoices } from "../MenuChoices"
export default function DepthChartRow({pos, jersey, name, pid, getGameLog})
{
    return (
        <tr className='small-row'>
        <td>{pos}</td>
        <td>{jersey}</td>
        <td style={{textAlign: 'left', color: 'white'}}>{name}</td>
        <td><button className="ltblueButtonInv" onClick={() =>  getGameLog(MenuChoices.GAMELOG, pid)} >GAMES</button></td>
        </tr>
    )
}

  