import MainMobile from './MainMobile'
import MainDesktop from './MainDesktop'
import TestGrid  from './TestGrid'

import React from "react"
import { useNavigate } from "react-router-dom"



export default function MainFBS({mobile, rev, activeMenu, setActive, GID, setGID, currYear, currWeek, currDay})
{
  const justSet = (val) => {
    setActive(val)
  }

  const navigate = useNavigate();

  const setAndMoveUp = (nm, val) => {
    navigate("/"+nm)
    setActive(val)
  }

  console.log ("Mobile = " + mobile)
  

return (
    <>
      {/* <TestGrid /> */}

    {mobile?

      <MainMobile 
                   parent         = {"MainFBS"}
                   currYear       = {currYear}
                   currWeek       = {currWeek}
                   currDay       =  {currDay}
                   activeMenu     = {activeMenu}
                   setActive      = {setActive}
                   setAndMoveUp   = {setAndMoveUp}
                   setGID         = {setGID}
                   GID            = {GID}
                   initStatLevel  = {0} 
                   confID         = {0}
                   TID            = {0}
                   confLabel      = {"CONF"}
                   teamLabel      = {"TEAM"}
                   rev={rev}
                   mobile={mobile} 
                   /> 
    :
       <MainDesktop 
                    parent         = {"MainFBS"}
                    currYear       = {currYear}
                    currWeek       = {currWeek}
                    currDay       =  {currDay}
                    activeMenu     = {activeMenu}
                    setActive      = {setActive}
                    setGID         = {setGID}
                    GID            = {GID}
                    initStatLevel  = {0} 
                    confID         = {0}
                    TID            = {0}
                    confLabel      = {"CONF"}
                    teamLabel      = {"TEAM"}
                    rev={rev}
                    mobile={mobile} 
               />

    }

  </>
  )
}
