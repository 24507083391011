import * as GlobalData from '../data';
import * as Images from '../images';
import { FormatTime} from "../utilities"


//************************ */
// SEE TV SCHEDULE VIEW
//************************ */

export default function GameInfoTV ({gameInfo })
{
    let awayPath=Images.TeamImagePaths[gameInfo.AwayTID];
    let awayRank=gameInfo.AwayRank;
    let awayScore=gameInfo.AwayScore;
    
    let awayTeam=gameInfo.AwayTeam;
    let homePath=Images.TeamImagePaths[gameInfo.HomeTID];;
    let homeTeam=gameInfo.HomeTeam;
    let homeRank=gameInfo.HomeRank;
    let homeScore=gameInfo.HomeScore;
    let active=gameInfo.Active;
    let complete=gameInfo.Complete
    let time = FormatTime(gameInfo.Time)

    let networkIdx = GlobalData.networkNames.indexOf(gameInfo.TV)
    if (networkIdx === -1) networkIdx=GlobalData.networkNames.indexOf("None");
    let network=Images.networkImagePaths[networkIdx];

return (
  <>
      <table className="blacktbl">
      <colgroup>
        <col style={{ width: '10%', textAlign: 'left'}} />
        <col style={{ width: '3%',  textAlign: 'right'}} />
        <col style={{ width: '30%', textAlign: 'left' }} />
        <col style={{ width: '10%', textAlign: 'right' }} />
        <col style={{ width: '20%', textAlign: 'right' }} />
        <col style={{ width: '20%', textAlign: 'right' }} />
      </colgroup>
      <tbody>
      <tr className='small-row'>
        <td><img className='icon-tiny2' src={awayPath} alt={""}></img></td>
        {awayRank <= 25?<td className='gray px10'>{awayRank}</td>:<td></td>}
        <td>{awayTeam}</td>
        <td>{(active || complete)?awayScore:"0"}</td> 
        <td style={{textAlign: 'center'}}><img className='icon-tiny2'  src={network} alt=""></img></td> 
      </tr>
      <tr className='small-row'>
        <td><img className='icon-tiny2' src={homePath} alt={""}></img></td> 
        {homeRank <= 25?<td className='gray px10'>{homeRank}</td>:<td></td>}
        <td>{homeTeam}</td>
        <td>{(active || complete)?homeScore:"0"}</td> 
        <td style={{textAlign: 'center'}}>{time}</td> 
      </tr>
      </tbody>
      </table> 
          :<></>
  </>
  )
}

