import Updater from './Updater';
import PopupHandler from './PopupHandler';
import ViewSelector from './ViewSelector';
import ConfBar from "./ConfBar";
import TeamBar from "./TeamBar";
import HandleCommon from './HandleCommon';

import { MenuChoices } from '../MenuChoices';

import React, { useState, useEffect } from "react"

export default function MainDesktop({parent, rev,mobile, currYear, currWeek, currDay, activeMenu, setActive, GID, setGID, teamMenu, setTeamMenu, confID, confLabel, TID, teamLabel})
{
    const [popupState, setPopup] = useState(0);
    
    const [gamesRetrieved, setGamesRetrieved] = useState(false)  
    const [scoresWeek, setScoresWeek] = useState(1);
    const [scoresLevel, setScoresLevel] = useState(0);
    const [statLevel, setStatLevel] = useState(0)
    const [playerLevel, setPlayerLevel] = useState(0)
    const [rosterLevel, setRosterLevel] = useState(0);
    
    const [activeView1, setView1] = useState(MenuChoices.HOME)
    const [activeView2, setView2] = useState(MenuChoices.ROSTER)
    const [activeView3, setView3] = useState(MenuChoices.LIVE_TEAMS)
    const [activeView4, setView4] = useState(MenuChoices.LIVE_DRIVES)
    const [activeView5, setView5] = useState(MenuChoices.STATS)
    const [activeView6, setView6] = useState(MenuChoices.LIVE_GAME)
    const [activeView7, setView7] = useState(MenuChoices.LIVE_PLAYERS)
    const [activeView8, setView8] = useState(MenuChoices.LIVE_PLAYS)

    // const [statLevel, setStatLevel] = useState((TID>0 ? 2 : (confID>0? 1 : 0)));

    const [PID, setPID] = useState(0);

    const [updaterTime, setUpdaterTime] = useState(0)


    const [chosenWeek, setChosenWeek] = useState(currWeek-1);

        
    useEffect(() => {
    
        setChosenWeek(currWeek-1);
    
      }, [currWeek]);  

// console.log ("currWeek="+currWeek + " chosenWeek="+chosenWeek)
if (currWeek < 0)
    return (
        <div className={" grid-container-desktop"}>
        <div className="middle-column-desktop-1 ">
        <h2>WEEK NOT SET</h2>
        </div>
        </div>
    )

else
{
    
  return (

    <div className={" grid-container-desktop"}>
                <Updater currWeek       = {currWeek}
                     setUpdaterTime = {setUpdaterTime}
                />
                <PopupHandler 
                    parent         = {"MainFBS"}
                    currYear       = {currYear}
                    currWeek       = {currWeek}
                    updaterTime    = {updaterTime}
                    activeMenu     = {activeMenu}
                    setActive      = {setActive}
                    popupState     = {popupState}
                    setPopup       = {setPopup}
                    PID            = {PID}
                    setPID         = {setPID}
                    setGID         = {setGID}
                    GID            = {GID}
                    initStatLevel  = {0} 
                    confID         = {0}
                    TID            = {0}
                    scoresLevel    = {scoresLevel}
                    setScoresLevel = {setScoresLevel}
                    confLabel      = {confLabel}
                    teamLabel      = {teamLabel}
                />

        <div className="left-column-desktop">
            <ConfBar placement="" 
                setGID={setGID}
                rev={rev}
                mobile={mobile} />
        </div>

        <div className="middle-column-desktop-1 ">
            <div className="half-height border-wheat">
                <ViewSelector 
                                value={activeView1} 
                                setView={setView1}
                                currWeek={currWeek} 
                                chosenWeek={chosenWeek} 
                                confLabel={confLabel}
                                confID={confID}
                                TID={TID} />

                <HandleCommon   parent={"desktop-1"} 
                                mobile={mobile}
                                rev={rev}
                                currYear={currYear}
                                currWeek={currWeek}
                                currDay={currDay}
                                chosenWeek={chosenWeek}
                                setChosenWeek={setChosenWeek}
                                updaterTime={updaterTime}
                                activeMenu={activeView1} 
                                setActive={setView1} 
                                popupState={popupState} 
                                setPopup={setPopup} 
                                PID={PID} 
                                setPID={setPID} 
                                GID={GID} 
                                setGID={setGID} 
                                teamMenu={teamMenu}
                                setTeamMenu={setTeamMenu} 
                                scoresLevel={scoresLevel} 
                                setScoresLevel={setScoresLevel} 
                                confID={confID} 
                                confLabel={confLabel} 
                                TID={TID} 
                                teamLabel={teamLabel} 
                                
                                /> 
            </div>
            <div className="half-height border-wheat">
                <ViewSelector 
                                value={activeView5} 
                                setView={setView5}
                                currWeek={currWeek} 
                                chosenWeek={chosenWeek}
                                confLabel={confLabel}
                                confID={confID}
                                TID={TID} />

                <HandleCommon   parent={"desktop-5"} 
                                mobile={mobile}
                                currYear={currYear}
                                currWeek={currWeek}
                                currDay={currDay}
                                chosenWeek={chosenWeek}
                                setChosenWeek={setChosenWeek}
                                updaterTime={updaterTime}
                                activeMenu={activeView5} 
                                setActive={setView5} 
                                popupState={popupState} 
                                setPopup={setPopup} 
                                PID={PID} 
                                setPID={setPID} 
                                GID={GID} 
                                setGID={setGID} 
                                teamMenu={teamMenu}
                                setTeamMenu={setTeamMenu} 
                                scoresLevel={scoresLevel} 
                                setScoresLevel={setScoresLevel} 
                                confID={confID} 
                                confLabel={confLabel} 
                                TID={TID} 
                                teamLabel={teamLabel} 
                                
                                /> 

            </div>
        </div>

        <div className="middle-column-desktop-2 ">
            <div className="half-height border-wheat">
                <>
                <ViewSelector 
                                value={activeView2} 
                                setView={setView2}
                                currWeek={currWeek} 
                                chosenWeek={chosenWeek} 
                                confLabel={confLabel}
                                confID={confID}
                                TID={TID} />

                <HandleCommon   parent={"desktop-2"} 
                                mobile={mobile}
                                currYear={currYear}
                                currWeek={currWeek}
                                currDay={currDay}
                                chosenWeek={chosenWeek}
                                setChosenWeek={setChosenWeek}
                                updaterTime={updaterTime}
                                activeMenu={activeView2} 
                                setActive={setView2} 
                                popupState={popupState} 
                                setPopup={setPopup} 
                                PID={PID} 
                                setPID={setPID} 
                                GID={GID} 
                                setGID={setGID} 
                                teamMenu={teamMenu}
                                setTeamMenu={setTeamMenu} 
                                scoresLevel={scoresLevel} 
                                setScoresLevel={setScoresLevel} 
                                confID={confID} 
                                confLabel={confLabel} 
                                TID={TID} 
                                teamLabel={teamLabel} 
                                
                                /> 
                
                </>
            </div>
            <div className="half-height border-wheat">
                <ViewSelector 
                                value={activeView6} 
                                setView={setView6}
                                currWeek={currWeek} 
                                chosenWeek={chosenWeek} 
                                confLabel={confLabel}
                                confID={confID}
                                TID={TID} />

                <HandleCommon   parent={"desktop-6"} 
                                mobile={mobile}
                                currYear={currYear}
                                currWeek={currWeek}
                                currDay={currDay}
                                chosenWeek={chosenWeek}
                                setChosenWeek={setChosenWeek}
                                updaterTime={updaterTime}
                                activeMenu={activeView6} 
                                setActive={setView6} 
                                popupState={popupState} 
                                setPopup={setPopup} 
                                PID={PID} 
                                setPID={setPID} 
                                GID={GID} 
                                setGID={setGID} 
                                teamMenu={teamMenu}
                                setTeamMenu={setTeamMenu} 
                                scoresLevel={scoresLevel} 
                                setScoresLevel={setScoresLevel} 
                                confID={confID} 
                                confLabel={confLabel} 
                                TID={TID} 
                                teamLabel={teamLabel}                             
                                /> 

            </div>
        </div>

        <div className="middle-column-desktop-3 ">
            <div className="half-height border-wheat">
                <ViewSelector 
                                value={activeView3} 
                                setView={setView3}
                                currWeek={currWeek} 
                                chosenWeek={chosenWeek} 
                                confLabel={confLabel}
                                confID={confID}
                                TID={TID} />

                <HandleCommon   parent={"desktop-3"} 
                                mobile={mobile}
                                currYear={currYear}
                                currWeek={currWeek}
                                currDay={currDay}
                                chosenWeek={chosenWeek}
                                setChosenWeek={setChosenWeek}
                                updaterTime={updaterTime}
                                activeMenu={activeView3} 
                                setActive={setView3} 
                                popupState={popupState} 
                                setPopup={setPopup} 
                                PID={PID} 
                                setPID={setPID} 
                                GID={GID} 
                                setGID={setGID} 
                                teamMenu={teamMenu}
                                setTeamMenu={setTeamMenu} 
                                scoresLevel={scoresLevel} 
                                setScoresLevel={setScoresLevel} 
                                confID={confID} 
                                confLabel={confLabel} 
                                TID={TID} 
                                teamLabel={teamLabel}                             
                                /> 
            </div>
            <div className="half-height border-wheat">
                <ViewSelector 
                                value={activeView7} 
                                setView={setView7}
                                currWeek={currWeek} 
                                chosenWeek={chosenWeek} 
                                confLabel={confLabel}
                                confID={confID}
                                TID={TID} />

                <HandleCommon   parent={"desktop-7"} 
                                mobile={mobile}
                                currYear={currYear}
                                currWeek={currWeek}
                                currDay={currDay}
                                chosenWeek={chosenWeek}
                                setChosenWeek={setChosenWeek}
                                updaterTime={updaterTime}
                                activeMenu={activeView7} 
                                setActive={setView7} 
                                popupState={popupState} 
                                setPopup={setPopup} 
                                PID={PID} 
                                setPID={setPID} 
                                GID={GID} 
                                setGID={setGID} 
                                teamMenu={teamMenu}
                                setTeamMenu={setTeamMenu} 
                                scoresLevel={scoresLevel} 
                                setScoresLevel={setScoresLevel} 
                                confID={confID} 
                                confLabel={confLabel} 
                                TID={TID} 
                                teamLabel={teamLabel} 
                                
                                /> 

            </div>
        </div>

        <div className="middle-column-desktop-4 ">
            <div className="half-height border-wheat">
                <ViewSelector 
                                value={activeView4} 
                                setView={setView4}
                                currWeek={currWeek} 
                                chosenWeek={chosenWeek} 
                                confLabel={confLabel}
                                confID={confID}
                                TID={TID} />

                <HandleCommon   parent={"desktop-4"} 
                                mobile={mobile}
                                currYear={currYear}
                                currWeek={currWeek}
                                currDay={currDay}
                                chosenWeek={chosenWeek}
                                setChosenWeek={setChosenWeek}
                                updaterTime={updaterTime}
                                activeMenu={activeView4} 
                                setActive={setView4} 
                                popupState={popupState} 
                                setPopup={setPopup} 
                                PID={PID} 
                                setPID={setPID} 
                                GID={GID} 
                                setGID={setGID} 
                                teamMenu={teamMenu}
                                setTeamMenu={setTeamMenu} 
                                scoresLevel={scoresLevel} 
                                setScoresLevel={setScoresLevel} 
                                confID={confID} 
                                confLabel={confLabel} 
                                TID={TID} 
                                teamLabel={teamLabel} 
                                
                                /> 
            </div>
            <div className="half-height border-wheat">
            <ViewSelector 
                            value={activeView8} 
                            setView={setView8}
                            currWeek={currWeek} 
                            chosenWeek={chosenWeek} 
                            confLabel={confLabel}
                            confID={confID}
                            TID={TID} />

            <HandleCommon   parent={"desktop-4"} 
                            mobile={mobile}
                            currYear={currYear}
                            currWeek={currWeek}
                            currDay={currDay}
                            chosenWeek={chosenWeek}
                            setChosenWeek={setChosenWeek}
                            updaterTime={updaterTime}
                            activeMenu={activeView8} 
                            setActive={setView8} 
                            popupState={popupState} 
                            setPopup={setPopup} 
                            PID={PID} 
                            setPID={setPID} 
                            GID={GID} 
                            setGID={setGID} 
                            teamMenu={teamMenu}
                            setTeamMenu={setTeamMenu} 
                            scoresLevel={scoresLevel} 
                            setScoresLevel={setScoresLevel} 
                            confID={confID} 
                            confLabel={confLabel} 
                            TID={TID} 
                            teamLabel={teamLabel} 
                            
                            /> 
            </div>    
        </div>

        <div className="right-column-desktop">  
            <TeamBar placement ={""}
                    layout={""} 
                    confID={confID}
                    setActive={setActive}
                    TID={TID} 
                    rev={rev}
                    mobile={mobile}
            />
        </div>
    </div>
  )
  }

}

function LogoLabel ({imgpath, label})
{
    return (
        <table><tbody>
        <tr>
        <td className='coalbk'><img className='icon-xs' src={imgpath} alt=""></img></td>
        <td className='coalbk'><h2 className=''>{label}</h2></td>
        <td className='coalbk'><img className='icon-xs' src={imgpath} alt=""></img></td>
        </tr>
        </tbody></table>                
    )
}