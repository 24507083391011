import { MenuChoices } from "../MenuChoices";

export default function WeekSelector({chosenWeek, setChosenWeek})
{

    const handleWeekClick = (week) => {
        setChosenWeek(week);
    };

    // console.log ("WS: " + chosenWeek)
    // console.log ("...SWS:  ScoresLevel = " + scoresLevel + "activeMenu=" + activeMenu)
    return (
        <div className='full-width'>
        <div style={styles.carouselContainer}>
        <div style={styles.weekNumbersContainer}>
            {Array.from({ length: 15 }, (_, i) => i ).map((week) => (
            <button
                key={week}
                onClick={() => handleWeekClick(week)}
                style={{
                ...styles.weekButton,
                backgroundColor: '#404040', color: chosenWeek === week ? 'cyan' : 'white',
                }}
            >
                {week}
            </button>
            ))}
        </div>
        {/* <div style={styles.currentWeekDisplay}>Week: {chosenWeek}</div> */}
        </div>
        </div>
    );
};

const styles = {
  carouselContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflowX: 'auto', /* Enables horizontal scrolling */
    whiteSpace: 'nowrap', /* Prevents wrapping so content stays in one line */
  },
  weekNumbersContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    gap: '9px',
    margin: '0px 0',
  },
  weekButton: {
    padding: '3px',
    fontSize: '0.8em',
    border: '1px solid #404040',
    cursor: 'pointer',
    width: '15px',
    textAlign: 'center',
    borderRadius: '0px',
  },
  currentWeekDisplay: {
    marginTop: '0px',
    fontSize: '1em',
    color: 'khaki'
  },
};
