import * as Images from '../images';
import { MenuChoices } from '../MenuChoices';
import { FaCaretRight } from "react-icons/fa6";


export default function MenuCommon({activeMenu, justSet, setAndMoveUp, confID, TID, hideLeft, toggleLeft, hideRight, toggleRight})
{  
    return (
        <>
            {hideLeft?
            <div className="btn-item graybk"><img className='btn-top' onClick={() =>  toggleLeft()} src={Images.ConfImagePaths[confID]}></img> </div>
            :
            <div className=""></div>
            }
            <div className="btn-item">
                <img className='btn-top' onClick={() =>  setAndMoveUp("FBS",MenuChoices.FBS)} src={Images.fbs}></img> 
                {activeMenu==MenuChoices.FBS?<h3 className='cyan'>FBS</h3> : <h3>FBS</h3>}
            </div>
            <div className="btn-item">
                <img className='btn-top' onClick={() => justSet(MenuChoices.RANKINGS)} src={Images.AP}></img>
                {activeMenu==MenuChoices.RANKINGS?<h3 className='cyan'>RANKINGS</h3> : <h3>RANKINGS</h3>}
            </div>
            <div className="btn-item">
                <img className='btn-top' onClick={() =>  justSet(MenuChoices.PREV_WEEK)} src={Images.Scores}></img>
                {activeMenu==MenuChoices.PREV_WEEK?<h3 className='cyan'>GAMES</h3> : <h3>GAMES</h3>}
            </div>
            <div className="btn-item">
                <img className='btn-top' onClick={() =>  justSet(MenuChoices.STATS)} src={Images.Stats}></img>
                {activeMenu==MenuChoices.STATS?<h3 className='cyan'>STATS</h3> : <h3>STATS</h3>}
            </div>
            <div className="btn-item">
                <img className='btn-top' onClick={() =>  justSet(MenuChoices.STANDINGS)} src={Images.Standings}></img>
                {activeMenu==MenuChoices.STANDINGS?<h3 className='cyan'>STANDINGS</h3> : <h3>STANDINGS</h3>}
            </div>
            <div className="btn-item">
                <img className='btn-xsq2' onClick={() =>  justSet(MenuChoices.LIVE_SCORES)} src={Images.Live}></img>
                {activeMenu==MenuChoices.LIVE_SCORES?<h3 className='cyan'>LIVE</h3> : <h3>LIVE</h3>}
            </div>
            {hideRight? (
             TID===0 ? (<button style={{fontSize: '8px', color: 'white', border: 'solid 1px cyan'}} onClick={() =>  toggleRight()}>CHANGE TEAM</button>):(
             <div className="btn-item graybk"><img className='btn-top' onClick={() =>  toggleRight()} src={Images.TeamImagePaths[TID]}></img> </div>)
            ) :
            <div className=""></div>
            }

        </>
    )
}