export const flat_path=require("./img/trending_flat.webp");
export const up_path=require("./img/trending_up.webp");
export const down_path=require("./img/trending_down.webp");
export const left=require("./img/left.webp");
export const depth=require("./img/depth.webp");
export const hint1=require("./img/hint1.png");
export const hint2=require("./img/hint2.png");
export const blank=require("./img/blank.webp");


export const 	fbs = require("./img/NCAA.webp");
export const 	sec = require("./img/sec.webp");
export const 	acc = require("./img/acc.webp");
export const 	b1g = require("./img/b1g.webp");
export const 	xii = require("./img/b12.webp");
export const 	pac = require("./img/pac.webp");
export const 	aac = require("./img/aac.webp");
export const 	mwc = require("./img/mwc.webp");
export const 	cusa = require("./img/cusa.webp");
export const 	sun = require("./img/sun.webp");
export const 	mac = require("./img/mac.webp");
export const 	indy = require("./img/IND.webp");
export const 	fcs = require("./img/fcs.webp");
export const 	g5 = require("./img/g5.webp");

export const abc = require("./img/abc.webp"); 
export const cbs = require("./img/cbs.webp"); 
export const fox = require("./img/fox.webp"); 
export const nbc = require("./img/nbc.webp"); 
export const espn = require("./img/espn.webp"); 
export const espn2 = require("./img/espn2.webp"); 
export const espnu = require("./img/espnu.webp"); 
export const espnplus = require("./img/espnplus.webp"); 
export const stadium = require("./img/stadium.webp"); 
export const none = require("./img/none.webp"); 
export const fs1  = require("./img/fs1.webp"); 
export const fs2  = require("./img/fs2.webp"); 
export const secn = require("./img/secn.webp"); 
export const accn = require("./img/accn.webp"); 
export const pacn = require("./img/pacn.webp"); 
export const cbssn = require("./img/cbssn.webp"); 
export const btn  = require("./img/btn.webp"); 
export const lhn  = require("./img/lhn.webp"); 

export const 	airforce = require("./img/airforce.webp");
export const 	akron = require("./img/akron.webp");
export const 	alabama = require("./img/alabama.webp");
export const 	appstate = require("./img/appstate.webp");
export const 	arizona = require("./img/arizona.webp");
export const 	arizonast = require("./img/arizonast.webp");
export const 	arkansas = require("./img/arkansas.webp");
export const 	arkstate = require("./img/arkstate.webp");
export const 	army = require("./img/army.webp");
export const 	auburn = require("./img/auburn.webp");
export const 	ballst = require("./img/ballst.webp");
export const 	baylor = require("./img/baylor.webp");
export const 	boise = require("./img/boise.webp");
export const 	bc = require("./img/bc.webp");
export const 	bowling = require("./img/bowling.webp");
export const 	buffalo = require("./img/buffalo.webp");
export const 	bye = require("./img/bye.webp");
export const 	byu = require("./img/byu.webp");
export const 	cal = require("./img/cal.webp");
export const 	cmich = require("./img/cmich.webp");
export const 	charlotte = require("./img/charlotte.webp");
export const 	cinci = require("./img/cinci.webp");
export const 	clemson = require("./img/clemson.webp");
export const 	coastalcar = require("./img/coastalcar.webp");
export const 	colorado = require("./img/colorado.webp");
export const 	colost = require("./img/colost.webp");
export const 	duke = require("./img/duke.webp");
export const 	ecu = require("./img/ecu.webp");
export const 	emich = require("./img/emich.webp");
export const 	fau = require("./img/fau.webp");
export const 	fiu = require("./img/fiu.webp");
export const 	florida = require("./img/florida.webp");
export const 	floridast = require("./img/floridast.webp");
export const 	fresno = require("./img/fresno.webp");
export const 	georgia = require("./img/georgia.webp");
export const 	gasouthern = require("./img/gasouthern.webp");
export const 	gastate = require("./img/gastate.webp");
export const 	georgiatech = require("./img/georgiatech.webp");
export const 	hawaii = require("./img/hawaii.webp");
export const 	houston = require("./img/houston.webp");
export const 	illinois = require("./img/illinois.webp");
export const 	indiana = require("./img/indiana.webp");
export const 	iowa = require("./img/iowa.webp");
export const 	iowast = require("./img/iowast.webp");
export const 	jmu = require("./img/jmu.webp");
export const 	kansas = require("./img/kansas.webp");
export const 	kansasst = require("./img/kansasst.webp");
export const 	kentst = require("./img/kentst.webp");
export const 	kentucky = require("./img/kentucky.webp");
export const 	kennst = require("./img/kennesawst.webp");
export const 	liberty = require("./img/liberty.webp");
export const 	ulmonroe = require("./img/ulmonroe.webp");
export const 	latech = require("./img/latech.webp");
export const 	louisville = require("./img/louisville.webp");
export const 	lsu = require("./img/lsu.webp");
export const 	marshall = require("./img/marshall.webp");
export const 	maryland = require("./img/maryland.webp");
export const 	umass = require("./img/umass.webp");
export const 	memphis = require("./img/memphis.webp");
export const 	miami = require("./img/miami.webp");
export const 	miamioh = require("./img/miamioh.webp");
export const 	michigan = require("./img/michigan.webp");
export const 	michiganst = require("./img/michiganst.webp");
export const 	mtsu = require("./img/mtsu.webp");
export const 	minnesota = require("./img/minnesota.webp");
export const 	missst = require("./img/missst.webp");
export const 	missouri = require("./img/missouri.webp");
export const 	navy = require("./img/navy.webp");
export const 	nebraska = require("./img/nebraska.webp");
export const 	nevada = require("./img/nevada.webp");
export const 	newmexico = require("./img/newmexico.webp");
export const 	newmexst = require("./img/newmexst.webp");
export const 	unc = require("./img/unc.webp");
export const 	ncstate = require("./img/ncstate.webp");
export const 	unt = require("./img/unt.webp");
export const 	noillinois = require("./img/noillinois.webp");
export const 	northwestern = require("./img/northwestern.webp");
export const 	notredame = require("./img/notredame.webp");
export const 	ohio = require("./img/ohio.webp");
export const 	ohiost = require("./img/ohiost.webp");
export const 	oklahoma = require("./img/oklahoma.webp");
export const 	oklahomast = require("./img/oklahomast.webp");
export const 	odu = require("./img/odu.webp");
export const 	olemiss = require("./img/olemiss.webp");
export const 	oregon = require("./img/oregon.webp");
export const 	oregonst = require("./img/oregonst.webp");
export const 	pennst = require("./img/pennst.webp");
export const 	pitt = require("./img/pitt.webp");
export const 	purdue = require("./img/purdue.webp");
export const 	rice = require("./img/rice.webp");
export const 	rutgers = require("./img/rutgers.webp");
export const 	sdsu = require("./img/sdsu.webp");
export const 	sanjose = require("./img/sanjose.webp");
export const 	smu = require("./img/smu.webp");
export const 	soalab = require("./img/soalab.webp");
export const 	scarolina = require("./img/scarolina.webp");
export const 	usf = require("./img/usf.webp");
export const 	usc = require("./img/usc.webp");
export const 	somiss = require("./img/somiss.webp");
export const 	stanford = require("./img/stanford.webp");
export const 	syracuse = require("./img/syracuse.webp");
export const 	tcu = require("./img/tcu.webp");
export const 	temple = require("./img/temple.webp");
export const 	tennessee = require("./img/tennessee.webp");
export const 	texas = require("./img/texas.webp");
export const 	texasam = require("./img/texasam.webp");
export const 	texstate = require("./img/texstate.webp");
export const 	texastech = require("./img/texastech.webp");
export const 	toledo = require("./img/toledo.webp");
export const 	troy = require("./img/troy.webp");
export const 	tulane = require("./img/tulane.webp");
export const 	tulsa = require("./img/tulsa.webp");
export const 	uab = require("./img/uab.webp");
export const 	ucf = require("./img/ucf.webp");
export const 	ucla = require("./img/ucla.webp");
export const 	uconn = require("./img/uconn.webp");
export const 	louisiana = require("./img/louisiana.webp");
export const 	unlv = require("./img/unlv.webp");
export const 	utah = require("./img/utah.webp");
export const 	utahst = require("./img/utahst.webp");
export const 	utep = require("./img/utep.webp");
export const 	utsa = require("./img/utsa.webp");
export const 	vandy = require("./img/vandy.webp");
export const 	virginia = require("./img/virginia.webp");
export const 	vatech = require("./img/vatech.webp");
export const 	wakeforest = require("./img/wakeforest.webp");
export const 	washington = require("./img/washington.webp");
export const 	washst = require("./img/washst.webp");
export const 	westva = require("./img/westva.webp");
export const 	wku = require("./img/wku.webp");
export const 	wmich = require("./img/wmich.webp");
export const 	wisconsin = require("./img/wisconsin.webp");
export const 	wyoming = require("./img/wyoming.webp");
export const 	jaxst = require("./img/jaxstate.webp");
export const 	samhoust = require("./img/samhouston.webp");

export const 	Blank = require("./img/blank.webp");
export const 	NCAA = require("./img/NCAA.webp");
export const 	Home = require("./img/Home.webp");
export const 	Standings = require("./img/Standings.png");
export const 	Schedule = require("./img/Schedule.webp");
export const 	AP = require("./img/ap.webp");
export const 	TV = require("./img/TV.webp");
export const 	Twitter = require("./img/Twitter.webp");
export const 	Stats = require("./img/Stats.webp");
export const 	Roster = require("./img/Roster.webp");
export const 	Future = require("./img/wyoming.webp");
export const 	Scores = require("./img/Scores.png");
export const 	Live = require("./img/live.webp");

// export const zero = fcs; // zero.webp");
// export const abchris = fcs; // abchris.webp");
// export const alabam = fcs; // alabam.webp");
// export const alabst = fcs; // alabst.webp");
// export const albany = fcs; // albany.webp");
// export const alcrornst = fcs; // alcrornst.webp");
// export const arkpb = fcs; // arkpb.webp");
// export const auspeay = fcs; // auspeay.webp");
// export const bcu = fcs; // bcu.webp");
// export const campbell = fcs; // campbell.webp");
// export const cenark = fcs; // cenark.webp");
// export const cenconn = fcs; // cenconn.webp");
// export const charso = fcs; // charso.webp");
// export const chatt = fcs; // chatt.webp");
// export const colgate = fcs; // colgate.webp");
// export const delst = fcs; // delst.webp");
// export const duq = fcs; // duq.webp");
// export const etennst = fcs; // etennst.webp");
// export const eill = fcs; // eill.webp");
// export const ekent = fcs; // ekent.webp");
// export const ewash = fcs; // ewash.webp");
// export const elon = fcs; // elon.webp");
// export const famu = fcs; // famu.webp");
// export const forham = fcs; // forham.webp");
// export const furman = fcs; // furman.webp");
// export const grambling = fcs; // grambling.webp");
// export const holycross = fcs; // holycross.webp");
// export const houbap = fcs; // houbap.webp");
// export const howard = fcs; // howard.webp");
// export const idaho = fcs; // idaho.webp");
// export const idahost = fcs; // idahost.webp");
// export const illst = fcs; // illst.webp");
// export const incword = fcs; // incword.webp");
// export const indst = fcs; // indst.webp");
// export const lamar = fcs; // lamar.webp");
// export const lehigh = fcs; // lehigh.webp");
// export const maine = fcs; // maine.webp");
// export const mcneese = fcs; // mcneese.webp");
// export const mercer = fcs; // mercer.webp");
// export const mizzst = fcs; // mizzst.webp");
// export const monmouth = fcs; // monmouth.webp");
// export const morganst = fcs; // morganst.webp");
// export const murrayst = fcs; // murrayst.webp");
// export const newhampshire = fcs; // newhampshire.webp");
// export const nicholls = fcs; // nicholls.webp");
// export const ncat = fcs; // ncat.webp");
// export const nccen = fcs; // nccen.webp");
// export const ndak = fcs; // ndak.webp");
// export const noraz = fcs; // noraz.webp");
// export const noriowa = fcs; // noriowa.webp");
// export const nwst = fcs; // nwst.webp");
// export const portland = fcs; // portland.webp");
// export const prairieview = fcs; // prairieview.webp");
// export const rhode = fcs; // rhode.webp");
// export const richmond = fcs; // richmond.webp");
// export const sacst = fcs; // sacst.webp");
// export const samford = fcs; // samford.webp");
// export const savst = fcs; // savst.webp");
// export const scarst = fcs; // scarst.webp");
// export const sdak = fcs; // sdak.webp");
// export const sdakst = fcs; // sdakst.webp");
// export const semizzst = fcs; // semizzst.webp");
// export const selast = fcs; // selast.webp");
// export const southern = fcs; // southern.webp");
// export const sill = fcs; // sill.webp");
// export const sutah = fcs; // sutah.webp");
// export const sfa = fcs; // sfa.webp");
// export const stonybrook = fcs; // stonybrook.webp");
// export const tennst = fcs; // tennst.webp");
// export const tenntech = fcs; // tenntech.webp");
// export const texsou = fcs; // texsou.webp");
// export const citadel = fcs; // citadel.webp");
// export const towson = fcs; // towson.webp");
// export const ucdavis = fcs; // ucdavis.webp");
// export const utmartin = fcs; // utmartin.webp");
// export const villanova = fcs; // villanova.webp");
// export const vmi = fcs; // vmi.webp");
// export const wagner = fcs; // wagner.webp");
// export const weberst = fcs; // weberst.webp");
// export const wcar = fcs; // wcar.webp");
// export const will = fcs; // will.webp");
// export const willmary = fcs; // willmary.webp");
// export const wofford = fcs; // wofford.webp");
// export const youngstown = fcs; // youngstown.webp");
// export const norfolk = fcs; // norfolk.webp");
// export const bucknell = fcs; // bucknell.webp");
// export const montanast = fcs; // montanast.webp");
// export const gardnerwebb = fcs; // gardnerwebb.webp");
// export const northerncol = fcs; // northerncol.webp");
// export const calpoly = fcs; // calpoly.webp");
// export const hampton = fcs; // hampton.webp");
// export const north_alabama = fcs; // north_alabama.webp");
// export const lafayette = fcs; // lafayette.webp");
// export const liu = fcs; // liu.webp");
// export const FCS_spare_5 = fcs; // FCS_spare_5.webp");
// export const stfrancispa = fcs; // stfrancispa.webp");
// export const montana = fcs; // montana.webp");
// export const Bryant = fcs; // Bryant.webp");
// export const FCS_spare_9 = fcs; // FCS_spare_9.webp");
// export const FCS_spare_10 = fcs; // FCS_spare_10.webp");
// export const FCS_spare_11 = fcs; // FCS_spare_11.webp");
// export const FCS_spare_12 = fcs; // FCS_spare_12.webp");
// export const FCS_spare_13 = fcs; // FCS_spare_13.webp");
// export const FCS_spare_14 = fcs; // FCS_spare_14.webp");
// export const FCS_spare_15 = fcs; // FCS_spare_15.webp");
// export const FCS_spare_16 = fcs; // FCS_spare_16.webp");
// export const FCS_spare_17 = fcs; // FCS_spare_17.webp");
// export const FCS_spare_18 = fcs; // FCS_spare_18.webp");
// export const FCS_spare_19 = fcs; // FCS_spare_19.webp");
// export const FCS_spare_20 = fcs; // FCS_spare_20.webp");
// export const FCS_spare_21 = fcs; // FCS_spare_21.webp");
// export const FCS_spare_22 = fcs; // FCS_spare_22.webp");
// export const FCS_spare_23 = fcs; // FCS_spare_23.webp");
// export const FCS_spare_24 = fcs; // FCS_spare_24.webp");
// export const FCS_spare_25 = fcs; // FCS_spare_25.webp");
// export const FCS_spare_26 = fcs; // FCS_spare_26.webp");
// export const FCS_spare_27 = fcs; // FCS_spare_27.webp");
// export const FCS_spare_28 = fcs; // FCS_spare_28.webp");
export const robert_morris = fcs; // robert_morris.webp");
// export const FCS_spare_30 = fcs; // FCS_spare_30.webp");
export const Last = fcs; 
// export const networkNames=["ABC","CBS","FOX","NBC","ESPN","ESPN2","ESPNU","FS1","FS2","SECN","ACCN","PACN","CBSSN","BTN","LHN","ESPN+","ESPN3","Stadium","None"]

export const networkImagePaths=[abc, cbs, fox, nbc, espn, espn2, espnu, fs1, fs2, secn, accn,  pacn,  cbssn,  btn,  lhn, espnplus,espnplus,espnplus,stadium,none]; 
// const networkNames=[         "ABC","CBS","FOX","NBC","ESPN","ESPN2","ESPNU","FS1","FS2","SECN","ACC NETWOR","PACN","CBSSN","BIG10","LHN","ESPN+","ESPN3","Peacock","Stadium","None"]


export const ConfImagePaths = [0,sec,b1g,acc,pac,xii,aac,cusa,mwc,sun,mac,indy,fcs];

export const TeamImagePaths = [
    fcs,
    fcs,
    airforce,
    akron,
    alabama,
    fcs,
    fcs,
    fcs,
    fcs,
    appstate,
    arizona,
    arizonast,
    arkansas,
    fcs,
    arkstate,
    army,
    auburn,
    fcs,
    ballst,
    baylor,
    fcs,
    boise,
    bc,
    bowling,
    buffalo,
    byu,
    cal,
    fcs,
    fcs,
    fcs,
    cmich,
    fcs,
    charlotte,
    fcs,
    cinci,
    clemson,
    coastalcar,
    fcs,
    colorado,
    colost,
    fcs,
    duke,
    fcs,
    ecu,
    fcs,
    fcs,
    fcs,
    emich,
    fcs,
    fcs,
    fcs,
    fau,
    fiu,
    florida,
    floridast,
    fcs,
    fresno,
    fcs,
    georgia,
    gasouthern,
    gastate,
    georgiatech,
    fcs,
    hawaii,
    fcs,
    houston,
    fcs,
    fcs,
    fcs,
    fcs,
    illinois,
    fcs,
    fcs,
    indiana,
    fcs,
    iowa,
    iowast,
    jaxst,
    jmu,
    kansas,
    kansasst,
    kennst,
    kentst,
    kentucky,
    fcs,
    fcs,
    fcs,
    liberty,
    ulmonroe,
    latech,
    louisville,
    lsu,
    fcs,
    marshall,
    maryland,
    umass,
    fcs,
    memphis,
    fcs,
    miami,
    miamioh,
    michigan,
    michiganst,
    mtsu,
    minnesota,
    missst,
    missouri,
    fcs,
    fcs,
    fcs,
    fcs,
    navy,
    nebraska,
    nevada,
    fcs,
    newmexico,
    newmexst,
    fcs,
    unc,
    fcs,
    fcs,
    ncstate,
    fcs,
    unt,
    fcs,
    noillinois,
    fcs,
    northwestern,
    fcs,
    notredame,
    ohio,
    ohiost,
    oklahoma,
    oklahomast,
    odu,
    olemiss,
    oregon,
    oregonst,
    pennst,
    pitt,
    fcs,
    fcs,
    purdue,
    fcs,
    rice,
    fcs,
    rutgers,
    fcs,
    fcs,
    sdsu,
    sanjose,
    fcs,
    smu,
    soalab,
    scarolina,
    fcs,
    fcs,
    fcs,
    usf,
    fcs,
    fcs,
    fcs,
    usc,
    fcs,
    somiss,
    fcs,
    stanford,
    fcs,
    fcs,
    syracuse,
    tcu,
    temple,
    tennessee,
    fcs,
    fcs,
    texas,
    texasam,
    fcs,
    texstate,
    texastech,
    fcs,
    toledo,
    fcs,
    troy,
    tulane,
    tulsa,
    uab,
    fcs,
    ucf,
    ucla,
    uconn,
    louisiana,
    unlv,
    fcs,
    utah,
    utahst,
    utep,
    utsa,
    vandy,
    fcs,
    virginia,
    vatech,
    fcs,
    fcs,
    wakeforest,
    washington,
    washst,
    fcs,
    westva,
    fcs,
    fcs,
    wku,
    wmich,
    fcs,
    wisconsin,
    wyoming,
    fcs,
    samhoust,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    fcs,
    bye,
    fcs,
    fcs,
    fcs,
    fcs, // FCS_spare_9,
    jaxst,
    fcs, // FCS_spare_11,
    fcs, // FCS_spare_12,
    fcs, // FCS_spare_13,
    fcs, // FCS_spare_14,
    fcs, // FCS_spare_15,
    fcs, // FCS_spare_16,
    fcs, // FCS_spare_17,
    fcs, // FCS_spare_18,
    fcs, // FCS_spare_19,
    fcs, // FCS_spare_20,
    fcs, // FCS_spare_21,
    fcs, // FCS_spare_22,
    fcs, // FCS_spare_23,
    fcs, // FCS_spare_24,
    fcs, // FCS_spare_25,
    fcs, // FCS_spare_26,
    fcs, // FCS_spare_27,
    fcs, // FCS_spare_28,
    fcs,
    fcs, // FCS_spare_30,
    Last]; 

    export const ConfNames = ["xxx","SEC","B1G","ACC","yyy","XII","AAC","CUSA","MWC","SUN","MAC","Independents"]
    export const ConfMembers=[[0],[],[],[],[],[],[],[],[],[],[],[],[]];

    export const teamColors = [

      {"TID":"1","Name":"Abilene Christian","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"2","Name":"Air Force","ShortName":"AF","fgColor":"white","bgColor":"blue"},
      {"TID":"3","Name":"Akron","ShortName":"AKR","fgColor":"white","bgColor":"blue"},
      {"TID":"4","Name":"Alabama","ShortName":"ALA","fgColor":"white","bgColor":"#9E1B32"},
      {"TID":"5","Name":"Alabama A&M","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"6","Name":"Alabama State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"7","Name":"Albany State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"8","Name":"Alcorn State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"9","Name":"App State","ShortName":"APP","fgColor":"gold","bgColor":"black"},
      {"TID":"10","Name":"Arizona","ShortName":"AZ","fgColor":"red","bgColor":"navy"},
      {"TID":"11","Name":"Arizona State","ShortName":"ASU","fgColor":"gold","bgColor":"maroon"},
      {"TID":"12","Name":"Arkansas","ShortName":"ARK","fgColor":"white","bgColor":"#9D2235"},
      {"TID":"13","Name":"Arkansas-Pine Bluff","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"14","Name":"Arkansas State","ShortName":"ARST","fgColor":"red","bgColor":"black"},
      {"TID":"15","Name":"Army","ShortName":"ARMY","fgColor":"#CEB888","bgColor":"black"},
      {"TID":"16","Name":"Auburn","ShortName":"AUB","fgColor":"#E87722","bgColor":"#0C2340"},
      {"TID":"17","Name":"Austin Peay","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"18","Name":"Ball State","ShortName":"BALL","fgColor":"red","bgColor":"black"},
      {"TID":"19","Name":"Baylor","ShortName":"BAY","fgColor":"gold","bgColor":"green"},
      {"TID":"20","Name":"Bethune-Cookman","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"21","Name":"Boise State","ShortName":"BOI","fgColor":"orange","bgColor":"blue"},
      {"TID":"22","Name":"Boston College","ShortName":"BC","fgColor":"gold","bgColor":"maroon"},
      {"TID":"23","Name":"Bowling Green","ShortName":"BG","fgColor":"orange","bgColor":"black"},
      {"TID":"24","Name":"Buffalo","ShortName":"BUF","fgColor":"white","bgColor":"blue"},
      {"TID":"25","Name":"BYU","ShortName":"BYU","fgColor":"white","bgColor":"navy"},
      {"TID":"26","Name":"California","ShortName":"CAL","fgColor":"gold","bgColor":"navy"},
      {"TID":"27","Name":"Campbell","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"28","Name":"Central Arkansas","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"29","Name":"Central Connecticut","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"30","Name":"Central Michigan","ShortName":"CMIC","fgColor":"gold","bgColor":"brown"},
      {"TID":"31","Name":"Charleston Southern","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"32","Name":"Charlotte","ShortName":"CHAR","fgColor":"gold","bgColor":"green"},
      {"TID":"33","Name":"Chattanooga","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"34","Name":"Cincinnati","ShortName":"CIN","fgColor":"red","bgColor":"black"},
      {"TID":"35","Name":"Clemson","ShortName":"CLEM","fgColor":"purple","bgColor":"orange"},
      {"TID":"36","Name":"Coastal Carolina","ShortName":"CC","fgColor":"teal","bgColor":"black"},
      {"TID":"37","Name":"Colgate","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"38","Name":"Colorado","ShortName":"COL","fgColor":"#CEB888","bgColor":"black"},
      {"TID":"39","Name":"Colorado State","ShortName":"CSU","fgColor":"white","bgColor":"green"},
      {"TID":"40","Name":"Delaware State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"41","Name":"Duke","ShortName":"DUKE","fgColor":"white","bgColor":"blue"},
      {"TID":"42","Name":"Duquesne","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"43","Name":"East Carolina","ShortName":"ECU","fgColor":"gold","bgColor":"purple"},
      {"TID":"44","Name":"East Tennessee State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"45","Name":"Eastern Illinois","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"46","Name":"Eastern Kentucky","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"47","Name":"Eastern Michigan","ShortName":"EMIC","fgColor":"white","bgColor":"green"},
      {"TID":"48","Name":"Eastern Washington","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"49","Name":"Elon","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"50","Name":"Florida A&M","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"51","Name":"Florida Atlantic","ShortName":"FAU","fgColor":"red","bgColor":"blue"},
      {"TID":"52","Name":"Florida International","ShortName":"FIU","fgColor":"navy","bgColor":"#CEB888"},
      {"TID":"53","Name":"Florida","ShortName":"FLA","fgColor":"#FA4616","bgColor":"#003087"},
      {"TID":"54","Name":"Florida State","ShortName":"FSU","fgColor":"#CEB888","bgColor":"maroon"},
      {"TID":"55","Name":"Fordham","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"56","Name":"Fresno State","ShortName":"FSU","fgColor":"white","bgColor":"red"},
      {"TID":"57","Name":"Furman","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"58","Name":"Georgia","ShortName":"UGA","fgColor":"#BA0C2F","bgColor":"#2c2a29"},
      {"TID":"59","Name":"Georgia Southern","ShortName":"GS","fgColor":"white","bgColor":"navy"},
      {"TID":"60","Name":"Georgia State","ShortName":"GST","fgColor":"white","bgColor":"blue"},
      {"TID":"61","Name":"Georgia Tech","ShortName":"GT","fgColor":"#CEB888","bgColor":"black"},
      {"TID":"62","Name":"Grambling","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"63","Name":"Hawai'i","ShortName":"HAW","fgColor":"white","bgColor":"green"},
      {"TID":"64","Name":"Holy Cross","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"65","Name":"Houston","ShortName":"HOU","fgColor":"white","bgColor":"red"},
      {"TID":"66","Name":"Houston Baptist","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"67","Name":"Howard","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"68","Name":"Idaho","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"69","Name":"Idaho State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"70","Name":"Illinois","ShortName":"ILL","fgColor":"orange","bgColor":"mavy"},
      {"TID":"71","Name":"Illinois State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"72","Name":"Incarnate Word","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"73","Name":"Indiana","ShortName":"IND","fgColor":"white","bgColor":"red"},
      {"TID":"74","Name":"Indiana State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"75","Name":"Iowa","ShortName":"IOWA","fgColor":"gold","bgColor":"black"},
      {"TID":"76","Name":"Iowa State","ShortName":"ISU","fgColor":"gold","bgColor":"red"},
      {"TID":"77","Name":"Jackson State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"78","Name":"James Madison","ShortName":"JMU","fgColor":"gold","bgColor":"navy"},
      {"TID":"79","Name":"Kansas","ShortName":"KAN","fgColor":"red","bgColor":"blue"},
      {"TID":"80","Name":"Kansas State","ShortName":"KSU","fgColor":"white","bgColor":"purple"},
      {"TID":"81","Name":"Kennesaw State","ShortName":"KSU","fgColor":"gold","bgColor":"navy"},
      {"TID":"82","Name":"Kent State","ShortName":"KNST","fgColor":"gold","bgColor":"blue"},
      {"TID":"83","Name":"Kentucky","ShortName":"KEN","fgColor":"white","bgColor":"blue"},
      {"TID":"84","Name":"Delaware","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"85","Name":"Lamar","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"86","Name":"Lehigh","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"87","Name":"Liberty","ShortName":"LIB","fgColor":"red","bgColor":"blue"},
      {"TID":"88","Name":"UL Monroe","ShortName":"ULM","fgColor":"gold","bgColor":"red"},
      {"TID":"89","Name":"Louisiana Tech","ShortName":"LT","fgColor":"red","bgColor":"blue"},
      {"TID":"90","Name":"Louisville","ShortName":"LOU","fgColor":"white","bgColor":"red"},
      {"TID":"91","Name":"LSU","ShortName":"LSU","fgColor":"#FDD023","bgColor":"#461D7C"},
      {"TID":"92","Name":"Maine","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"93","Name":"Marshall","ShortName":"MAR","fgColor":"white","bgColor":"green"},
      {"TID":"94","Name":"Maryland","ShortName":"MD","fgColor":"black","bgColor":"red"},
      {"TID":"95","Name":"Massachusetts","ShortName":"MASS","fgColor":"white","bgColor":"maroon"},
      {"TID":"96","Name":"McNeese","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"97","Name":"Memphis","ShortName":"MEM","fgColor":"white","bgColor":"blue"},
      {"TID":"98","Name":"Mercer","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"99","Name":"Miami","ShortName":"MIA","fgColor":"orange","bgColor":"green"},
      {"TID":"100","Name":"Miami (OH)","ShortName":"MIOH","fgColor":"white","bgColor":"red"},
      {"TID":"101","Name":"Michigan","ShortName":"MICH","fgColor":"gold","bgColor":"navy"},
      {"TID":"102","Name":"Michigan State","ShortName":"MSU","fgColor":"white","bgColor":"green"},
      {"TID":"103","Name":"Middle Tennessee","ShortName":"MTSU","fgColor":"white","bgColor":"blue"},
      {"TID":"104","Name":"Minnesota","ShortName":"MIN","fgColor":"#FBB93C","bgColor":"#862334"},
      {"TID":"105","Name":"Mississippi State","ShortName":"MSU","fgColor":"white","bgColor":"maroon"},
      {"TID":"106","Name":"Missouri","ShortName":"MIZ","fgColor":"gold","bgColor":"black"},
      {"TID":"107","Name":"Missouri State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"108","Name":"Monmouth","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"109","Name":"Morgan State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"110","Name":"Murray State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"111","Name":"Navy","ShortName":"NAVY","fgColor":"#b4a87e","bgColor":" #000048"},
      {"TID":"112","Name":"Nebraska","ShortName":"NEB","fgColor":"white","bgColor":"red"},
      {"TID":"113","Name":"Nevada","ShortName":"NEV","fgColor":"white","bgColor":"navy"},
      {"TID":"114","Name":"New Hampshire","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"115","Name":"New Mexico","ShortName":"NM","fgColor":"red","bgColor":"black"},
      {"TID":"116","Name":"New Mexico State","ShortName":"NMS","fgColor":"red","bgColor":"black"},
      {"TID":"117","Name":"Nicholls","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"118","Name":"North Carolina","ShortName":"NC","fgColor":"#7BAFD4","bgColor":"#13294B"},
      {"TID":"119","Name":"North Carolina AT","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"120","Name":"North Carolina Central","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"121","Name":"NC State","ShortName":"NCST","fgColor":"white","bgColor":"red"},
      {"TID":"122","Name":"North Dakota","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"123","Name":"North Texas","ShortName":"UNT","fgColor":"white","bgColor":"green"},
      {"TID":"124","Name":"Northern Arizona","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"125","Name":"Northern Illinois","ShortName":"UNI","fgColor":"red","bgColor":"black"},
      {"TID":"126","Name":"Northern Iowa","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"127","Name":"Northwestern","ShortName":"NOR","fgColor":"white","bgColor":"#4E2A84"},
      {"TID":"128","Name":"Northwestern State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"129","Name":"Notre Dame","ShortName":"ND","fgColor":"#CEB888","bgColor":"navy"},
      {"TID":"130","Name":"Ohio","ShortName":"OHIO","fgColor":"white","bgColor":"green"},
      {"TID":"131","Name":"Ohio State","ShortName":"OSU","fgColor":"silver","bgColor":"red"},
      {"TID":"132","Name":"Oklahoma","ShortName":"OKL","fgColor":"white","bgColor":"red"},
      {"TID":"133","Name":"Oklahoma State","ShortName":"OKST","fgColor":"#FF7300","bgColor":"black"},
      {"TID":"134","Name":"Old Dominion","ShortName":"ODU","fgColor":"silver","bgColor":"blue"},
      {"TID":"135","Name":"Ole Miss","ShortName":"MISS","fgColor":"red","bgColor":"navy"},
      {"TID":"136","Name":"Oregon","ShortName":"ORE","fgColor":"green","bgColor":"black"},
      {"TID":"137","Name":"Oregon State","ShortName":"ORST","fgColor":"orange","bgColor":"black"},
      {"TID":"138","Name":"Penn State","ShortName":"PSU","fgColor":"white","bgColor":"navy"},
      {"TID":"139","Name":"Pittsburgh","ShortName":"PITT","fgColor":"gold","bgColor":"navy"},
      {"TID":"140","Name":"Portland State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"141","Name":"Prairie View","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"142","Name":"Purdue","ShortName":"PUR","fgColor":"#CEB888","bgColor":"black"},
      {"TID":"143","Name":"Rhode Island","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"144","Name":"Rice","ShortName":"RICE","fgColor":"#C1C6C8","bgColor":"#00205B"},
      {"TID":"145","Name":"Richmond","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"146","Name":"Rutgers","ShortName":"RUT","fgColor":"white","bgColor":"red"},
      {"TID":"147","Name":"Sacramento State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"148","Name":"Samford","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"149","Name":"San Diego State","ShortName":"SDSU","fgColor":"red","bgColor":"black"},
      {"TID":"150","Name":"San Jose State","ShortName":"SJSU","fgColor":"gold","bgColor":"blue"},
      {"TID":"151","Name":"Savannah State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"152","Name":"SMU","ShortName":"SMU","fgColor":"red","bgColor":"blue"},
      {"TID":"153","Name":"South Alabama","ShortName":"SALA","fgColor":"red","bgColor":"blue"},
      {"TID":"154","Name":"South Carolina","ShortName":"SC","fgColor":"#73000A","bgColor":"black"},
      {"TID":"155","Name":"South Carolina State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"156","Name":"South Dakota","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"157","Name":"South Dakota State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"158","Name":"South Florida","ShortName":"USF","fgColor":"#CEB888","bgColor":"green"},
      {"TID":"159","Name":"Southeast Missouri State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"160","Name":"SE Louisiana","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"161","Name":"Southern","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"162","Name":"USC","ShortName":"USC","fgColor":"#FFC72C","bgColor":"#990000"},
      {"TID":"163","Name":"Southern Illinois","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"164","Name":"Southern Miss","ShortName":"SMIS","fgColor":"gold","bgColor":"black"},
      {"TID":"165","Name":"Southern Utah","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"166","Name":"Stanford","ShortName":"STAN","fgColor":"white","bgColor":"red"},
      {"TID":"167","Name":"Stephen F Austin","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"168","Name":"Stony Brook","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"169","Name":"Syracuse","ShortName":"SYR","fgColor":" #D44500","bgColor":"#0c233f"},
      {"TID":"170","Name":"TCU","ShortName":"TCU","fgColor":"white","bgColor":"#4D1979"},
      {"TID":"171","Name":"Temple","ShortName":"TEM","fgColor":"white","bgColor":"#981e32"},
      {"TID":"172","Name":"Tennessee","ShortName":"TEN","fgColor":"white","bgColor":"orange"},
      {"TID":"173","Name":"Tennessee State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"174","Name":"Tennessee Tech","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"175","Name":"Texas","ShortName":"TEX","fgColor":"white","bgColor":"#BF5700"},
      {"TID":"176","Name":"Texas A&M","ShortName":"ATM","fgColor":"white","bgColor":"maroon"},
      {"TID":"177","Name":"Texas Southern","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"178","Name":"Texas State","ShortName":"TXST","fgColor":"gold","bgColor":"brown"},
      {"TID":"179","Name":"Texas Tech","ShortName":"TT","fgColor":"red","bgColor":"black"},
      {"TID":"180","Name":"The Citadel","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"181","Name":"Toledo","ShortName":"TOL","fgColor":"gold","bgColor":"navy"},
      {"TID":"182","Name":"Towson","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"183","Name":"Troy","ShortName":"TROY","fgColor":"silver","bgColor":"red"},
      {"TID":"184","Name":"Tulane","ShortName":"TUL","fgColor":"lightblue","bgColor":"green"},
      {"TID":"185","Name":"Tulsa","ShortName":"TLSA","fgColor":"blue","bgColor":"red"},
      {"TID":"186","Name":"UAB","ShortName":"UAB","fgColor":"orange","bgColor":"green"},
      {"TID":"187","Name":"UC Davis","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"188","Name":"UCF","ShortName":"UCF","fgColor":"khaki","bgColor":"black"},
      {"TID":"189","Name":"UCLA","ShortName":"UCLA","fgColor":"#F2A900","bgColor":"#2D68C4"},
      {"TID":"190","Name":"UConn","ShortName":"CONN","fgColor":"white","bgColor":"navy"},
      {"TID":"191","Name":"Louisiana","ShortName":"LSA","fgColor":"white","bgColor":"red"},
      {"TID":"192","Name":"UNLV","ShortName":"UNLV","fgColor":"white","bgColor":"red"},
      {"TID":"193","Name":"UT Martin","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"194","Name":"Utah","ShortName":"UTAH","fgColor":"white","bgColor":"red"},
      {"TID":"195","Name":"Utah State","ShortName":"USU","fgColor":"white","bgColor":"blue"},
      {"TID":"196","Name":"UTEP","ShortName":"UTEP","fgColor":"orange","bgColor":"navy"},
      {"TID":"197","Name":"UTSA","ShortName":"UTSA","fgColor":"orange","bgColor":"navy"},
      {"TID":"198","Name":"Vanderbilt","ShortName":"VAN","fgColor":"#CEB888","bgColor":"black"},
      {"TID":"199","Name":"Villanova","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"200","Name":"Virginia","ShortName":"UVA","fgColor":"white","bgColor":"#F84C1E"},
      {"TID":"201","Name":"Virginia Tech","ShortName":"VT","fgColor":"maroon","bgColor":"orange"},
      {"TID":"202","Name":"VMI","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"203","Name":"Wagner","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"204","Name":"Wake Forest","ShortName":"WAKE","fgColor":"#CEB888","bgColor":"black"},
      {"TID":"205","Name":"Washington","ShortName":"WAS","fgColor":"#E8D3A2","bgColor":"#330066"},
      {"TID":"206","Name":"Washington State","ShortName":"WAST","fgColor":"red","bgColor":"silver"},
      {"TID":"207","Name":"Weber State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"208","Name":"West Virginia","ShortName":"WVU","fgColor":"gold","bgColor":"navy"},
      {"TID":"209","Name":"Western Carolina","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"210","Name":"Western Illinois","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"211","Name":"Western Kentucky","ShortName":"WKU","fgColor":"white","bgColor":"red"},
      {"TID":"212","Name":"Western Michigan","ShortName":"WMIC","fgColor":"brown","bgColor":"khaki"},
      {"TID":"213","Name":"William & Mary","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"214","Name":"Wisconsin","ShortName":"WIS","fgColor":"white","bgColor":"red"},
      {"TID":"215","Name":"Wyoming","ShortName":"WYO","fgColor":"#FFC425","bgColor":"#492F24"},
      {"TID":"216","Name":"Wofford","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"217","Name":"Sam Houston","ShortName":"SH","fgColor":"gold","bgColor":"navy"},
      {"TID":"218","Name":"Youngstown State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"219","Name":"Norfolk State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"220","Name":"Bucknell","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"221","Name":"Montana State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"222","Name":"Gardner-Webb","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"223","Name":"Northern Colorado","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"224","Name":"Cal Poly","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"225","Name":"Hampton","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"226","Name":"North Alabama","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"227","Name":"Lafayette","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"228","Name":"LIU","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"229","Name":"TBD","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"230","Name":"St Francis (PA)","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"231","Name":"Montana","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"232","Name":"Bryant","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"233","Name":"Tarleton State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"234","Name":"Jacksonville State","ShortName":"JAX","fgColor":"gold","bgColor":"navy"},
      {"TID":"235","Name":"FCS_spare_11","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"236","Name":"FCS_spare_12","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"237","Name":"FCS_spare_13","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"238","Name":"FCS_spare_14","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"239","Name":"FCS_spare_15","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"240","Name":"FCS_spare_16","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"241","Name":"FCS_spare_17","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"242","Name":"FCS_spare_18","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"243","Name":"FCS_spare_19","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"244","Name":"FCS_spare_20","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"245","Name":"FCS_spare_21","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"246","Name":"FCS_spare_22","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"247","Name":"Lindenwood","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"248","Name":"North Dakota State","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"249","Name":"Sacred Heart","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"250","Name":"Merrimack","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"251","Name":"Utah Tech","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"252","Name":"Texas A&M Comm","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"253","Name":"Robert Morris","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"254","Name":"Houston Christian","ShortName":"FCS","fgColor":"gold","bgColor":"navy"},
      {"TID":"255","Name":"Unknown","ShortName":"FCS","fgColor":"gold","bgColor":"navy"}
            
      ];

    export function LoadConfMember (confid, tid)
    {        
       ConfMembers[confid].push(TeamImagePaths[tid]);
    }

