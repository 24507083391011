import { useState, useEffect } from "react"
import * as GlobalData from '../data';

export default function GameSelector ({currWeek, GID, setGID})
{
    const [gameList, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
            const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_SCOREBOARD&Week="+currWeek;
            console.log (url)
            const response = await fetch(url, {
                method: 'GET', // or 'POST', 'PUT', etc.
                headers: {
                    'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const gms = await response.json();
            {
                var list=[];
                for (var i=0; i<gms.length; i++)
                    if (1)
                        list.push ({ GID: gms[i].GID, AwayTeam: gms[i].AwayTeam, AwayShort: gms[i].AwayShort, HomeTeam: gms[i].HomeTeam, HomeShort: gms[i].HomeShort})
                console.log ("This weeks games = "+list.length)
                setData( list);
            }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      fetchData();
  
    }, [currWeek]);  

    const handleGameSelect = (event) => {
        event.preventDefault();
        console.log ("GID="+event.target.value)
        setGID(parseInt(event.target.value))
    }

    if (loading) 
        return (<h2>Loading...</h2>);
    else if (error) 
        return (<h2>Error: {error}</h2>);
    else
        return (
        <select className="khaki coalbk large-font bold" value={GID} onChange={handleGameSelect} >
            <option value={0}>---</option>
            {gameList.map((data,id)=>(
                <option key={id} value={data.GID}>{data.AwayShort} at {data.HomeShort}</option>
            ))}

        </select> 
    )
}