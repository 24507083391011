import ScoringByQuarter from "./ScoringByQuarter";
import DriveSelector from "./DriveSelector";
import React, { useState, useEffect } from "react"
import * as GlobalData from '../data';
import * as Images from '../images';
// import { LoadPlays } from '../data';

export default function DriveView({placement, mobile, setPopup, GID})
{
    const [drives, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(true);
    const [driveSelected, setDriveSelected] = useState("BOTH");

    const closePopup = () => {
        setIsPopupOpen(false);
        setPopup(0)
    }
    
    useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
            const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_DRIVES&GID="+GID;
            console.log (url)
            const response = await fetch(url, {
              method: 'GET', // or 'POST', 'PUT', etc.
              headers: {
                  'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                  'Content-Type': 'application/json'
              }
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setData(result);
            
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      if (GID > 0) fetchData();
  
  }, [GID]);  
  
    if (loading) 
      return (<h2>Loading...</h2>);
    else if (error) 
      return (<h2>Error: {error}</h2>);
    else if (drives.length == 0) 
        return (<h2>NO DRIVES LOADED</h2>);
    else
    {
        var Drives = drives.map((data,id)=>{
            return <DriveLine key={id} d={data} driveSelected={driveSelected}/>
        })

        return (
          <div className={mobile?"popup-noscroll":'popup-noscroll-desktop'}>
            <div className='flex-container'>
                    <div className='popup-item full-width  fixed-height'>
                        <br></br>
                        <button className="close-icon" onClick={closePopup}>&times;</button>
                        <ScoringByQuarter drives={drives}/>
                    </div>
                    <h2 className='khaki'>DRIVES</h2>
                        <DriveSelector driveSelected={driveSelected} setDriveSelected={setDriveSelected} awayTeam={drives[0].awayTeam} homeTeam={drives[0].homeTeam} />
                    <div className="popup-item-scroll full-width flexible-height">
                        <>
                        <table>
                        <colgroup>
                            <col style={{ width: '10%', textAlign: 'center'}} />
                            <col style={{ width: '15%', textAlign: 'center'}} />
                            <col style={{ width: '10%', textAlign: 'center' }} />
                            <col style={{ width: '10%', textAlign: 'center' }} />
                            <col style={{ width: '10%', textAlign: 'center' }} />
                            <col style={{ width: '15%', textAlign: 'center' }} />
                            <col style={{ width: '15%', textAlign: 'center' }} />
                            <col style={{ width: '15%', textAlign: 'center' }} />
                        </colgroup>
                            <tbody>
                                <tr style={{fontSize: '14px'}}><th>Qtr</th><th>Time</th><th>Team</th><th>Plays</th><th>Yards</th><th>Duration</th><th>Result</th><th>Score</th></tr>
                            {Drives}
                            </tbody>
                        </table>
                        </>
                    </div>
                </div>
            </div>    
        )
    }
}


function DriveLine ({d, driveSelected})
{
    let offPath = Images.TeamImagePaths[d.offTID];
    let turnover = (d.drive_result === "INT" || d.drive_result === "INT RETURN TD" || d.drive_result === "FUMBLE"  || d.drive_result === "FUMBLE RETURN TD"  || d.drive_result === "MISSED FG");
    let final = d.drive_result === "END OF GAME";
    let awayscore = (d.offense == d.awayTeam) ? d.end_offense_score : d.end_defense_score;
    let homescore = (d.offense == d.homeTeam) ? d.end_offense_score : d.end_defense_score;

    return (
        <>
        {driveSelected == d.offense || driveSelected == "BOTH" ?
            <tr style={{textAlign:'center', fontSize: '12px'}}>  
            <td>{d.start_period}</td> 
            <td>{d.start_time}</td> 
            <td><img className='icon-tiny' src={offPath} alt={""}></img></td>
            <td>{d.plays}</td>
            <td>{d.yards}</td>
            <td>{d.elapsed_time}</td> 
            {d.scoring && (!turnover)? <td style={{textAlign: 'center', color: 'green'}}>{d.drive_result}</td> :<></> }
            {turnover ?  <td style={{textAlign: 'center', color: 'red'}}>{d.drive_result}</td> :<></> }
            {d.scoring || turnover?<></>:<td style={{textAlign: 'center', color: 'white'}}>{d.drive_result}</td>}  
            {d.scoring || final ?<td>{awayscore} - {homescore}</td>:<td></td>}      
        </tr>        
        :
        <tr>  
        <td style={{textAlign: 'center', color: 'gray'}}>.</td> 
        <td></td> 
        <td></td>
        <td></td>
        <td></td>
        <td></td> 
        <td></td> 
        <td></td> 
        </tr>        
    }
        </>
    )
}


            {/* <td>{d.defense}</td> ?<td>{d.drive_result}</td>:<></>
            {/* <td>{d.defense}</td> 
            <td>{d.scoring}</td> 
            <td>{d.start_yardline}</td> 
            <td>{d.start_yards_to_goal}</td>
            <td>{d.end_period}</td> 
            <td>{d.end_yardline}</td> 
            <td>{d.end_yards_to_goal}</td>
            <td>{d.end_time}</td> 
            <td>{d.is_home_offense}</td>
            <td>{d.start_offense_score}</td>
            <td>{d.start_defense_score}</td>
            <td>{d.end_offense_score}</td>
            <td>{d.end_defense_score}</td> */}
