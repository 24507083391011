import React, { useState, useEffect } from "react"
import {GetTime, LoadThisWeeksGames, SetWeekDay} from '../data'
import * as GlobalData from '../data';

export default function WeekDay({setCurrYear, setCurrWeek, setCurrDay})
{
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refreshCnt, setRefreshCnt] = useState(0)

    const fetchWeek = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_WEEK_INFO";
          const response = await fetch(url, {
            method: 'GET', // or 'POST', 'PUT', etc.
            headers: {
                'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                'Content-Type': 'application/json'
            }
        });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          console.log (url);
          const result = await response.json();
          {
            setCurrYear (result.CurrYear);
            setCurrWeek (result.CurrWeek);
            setCurrDay (result.CurrDay);
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };

      useEffect(() => {
      // Function to fetch data


      fetchWeek();

    }, [refreshCnt]); 
  


  useEffect(() => {

    if (refreshCnt === 0) fetchWeek();

        // Set up an interval that runs every second (1000ms)
    const interval = setInterval(() => {
      setRefreshCnt(prevCount => prevCount + 1); // Update state using the previous value
    }, 60000);

    // Cleanup the interval when the component unmounts or dependencies change
    return () => clearInterval(interval);
  }, []); // Empty dependency array to run only on mount and unmount


  return (<></>)

}