import GameInfoLive2 from "./GameInfoLive2";
import DriveGraphic from "./DriveGraphic";
import HorizontalLine from "./HorizontalLine";
import HorizontalLabel from "./HorizontalLabel";
import LastPlay from "./LastPlay";

export default function LiveGame({GID, gameInfo, possInfo, lastPlay, updaterTime})
{

    // const gameInfo = {
    //     GID: 530,
    //     AwayTeam: 'BYU',
    //     AwayRank: 11,
    //     AwayTID: 25,
    //     AwayScore: 41,
    //     HomeTeam: 'UCF',
    //     HomeTID: 188,
    //     HomeScore: 17,
    //     HomeRank: 50,
    //     Status: "status"
    //   };

    //   const possInfo = {
    //       down: 1,
    //       dist: 10,
    //       side: "BYU",
    //       startYdsToGoal: 70,
    //       endYdsToGoal: 42,
    //   };

    //   const lastPlay = {
    //     down: 3,
    //     dist: 4,
    //     side: "BYU",
    //     ydLn: 49,
    //     playText: "RJ Harvey rushes for 7 yds and a 1ST down",
    // };

    const downNames = ["0", "1st","2nd","3rd","4th"];
    
    let downdist = downNames[possInfo.down] + " & " + possInfo.dist;

    return (
        <>
        <GameInfoLive2 gameInfo={gameInfo} homePoss={true} updaterTime={updaterTime} />
        <br></br>
        <div className="blackbk">
            <h3 className='no-margin'></h3>
            <HorizontalLabel left={15} label1={downdist} color1={"white"}  />
            <DriveGraphic startYdsToGoal={possInfo.startYdsToGoal} side={possInfo.side} endYdsToGoal={possInfo.endYdsToGoal} />
            <h3 className=''></h3>
        </div>
        <br></br>
        <LastPlay lastPlay={lastPlay} />
        <h3 className="mark">{updaterTime}</h3>
        </>

    )
}