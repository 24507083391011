import TeamRecord from "./TeamRecord";
import React, { useState, useEffect } from "react"
import * as GlobalData from '../data';

export default function StandingsNew({placement, confID, confName})
{

    const [standings, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
      useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_STANDINGS&Conf=" + confID;
          console.log (url)
          const response = await fetch(url, {
            method: 'GET', // or 'POST', 'PUT', etc.
            headers: {
                'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                'Content-Type': 'application/json'
            }
        });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          
           setData(result);
          
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      if (confID > 0) fetchData();
  
  }, [confID]);  
  
    if (loading) 
      return (<h2>Loading...</h2>);
    else if (error) 
      return (<h2>Error: {error}</h2>);
    else 
    {

    const DivRecords1 = FilterDivRecords(standings, 1)
    const DivRecords2 = FilterDivRecords(standings, 2)
    let noDivConf=(DivRecords2.length === 0 ? true:false);

    const standings1=DivRecords1.map((data,id)=>{
        return <TeamRecord key={id} teamRecord={data}  />
      })
    const standings2=DivRecords2.map((data,id)=>{
        return <TeamRecord key={id} teamRecord={data} />
      })

    return (
        <>
            <table className="full-width" >
            <colgroup>
                <col style={{ width: '5%', textAlign: 'right'}} />
                <col style={{ width: '45%', textAlign: 'left'}} />
                <col style={{ width: '25%', textAlign: 'center' }} />
                <col style={{ width: '25%', textAlign: 'center' }} />
                </colgroup>
           <tbody>
           <tr className='small-row'>
                <th style={{textAlign: 'right', color: 'khaki'}}></th>
               <th style={{textAlign: 'left', color: 'khaki'}}>Team</th>
               <th style={{color: 'khaki'}}>Conference</th>
               <th style={{color: 'khaki'}}>Season</th></tr> 
            {standings1}
            </tbody>
            </table>
            {noDivConf?<></>
            :
            <>
            <br></br>
            <table className="table table-dark full-width" >
            <colgroup>
            <col style={{ width: '3%', textAlign: 'right'}} />
                <col style={{ width: '40%', textAlign: 'left'}} />
                <col style={{ width: '20%', textAlign: 'center' }} />
                <col style={{ width: '20%', textAlign: 'center' }} />
                </colgroup>
           <tbody>
           <tr className='small-row'>
             <th className='gray righty px10'></th><th style={{textAlign: 'left', color: 'khaki'}}>Team</th><th style={{color: 'khaki'}}>Conference</th><th style={{color: 'khaki'}}>Season</th></tr> 
            {standings2}
            </tbody>
            </table>
            </>} 
        </>
    )
    }
}

function FilterDivRecords(standings, div)
{
    var DivRec = []

    for (let record of standings)
    {
        if (record.DivID === div)
        {
            DivRec = record.Records
        }
    }

    return DivRec
}
