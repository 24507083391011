import Updater from "./Updater"
import LiveGameView from "./LiveGameView";
import PlayGameView2 from "./PlayGameView2";

import GameStatistics3 from "./GameStatistics3";
import Preview2 from "./Preview2";
import DriveView from "./DriveView";
import GameLog from './GameLog'
import MenuCommon from './MenuCommon';
import ConfBar from "./ConfBar";
import TeamBar from "./TeamBar";
import ViewSelector from "./ViewSelector";
import PopupHandler from './PopupHandler';
import GameDrivesPopup from "./GameDrivesPopup";
import GamePlaysPopup from "./GamePlaysPopup";

import {RetrieveCurrentScoreboard} from "../dungeonapi"
import {LoadThisWeeksGames} from "../data"
import { MenuChoices } from '../MenuChoices';

import * as GlobalData from '../data'

import React, { useState, useEffect } from "react"
import HandleCommon from "./HandleCommon";

export default function MainMobile({parent, rev,mobile,currYear, currWeek, currDay, activeMenu, setActive, setAndMoveUp, setGID, teamMenu, setTeamMenu, confID, confLabel, TID, teamLabel, GID})
{

const [popupState, setPopup] = useState(0);
    
const [gamesRetrieved, setGamesRetrieved] = useState(false)  
const [scoresWeek, setScoresWeek] = useState(1);
const [scoresLevel, setScoresLevel] = useState(0);


const [PID, setPID] = useState(0);

const [updaterTime, setUpdaterTime] = useState(0)
const [chosenWeek, setChosenWeek] = useState(currWeek);

const [hideLeft, setHideLeft] = useState(false)  
const [hideRight, setHideRight] = useState(false)  

const gridTemplateColumns = hideLeft
? hideRight
  ? '1fr' // If both are hidden, full width for middle
  : '1fr 40px' // If left is hidden, give remaining space and 40px for right
: hideRight
? '40px 1fr' // If right is hidden, give remaining space and 40px for left
: '40px 1fr 40px'; // If none are hidden, keep default


function toggleLeft()
{
    setHideLeft(prevState => !prevState)
}

function toggleRight()
{
    setHideRight(prevState => !prevState)
}

useEffect(() => { 
    // console.log ("HandleCommon initial useEffect ")

    if (currWeek >= 0)
    {
        RetrieveCurrentScoreboard (currYear, currWeek, LoadThisWeeksGames, setGamesRetrieved)
        // if (currWeek > 0) 
        //       RetrieveCurrentScoreboard (currYear, currWeek-1, LoadLastWeeksGames, Dummy)    
    }

}, [currWeek]);    




useEffect(() => { 

//   console.log ("HandleCommon useEffect "+scoresLevel)

  if (scoresLevel === 1 && confID > 0) // CONF
  {

  }
  else if (scoresLevel === 1 && confID === 0) // CONF
  {
    alert("Conf scores require a Conf selection")
    setScoresLevel(0)
  }
  
}, [scoresLevel]); 

useEffect(() => { 
    console.log ("HandleCommon GID changed "+GID)

}, [GID]); 

useEffect(() => {
    setChosenWeek(currWeek);
}, [currWeek]);  

    
return (
    <>
    <Updater    currWeek       = {currWeek}
                setUpdaterTime = {setUpdaterTime}   />

    <div className={"grid-container-mobile"}
         style={{ gridTemplateColumns }} >

        {!hideLeft && 
            <div className="left-column-mobile">

                    <ConfBar placement="" 
                        setGID={setGID}
                        rev={rev}
                        mobile={mobile}
                        toggleLeft={toggleLeft}
                        />
            </div>
        }


        <div className={'middle-column-mobile'}>
            <div className="high-container" style={{height: '690px'}}>
                <div className="box btn-container full-width">
                    <MenuCommon activeMenu={activeMenu} 
                                justSet={setActive}  
                                setAndMoveUp={setAndMoveUp} 
                                confID={confID} 
                                TID={TID} 
                                hideLeft={hideLeft}
                                toggleLeft={toggleLeft} 
                                hideRight={hideRight}
                                toggleRight={toggleRight}/>
                </div>

                <ViewSelector 
                                value={activeMenu} 
                                setView={setActive}
                                currWeek={currWeek} 
                                chosenWeek={chosenWeek} 
                                confLabel={confLabel}
                                confID={confID}
                                TID={TID} />

                <HandleCommon   parent={parent} 
                                rev={rev}
                                mobile={mobile}
                                currYear={currYear}
                                currWeek={currWeek}
                                currDay={currDay}
                                chosenWeek={chosenWeek}
                                setChosenWeek={setChosenWeek}
                                updaterTime={updaterTime}
                                activeMenu={activeMenu} 
                                setActive={setActive} 
                                popupState={popupState} 
                                setPopup={setPopup} 
                                PID={PID} 
                                setPID={setPID} 
                                GID={GID} 
                                setGID={setGID} 
                                teamMenu={teamMenu}
                                setTeamMenu={setTeamMenu} 
                                scoresLevel={scoresLevel} 
                                setScoresLevel={setScoresLevel} 
                                confID={confID} 
                                confLabel={confLabel} 
                                TID={TID} 
                                teamLabel={teamLabel} 
                                
                                /> 

                {popupState===MenuChoices.LIVE  ? 
                    <LiveGameView 
                                placement="box-container"
                                mobile={mobile}
                                setPopup={setPopup}
                                GID={GID}
                                updaterTime={updaterTime}
                                />
                        : <></> }

                {popupState===MenuChoices.DRIVES  ? 
                    <GameDrivesPopup 
                                placement="box-container"
                                mobile={mobile}
                                setPopup={setPopup}
                                GID={GID}
                                />
                        : <></> }

                {popupState===MenuChoices.PLAYS  ? 
                    <GamePlaysPopup 
                                placement="box-container"
                                mobile={mobile}
                                setPopup={setPopup}
                                GID={GID}
                                />
                        : <></> }

                {popupState===MenuChoices.GAME_STATS  ? 
                    <GameStatistics3
                                placement={""}
                                mobile={mobile}
                                label={"GAME STATISTICS"}
                                setPopup={setPopup}
                                GID={GID}
                                />
                        : <></> }

                {popupState===MenuChoices.PREVIEW  ? 
                    <Preview2   placement="box-container" 
                                mobile={mobile}
                                setPopup={setPopup}
                                GID={GID}
                            />
                        : <></> }

                {popupState===MenuChoices.GAMELOG  ? 
                    <GameLog
                                placement={""}
                                mobile={mobile}
                                label={"GAME LOG"}
                                setPopup={setPopup}
                                PID={PID}
                                />
                        : <></> }
            </div>      
        </div>

        {!hideRight && 
            <div className="right-column-mobile">
            {confID > 0 ?
                <TeamBar    placement  = ""
                            setActive   = {setActive}
                            confID      = {confID} 
                            TID         = {TID} 
                            mobile      = {mobile} 
                            rev         = {rev} 
                            toggleRight ={toggleRight}/>   
            :
            <></>      
            }      
            </div>
        }
        </div>
        </>
    )
}
