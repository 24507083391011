import GameType from "../games"
import GameInfoFuture from './GameInfoFuture';
import GameInfoComplete from './GameInfoComplete';
import {FilterGamesByConf} from '../utilities';

import React, { useState, useEffect } from "react"
import * as GlobalData from '../data';



export default function ScoresComplete({label, chosenWeek, confID, confLabel, activeMenu, setActive, setPopup, setGID, scoresLevel})
{
    const [chosenWeeksGames, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_SCOREBOARD&Week="+chosenWeek;
          // console.log (url)
          const response = await fetch(url, {
            method: 'GET', // or 'POST', 'PUT', etc.
            headers: {
                'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                'Content-Type': 'application/json'
            }
        });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setData( LoadChosenWeeksGames(result));
            // console.log ("loaded " + result.length + " games")
          }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      fetchData();
  
    }, [chosenWeek]);  

    if (loading) 
        return (<h2>Loading...</h2>);
    else if (error) 
        return (<h2>Error: {error}</h2>);
    else if (chosenWeeksGames == 0 || chosenWeeksGames.length == 0)
        return (<h2>Waiting on Data</h2>);
    else
    {
        var chosenWeeksConfGames = FilterGamesByConf (chosenWeeksGames, confID);


        const getPlays = (val, GID)  => {
            setGID (GID)
            setPopup(val)
        }
        
        const getGameStats = (val, GID, gameInfo) => {
            setGID (GID)
            setPopup(val)
        }
        
        const getDrives = (val, GID, gameInfo) => {
            setGID (GID)
            setPopup(val)
        }

        const getPreview = (val, GID) => {
            setGID(GID)
            setPopup(val)
            }
    
        // Helper function to render complete games
        const renderCompleteGames = (games) => {
          return games.map((data, id) => 
            data.Complete ? (
              <GameInfoComplete
                key={id}
                gameInfo={data}
                getPlays={getPlays}
                getGameStats={getGameStats}
                getDrives={getDrives}
                getPreview={getPreview}
              />
            ) : null
          );
        };

        // Helper function to render future games
        const renderFutureGames = (games) => {
          return games.map((data, id) => 
            !data.Complete ? (
              <GameInfoFuture
                key={id}
                gameInfo={data}
                getPreview={getPreview}
              />
            ) : null
          );
        };

        // Using the helper functions for chosen weeks and conference games
        const chosenWeeksScores = renderCompleteGames(chosenWeeksGames);
        const chosenWeeksScoresFuture = renderFutureGames(chosenWeeksGames);
        const chosenWeeksConfScores = renderCompleteGames(chosenWeeksConfGames);
        const chosenWeeksConfScoresFuture = renderFutureGames(chosenWeeksConfGames);    


        var allLen=0, allLenConf=0;

        if (scoresLevel===0)
        {
            label = "FBS"
            allLen = chosenWeeksGames.length;
            allLenConf=0
        }
        else if (scoresLevel===1)
        {
            label = confLabel;
            allLenConf = chosenWeeksConfGames.length;
            allLen=0
        }


        return (
            <>
                {allLen>0?<>{chosenWeeksScores}</>:<></>}
                {allLen>0?<>{<hr />}</>:<></>}
                {allLen>0?<>{chosenWeeksScoresFuture}</>:<></>}
                {allLenConf>0?<>{chosenWeeksConfScores}</>:<></>}            
                {allLenConf>0?<>{<hr />}</>:<></>}            
                {allLenConf>0?<>{chosenWeeksConfScoresFuture}</>:<></>}            
            </>
        )
    }
}

function LoadChosenWeeksGames(dbGames)
{
    // console.log ("ScoresComplete: LoadChosenWeeksGames")
    var list = []
    
    for (var i=0; i<dbGames.length; i++)
    {        
            const Game = Object.create(GameType);	
    
             Game.Initialize   (parseInt (dbGames[i].GID),
                            parseInt (dbGames[i].AwayTID),
                            dbGames[i].AwayTeam,
                            dbGames[i].AwayRank,
                            dbGames[i].AwayCoach,
                            dbGames[i].AwayStar,
                            parseInt (dbGames[i].AwayConf),
                            parseInt (dbGames[i].AwayScore),

                            parseInt (dbGames[i].HomeTID),
                            dbGames[i].HomeTeam,
                            dbGames[i].HomeRank,
                            dbGames[i].HomeCoach,
                            dbGames[i].HomeStar,
                            parseInt (dbGames[i].HomeConf),
                            parseInt (dbGames[i].HomeScore),
                            
                            parseFloat (dbGames[i].Spread),
                            parseInt (dbGames[i].Week),
                            parseInt (dbGames[i].Day),
                            dbGames[i].TV,
                            dbGames[i].Time,
                            dbGames[i].Status,
                            dbGames[i].Neutral,
                            dbGames[i].Active,
                            dbGames[i].Complete);
    
        list.push(Game)
    }

    return list;
}
