import MainMobile from './MainMobile'
import MainDesktop from './MainDesktop'
import {
        RetrieveConfTeams,
        RetrieveCurrentScoreboard} from "../dungeonapi"

import {LoadConfTeams,
        LoadThisWeeksGames,
        FilterGamesByTID} from "../data"

import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import * as GlobalData from '../data';
import * as Images from '../images';
import { MenuChoices } from '../MenuChoices';

export default function MainTeam({rev, mobile, currYear, currWeek, currDay, activeMenu, setActive, GID, setGID, confName, TID, teamName})
{
    const [gamesRetrieved, setGamesRetrieved] = useState(false);
    const [teamsRetrieved, setTeamsRetrieved] = useState(false);
    const [teamMenu, setTeamMenu] = useState(MenuChoices.HOME);



    const [tName, setTeamName] = useState(false);


    const navigate = useNavigate();

    const justSet = (val) => {
        setActive(val)
      }
        
    const justMove = (nm) => {
        navigate("/"+nm)
      }

    const setAndMoveUp = (nm, val) => {
        navigate("/"+nm)
        setActive(val)
      }
      
    const ChangeTeamMenu = (val) => {
        setTeamMenu(val)
        setActive(val)
    }
          
        useEffect(() => { 
        
            RetrieveConfTeams (confID, LoadConfTeams, setTeamsRetrieved);	
        
            RetrieveCurrentScoreboard (currYear, currWeek, LoadThisWeeksGames, setGamesRetrieved)
            
            if (activeMenu != MenuChoices.SCHEDULE && activeMenu != MenuChoices.ROSTER && activeMenu != MenuChoices.DEPTH )
                setActive(MenuChoices.HOME)
            else
                console.log ("MainTeam: Team changed but Menu left unchanged")
            
        }, [TID]);    
        
    
    let confID = Images.ConfNames.indexOf(confName)

          
    return (

        <>
        {mobile?
            <MainMobile 
                        parent         = {"MainFBS"}
                        currYear       = {currYear}
                        currWeek       = {currWeek}
                        currDay       =  {currDay}
                        activeMenu     = {activeMenu}
                        setActive      = {setActive}
                        setAndMoveUp   = {setAndMoveUp}
                        setGID         = {setGID}
                        GID            = {GID}
                        initStatLevel  = {0} 
                        teamMenu       = {teamMenu}
                        setTeamMenu    = {setTeamMenu}
                        confID         = {confID}
                        TID            = {TID}
                        confLabel      = {confName}
                        teamLabel      = {teamName}
                        rev={rev}
                        mobile={mobile} 
                        />
    
        :
           <MainDesktop 
                        parent         = {"MainFBS"}
                        currYear       = {currYear}
                        currWeek       = {currWeek}
                        currDay       =  {currDay}
                        activeMenu     = {activeMenu}
                        setActive      = {setActive}
                        setGID         = {setGID}
                        GID            = {GID}
                        initStatLevel  = {0} 
                        teamMenu       = {teamMenu}
                        setTeamMenu    = {setTeamMenu}
                        confID         = {confID}
                        TID            = {TID}
                        confLabel      = {confName}
                        teamLabel      = {teamName}
                        rev={rev}
                        mobile={mobile} 
                   />
    
        }
    
      </>
    )
}

