import StatLine from './StatLine';
import StatLineDef from './StatLineDef';
import { GetSelectedGame } from '../data';
import * as GlobalData from '../data';

import React, { useState, useEffect } from "react"


export default function GameStatistics3 ({ placement, mobile, setPopup, label, GID})
{
    const [gameData, setData] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(true);
    const [homeAway, setHomeAway] = useState('AWAY')

    const [selectedData, setSelectedData] = useState(null)

    const ChangeHomeAway = (val) => {
        setHomeAway(val)
      }

      const closePopup = () => {
        setIsPopupOpen(false);
        setPopup(0)
    }
    
    useEffect(() => {
        if (homeAway === 'AWAY' && gameData != null)
            setSelectedData (gameData.Away);
        else if (homeAway === 'HOME' && gameData != null)
            setSelectedData (gameData.Home);

    }, [homeAway]); 

    useEffect(() => {

        const fetchData = async () => {
            try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_GAME_STATISTICS&GID="+GID;
          console.log (url)
          const response = await fetch(url, {
            method: 'GET', // or 'POST', 'PUT', etc.
            headers: {
                'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                'Content-Type': 'application/json'
            }
        });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          {
            setData(result);
            if (homeAway === 'AWAY')
                setSelectedData (result.Away);
            else    
                setSelectedData (result.Home);
        }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };

      if (GID > 0) 
      {
        fetchData();
      }
  
  }, [GID]);  
  
    if (loading) 
      return (<h2>Loading...</h2>);

    else if (error) 
      return (<h2>Error: {error}</h2>);

    else if (selectedData == null) 
      return (
      <div className="popup">
      <button className="close-icon" onClick={closePopup}>&times;</button>
      <br></br>
      <h2>Stats are not yet available</h2>
      </div>
      );
    else
    {
        let fontclass = "ltblue popup-item";

        const passingHdr = ["Player", "C/A", "Yds", "TDs", "INTs", "QBR"];
        const rushingHdr = ["Player", "Rush", "Yds", "TDs", "Long", "Avg"];
        const receivingHdr = ["Player", "Rec", "Yds", "TDs", "Long", "Avg"];
        const defensiveHdr = ["Player", "Tack", "Solo", "TFL", "Sack", "Hur"];
        const fumblesHdr = ["Player", "Number", "Rec", "Lost"];
        const interceptionsHdr = ["Player", "Number", "Yds", "TDs"];
        const kickingHdr = ["Player", "Points", "XP", "FG", "Long", "Pct"];
        const puntingHdr = ["Player", "Number", "Touch", "In_20", "Long", "Avg"];
        const kickreturnsHdr = ["Player", "Number", "Yds", "Long", "TDs", "Avg"];
        const puntreturnsHdr = ["Player", "Number", "Yds", "Long", "TDs", "Avg"];
    
    
        
        const passingHeader = passingHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
        const rushingHeader = rushingHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
        const receivingHeader = receivingHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
        const defensiveHeader = defensiveHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
        const fumblesHeader = fumblesHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
        const interceptionsHeader = interceptionsHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
        const kickingHeader = kickingHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
        const puntingHeader = puntingHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
        const kickreturnsHeader = kickreturnsHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})
        const puntreturnsHeader = puntreturnsHdr.map((data,id) => { return <KhakiHeader key={id} field={data} />})

        // console.log (selectedData)
        // console.log ("PAS="+selectedData.Passing.length)
        // console.log ("RUS="+selectedData.Rushing.length)
        // console.log ("RCV="+selectedData.Receiving.length)
        // console.log ("DEF="+selectedData.Defensive.length)
        // console.log ("FUM="+selectedData.Fumbles.length)
        // console.log ("INT="+selectedData.Interceptions.length)
        // console.log ("K="+selectedData.Kicking.length)
        // console.log ("P="+selectedData.Punting.length)
        // console.log ("KR="+selectedData.KickReturns.length)
        // console.log ("KR="+selectedData.PuntReturns.length)

        var passingLine=selectedData.Passing.map((data,id)=>{
            let AC=data.Comps+" / "+data.Attempts;    
            return <Linev1 key={id} name={data.CommonName}  number={AC}  yds={data.Yds}  tds={data.TDs}  longest={data.INTs}  avg={data.QBR} other={''}/>})
        
        var rushingLine=selectedData.Rushing.map((data,id)=>{
            return <Linev1 key={id} name={data.CommonName} number={data.Rushes}  yds={data.Yds}  tds={data.TDs}  longest={data.Longest}  avg={data.Avg} other={''} />})
        
        var receivingLine=selectedData.Receiving.map((data,id)=>{
            return <Linev1 key={id} name={data.CommonName} number={data.Receptions}  yds={data.Yds}  tds={data.TDs}  longest={data.Longest}  avg={data.Avg} other={''} />})
        
        var defensiveLine=selectedData.Defensive.map((data,id)=>{
            return <Linev3 key={id} name={data.CommonName} tackles={data.Tackles}  solo={data.Solo}  TFL={data.TFLs}  sacks={data.Sacks}  deflections={data.Deflections} hurries={data.Hurries} TDs={data.TDs} />})

        var fumblesLine=selectedData.Fumbles.map((data,id)=>{
            return <Linev2 key={id} name={data.CommonName} number={data.Number}  yds={data.Recovered}  tds={data.Lost}   />})
            
        var interceptionsLine=selectedData.Interceptions.map((data,id)=>{
            return <Linev2 key={id} name={data.CommonName} number={data.Number}  yds={data.Yds}  tds={data.TDs}   />})
            
        var kickingLine=selectedData.Kicking.map((data,id)=>{
            let XP=data.XPM+" / "+data.XPA;    
            let FG=data.FGM+" / "+data.FGA;

            return <Linev1 key={id} name={data.CommonName} number={data.Points}  yds={XP}  tds={FG}  longest={data.Longest}  avg={data.Pct}  />})

        var puntingLine=selectedData.Punting.map((data,id)=>{
            return <Linev1 key={id} name={data.CommonName} number={data.Number}  yds={data.Touchbacks} tds={data.In_20} longest={data.Longest}  avg={data.Avg} />})
                
        var kickReturnsLine=selectedData.KickReturns.map((data,id)=>{
            return <Linev1 key={id} name={data.CommonName} number={data.Number}  yds={data.Yds}  tds={data.TDs}  longest={data.Longest} other={''}  avg={data.Avg} />})
                
        var puntReturnsLine=selectedData.PuntReturns.map((data,id)=>{
            return <Linev1 key={id} name={data.CommonName} number={data.Number}  yds={data.Yds}  tds={data.TDs}  longest={data.Longest} other={''}  avg={data.Avg} />})

        let awayTeam = gameData.Away.Team;
        let homeTeam = gameData.Home.Team;
        // var gameInfo = GetSelectedGame();
        // console.log (gameInfo)

        return (

            <div className={mobile?"popup-noscroll":'popup-noscroll-desktop'}>
                <div className='flex-container'>
                    <div className='popup-item full-width  fixed-height'>
                        <button className="close-icon" onClick={closePopup}>&times;</button>
                        <h2 className='khaki'>GAME STATS </h2>
                        <TeamSelector homeAway={homeAway} awayTeam={awayTeam} homeTeam={homeTeam} ChangeHomeAway={ChangeHomeAway} />
                    </div>
                    <div className="popup-item-scroll full-width flexible-height">
                        <CategoryTable length={selectedData.Passing.length} label={"PASSING"} hdrs={passingHeader} rows={passingLine} />
                        <CategoryTable length={selectedData.Rushing.length} label={"RUSHING"} hdrs={rushingHeader} rows={rushingLine} />
                        <CategoryTable length={selectedData.Receiving.length} label={"RECEIVING"} hdrs={receivingHeader} rows={receivingLine} />
                        <CategoryTable length={selectedData.Defensive.length} label={"DEFENSE"} hdrs={defensiveHeader} rows={defensiveLine} />
                        <CategoryTable length={selectedData.Interceptions.length} label={"INTERCEPTIONS"} hdrs={interceptionsHeader} rows={interceptionsLine} />
                        <CategoryTable length={selectedData.Fumbles.length} label={"FUMBLES"} hdrs={fumblesHeader} rows={fumblesLine} />
                        <CategoryTable length={selectedData.KickReturns.length} label={"KICK RETURNS"} hdrs={kickreturnsHeader} rows={kickReturnsLine} />
                        <CategoryTable length={selectedData.PuntReturns.length} label={"PUNT RETURNS"} hdrs={puntreturnsHeader} rows={puntReturnsLine} />
                        <CategoryTable length={selectedData.Kicking.length} label={"KICKING"} hdrs={kickingHeader} rows={kickingLine} />
                        <CategoryTable length={selectedData.Punting.length} label={"PUNTING"} hdrs={puntingHeader} rows={puntingLine} />
                    </div>
                </div>
            </div>
        ) 
    }
}

function TeamSelector({homeAway, awayTeam, homeTeam, ChangeHomeAway})
{
    return(
        <div className="box btn-container">
            <button className="whiteButtonInv"></button>                    
            <button className={`${homeAway==='AWAY' ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  ChangeHomeAway('AWAY')} >{awayTeam}</button>
            <button className={`${homeAway==='HOME' ? "ltblueButtonInv" : "whiteButtonInv"}`} onClick={() =>  ChangeHomeAway('HOME')} >{homeTeam}</button>
            <button className="whiteButtonInv"></button>                    
        </div>
    )
}


function CategoryTable ({length, label, hdrs, rows})
{
    let fontclass = "ltblue popup-item  small-font";
    return (
        <div className="popup-container full-width">
            {length > 0 ? 
                <LogTablev1 placement={"popup-item full-width"} layout="table-coal full-width" fontclass={fontclass} label={label} hdrs={hdrs} rows={rows} />
            :   <>
                <h3 className='ltblue full-width'>--- No {label} stats available ---</h3>
                </> 
                }
        </div>
    )
}

function KhakiHeader({field})
{
    return (
        (field === 'Player'  ?
            <th style={{textAlign: 'left', color: 'khaki', fontSize: '10px'}}>{field}</th>         
            :
            <th style={{textAlign: 'right', color: 'khaki', fontSize: '10px'}}>{field}</th>         
        )
    ) 
}

function LogTablev1 ({placement, layout, fontclass, label, hdrs, rows})
{
    return (
        <div className={placement}>
            <h2 className={fontclass}>{label}</h2>
            <table className={layout}>
            <colgroup>
            <col style={{ width: '30%', textAlign: 'left' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            <col style={{ width: '10%', textAlign: 'right' }} />
            </colgroup>
            <tbody style={{'height': '15vh'}}>
                <tr>{hdrs}</tr>
                {rows}
            </tbody>
            </table>
        </div>
    )
}


function Linev1({ name, number, yds, tds, longest, avg, other })
{
    return (<tr style={{textAlign:'right', fontSize: '12px'}}><td style={{textAlign:'left'}}>{name}</td><td>{number}</td><td>{yds}</td><td>{tds}</td><td>{longest}</td><td>{avg}</td></tr>)
}

function Linev2({ name,  number, yds, tds})
{
    return (<tr style={{textAlign:'right', fontSize: '12px'}}><td style={{textAlign:'left'}}>{name}</td><td>{number}</td><td>{yds}</td><td>{tds}</td></tr>)
}

function Linev3({ name,  tackles, solo, TFL, sacks, deflections, hurries, TDs })
{
    return (<tr style={{textAlign:'right', fontSize: '12px'}}><td style={{textAlign:'left'}}>{name}</td><td>{tackles}</td><td>{solo}</td><td>{TFL}</td><td>{sacks}</td><td>{hurries}</td></tr>)
}


