import React, { useState, useEffect } from "react"
import {GetTime, 
        LoadThisWeeksGames,
        UpdateThisWeeksScores} from '../data'
import * as GlobalData from '../data';

export default function Updater({currWeek, setUpdaterTime})
{
    const [activeGames, setActiveGames] = useState(0);
    const [liveScores, setLiveScores] = useState(0);
    const [refreshCnt, setRefreshCnt] = useState(0)
    const [loadingActive, setLoadingActive] = useState(true);
    const [loadingGames, setLoadingGames] = useState(true);
    const [loadingScores, setLoadingScores] = useState(true);
    const [error, setError] = useState(null);
    const [tm, setTime] = useState(0)
  

  useEffect(() => {

      // Set up an interval that runs every second (1000ms)
      const interval = setInterval(() => {
        setRefreshCnt(prevCount => prevCount + 1); // Update state using the previous value
      }, 10000);

      // Cleanup the interval when the component unmounts or dependencies change
      return () => clearInterval(interval);

  }, []); // Empty dependency array to run only on mount and unmount

  useEffect(() => {

      const fetchGames = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_SCOREBOARDWeek="+currWeek;
          console.log (url)
          const response = await fetch(url, {
            method: 'GET', // or 'POST', 'PUT', etc.
            headers: {
                'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                'Content-Type': 'application/json'
            }
        });
          // console.log (url)
      
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();
        {
          LoadThisWeeksGames(result);
        }
      } 
      catch (error) {
        setError(error.message);
      } 
      finally {
        setLoadingGames(false);
      }
    };

    fetchGames();

  }, []); 

  useEffect(() => {
      // Function to fetch data

    const fetchActive = async () => {
      try {
        const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=COUNT_ACTIVE_GAMES&Week="+currWeek;
        // console.log (url)

        const response = await fetch(url, {
          method: 'GET', // or 'POST', 'PUT', etc.
          headers: {
              'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
              'Content-Type': 'application/json'
          }
      });
      
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
    
      const result = await response.json();
      {
        setActiveGames(result);
            // console.log (refreshCnt + " Updater found active games = " + activeGames)
      }
    } 
    catch (error) {
        setError(error.message);
    } 
    finally {
          setLoadingActive(false);
    }
  };
  
  const fetchScores = async () => {
    try {
      const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_SCORES_ONLY&Week="+currWeek;
      // console.log (url)

      const response = await fetch(url, {
        method: 'GET', // or 'POST', 'PUT', etc.
        headers: {
            'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
            'Content-Type': 'application/json'
        }
    });
      // console.log (url)
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      {
        setLiveScores(result);
        UpdateThisWeeksScores(result);
      }
    } 
    catch (error) {
      setError(error.message);
    } 
    finally {
      setLoadingScores (false);
    }
  };

  fetchActive();
  fetchScores();

  let tmp = GetTime();
      setUpdaterTime(tmp)
      setTime(tmp)
    
  }, [refreshCnt]);  


  return (<p className="mark">{tm}</p>)

}