import { GetThisGame } from "../data"
import React, { useState, useEffect } from "react"

import * as GlobalData from '../data';


export default function LiveTeams({currWeek, GID})
{
    const [awayData, setAwayData] = useState(null);
    const [homeData, setHomeData] = useState(null);
    const [liveTeamStats, setLiveTeamStats] = useState(null);
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [loadingAway, setLoadingAway] = useState(true);
    const [loadingHome, setLoadingHome] = useState(true);
    const [error, setError] = useState(null);
    const [awayTeam, setAwayTeam] = useState(0);
    const [awayTID, setAwayTID] = useState(0);
    const [awayScore, setAwayScore] = useState(0);
    const [homeTeam, setHomeTeam] = useState(0);
    const [homeTID, setHomeTID] = useState(0);
    const [homeScore, setHomeScore] = useState(0);
    const [tmSelected, setTmSelected] = useState('');
    const [tidSelected, setTidSelected] = useState(0);

    const saveTeam = (tid,name)  => {
        setTidSelected(tid);
        setTmSelected(name);
    }

    useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
            const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_GAME_INFO&GID="+GID;
            console.log (url)
            const response = await fetch(url, {
              method: 'GET', // or 'POST', 'PUT', etc.
              headers: {
                  'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                  'Content-Type': 'application/json'
              }
          });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const results = await response.json();
            {
                setAwayTeam( results.AwayTeam);
                setAwayTID( results.AwayTID);
                setAwayScore( results.AwayScore);
                setHomeTeam( results.HomeTeam);
                setHomeTID( results.HomeTID);
                setHomeScore( results.HomeScore);
                saveTeam(results.HomeTID, results.HomeTeam)
            }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoadingInfo(false);
        }
      };
  
      const fetchGameStats = async () => {
        try {
            const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_LIVE_TEAM_STATS&GID="+GID;
            console.log (url)
            const response = await fetch(url, {
              method: 'GET', // or 'POST', 'PUT', etc.
              headers: {
                  'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                  'Content-Type': 'application/json'
              }
          });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const results = await response.json();
            {
                setLiveTeamStats( results);
            }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoadingInfo(false);
        }
      };

      fetchData();
      fetchGameStats();
  
    }, [GID]);  

    useEffect(() => {
        // Function to fetch data
        const fetchAway = async () => {
          try {
              const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_TEAM_STATS&TID="+awayTID;
              console.log (url)
              const response = await fetch(url, {
                method: 'GET', // or 'POST', 'PUT', etc.
                headers: {
                    'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                    'Content-Type': 'application/json'
                }
            });
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              const results = await response.json();
              {
                setAwayData(results);
              }
          } 
          catch (error) {
            setError(error.message);
          } 
          finally {
                setLoadingAway(false);
  }
        };
    
        if (awayTID > 0) fetchAway();
    
      }, [awayTID]);  
      
    
      useEffect(() => {
        // Function to fetch data
        const fetchHome = async () => {
          try {
              const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_TEAM_STATS&TID="+homeTID;
              console.log (url)
              const response = await fetch(url, {
                method: 'GET', // or 'POST', 'PUT', etc.
                headers: {
                    'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                    'Content-Type': 'application/json'
                }
            });
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              const results = await response.json();
              {
                setHomeData(results);
              }
          } 
          catch (error) {
            setError(error.message);
          } 
          finally {
                setLoadingHome(false);
            }
        };
    
        if (homeTID > 0) fetchHome();
    
      }, [homeTID]);  
  
      const above = (today, average) => { return today > average;};

      const toolow = (today, average, period) => { 
          if (period == 1) return today < average*0.20;
          else if (period == 2) return today < average*0.4;
          else if (period == 3) return today < average*0.70;
          else if (period == 4) return today < average*0.90;
      }

      function TeamTable({name,teamStats, todayScore, gameStats})
      {
          // let avgMins = parseInt(teamStats.TimeOfPoss.TotalMins / teamStats.NumGames);
          // let avgSecs = parseInt(teamStats.TimeOfPoss.TotalSecs / teamStats.NumGames);
          // let avgTOP = avgMins+":"+avgSecs;

        //   let today = TodaysStats(label, item.CommonName);
          let avgScore = Math.floor(teamStats.Scoring/teamStats.NumGames);
          let avgRush = Math.floor(teamStats.RushingYds  / teamStats.NumGames);
          let avgPass = Math.floor(teamStats.PassingYds / teamStats.NumGames);
          let avgYds = Math.floor((parseInt(teamStats.RushingYds) + parseInt(teamStats.PassingYds)) / teamStats.NumGames);

      return (
              <>
              <h3>{name}</h3>
              <table>
              <tbody>
                  <tr><th>Category</th><th>Average</th><th>Today</th></tr>

              <tr className="small-row">
                <td>Scoring</td><td>{parseInt(teamStats.Scoring/teamStats.NumGames)}</td>
                {above(todayScore, avgScore) ? (
                <td className="bluebk">{todayScore}</td>
                ) : toolow(todayScore, avgScore, 4) ? (
                <td className="redbk">{todayScore}</td>
                ) : (<td className="greenbk">{todayScore}</td>)
                }
              </tr>
              <tr className="small-row">
                  <td>Total YDS</td><td>{avgYds}</td>
                  {above(gameStats.totalYds, avgYds) ? (
                <td className="bluebk">{gameStats.totalYds}</td>
                ) : toolow(gameStats.totalYds, avgYds, 4) ? (
                <td className="redbk">{gameStats.totalYds}</td>
                ) : (<td className="greenbk">{gameStats.totalYds}</td>)
                }
                  </tr>
              <tr className="small-row">
                  <td>Rushing</td><td>{avgRush}</td>
                  {above(gameStats.rushYds, avgRush) ? (
                <td className="bluebk">{gameStats.rushYds}</td>
                ) : toolow(gameStats.rushYds, avgRush, 4) ? (
                <td className="redbk">{gameStats.rushYds}</td>
                ) : (<td className="greenbk">{gameStats.rushYds}</td>)
                }
                </tr>
                <tr className="small-row">
                  <td>Passing</td><td>{avgPass}</td>                  
                  {above(gameStats.passYds, avgPass) ? (
                <td className="bluebk">{gameStats.passYds}</td>
                ) : toolow(gameStats.passYds, avgPass, 4) ? (
                <td className="redbk">{gameStats.passYds}</td>
                ) : (<td className="greenbk">{gameStats.passYds}</td>)
                }
                  </tr>
            {/* <tr><td>Time of Poss</td><td>{avgTOP}</td><td>today</td></tr> */}
              </tbody>
              </table>
              </>
          )
      }
            
    
    if (loadingInfo || loadingAway || loadingHome) 
        return (<h2>Loading...</h2>);
    else if (error) 
        return (<h2>Error: {error}</h2>);
    else
    {
        return (
        <>
            <div className="box btn-container-desktop">
                  <button className={"noButton"} ></button>
                  <button className={`${tmSelected===awayTeam? "ltblueButtonInv" : "whiteButtonInv"}`} 
                                    onClick={() =>  saveTeam(awayTID, awayTeam)} >{awayTeam}</button>
                  <button className={`${tmSelected===homeTeam? "ltblueButtonInv" : "whiteButtonInv"}`} 
                                    onClick={() =>  saveTeam(homeTID, homeTeam)} >{homeTeam}</button>
                  <button className={"noButton"} ></button>
            </div>

            <div className="full-width three-quarter-height scroll">
                {tidSelected==awayTID?<TeamTable  name={awayTeam} teamStats={awayData} todayScore={awayScore} gameStats={liveTeamStats.awayStats}/>:<></>}
                {tidSelected==homeTID?<TeamTable  name={homeTeam} teamStats={homeData} todayScore={homeScore} gameStats={liveTeamStats.homeStats}/>:<></>}
            </div>
        </>
        )
    }
}


