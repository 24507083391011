import GameInfoLive from './GameInfoLive';

import {FilterGamesByConf,
        FilterGamesByDay} from '../utilities';
import * as GlobalData from '../data';

import React, { useState,useEffect } from "react"


export default function LiveScores ({label, confID, confLabel, currWeek, currDay, scoresLevel, setScoresLevel, activeMenu, setActive, setPopup, setGID, updaterTime})
{
    const [weekPossData, setPossInfo] = useState(0);
    const [error, setError] = useState(0);
    const [loading, setLoading] = useState(true);

// const ChangeScoresLevel = (val) => {
//   // console.log ("ChangeScoresLevel Val="+val)
//   setScoresLevel(val)
// }
useEffect(() => {
    // Function to fetch data


    const fetchPossData = async () => {
      try {
        const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_POSS_INFO_BY_WEEK&Week="+currWeek;
        const response = await fetch(url, {
          method: 'GET', // or 'POST', 'PUT', etc.
          headers: {
              'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
              'Content-Type': 'application/json'
          }
      });
        console.log (url)
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        {
          setPossInfo(result);
        }
      } 
      catch (error) {
        setError(error.message);
      } 
      finally {
        setLoading(false);
      }
    };

    fetchPossData();

  
}, [updaterTime]);  


const getPreview = (val, GID) => {

  // console.log ("getPreview Val="+val+" GID="+GID)
  setGID(GID)
  setPopup(val)
}
  
const getLive = (val, GID) => {
  // console.log ("getLive Val="+val+" GID="+GID)
  setGID(GID)
  setPopup(val)
}

const getPlays = (val, GID)  => {
  // console.log ("getPlays Val="+val+" GID="+GID)
  setGID (GID)
  setPopup(val)
}


const getGameStats = (val, GID) => {
  // console.log ("getGameStats Val="+val+" GID="+GID)
  setGID(GID)
  setPopup(val)
}

function FindPossByGID(GID)
{
    // const poss = 
    // return [poss]
    for (let i=0; i<weekPossData.length; i++)
        if (weekPossData[i].GID == GID) return weekPossData[i];
}

// const possInfo = {
//   offTID: 188,
//   offTeam: '',
//   clock: '12:34',
//   yard_line: -20,
//   down: '3rd',
//   distance: 5,
//   period: 3
// };



    if (loading)
        return (<h2>loading...</h2>);
    else
    {
        var TodaysGames = FilterGamesByDay (GlobalData.ThisWeeksGames, currDay)

        var todaysGames = TodaysGames.map((data,id)=>{
              return <GameInfoLive key={id} updaterTime={updaterTime} gameInfo={data} possInfo={FindPossByGID(data.GID)} getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
        })
        
        var ThisWeeksConfGames = FilterGamesByConf (TodaysGames, confID);

        console.log ("This Weeks Conf Games " + ThisWeeksConfGames.length)
        
        
        var todaysConfGames = ThisWeeksConfGames.map((data,id)=>{
                return <GameInfoLive key={id}  updaterTime={updaterTime} gameInfo={data} possInfo={FindPossByGID(data.GID)} getPreview={getPreview} getLive={getLive} getPlays={getPlays} getGameStats={getGameStats}/>
        })

        return  (
            <DayView label={scoresLevel===0 ? "FBS" : "CONF"}  
                    //  currDay={currDay} 
                    //  len={scoresLevel===0 ? TodaysGames.length : todaysConfGames.length} 
                     games={scoresLevel===0 ? todaysGames : todaysConfGames} />            
        )
    }
}

function DayView({label, games})
{
  return ( <>
      <h2>{label}</h2>
      {games}
    </> )
}

function DayView2({label, currDay, len, games})
{
  return ( <>
      {len > 0  ?
      <>
      <h2>{label}</h2>
      {games}
      </>
      : <></>
      }
    </> )
}
