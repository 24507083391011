
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import React, { useState, useEffect } from "react"


import './App.css';
import './css/mycss.css';

import MainFBS   from './components/MainFBS';
import MainConf  from './components/MainConf';
import MainTeam  from './components/MainTeam';
import WeekDay from "./components/WeekDay";

import { MenuChoices } from "./MenuChoices";


function App() {

  const [currYear, setCurrYear] = useState(-1);
  const [currWeek, setCurrWeek] = useState(-1);
  const [currDay, setCurrDay] = useState(-1);
  const [activeMenu, setActive] = useState(MenuChoices.ABOUT);
  const [GID, setGID] = useState(0);


  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      console.log (userAgent)
      
      // Check if userAgent matches common mobile devices
      if (/android|iphone|ipad|iPod|opera mini|iemobile|mobile/i.test(userAgent)) {
        setIsMobile(true); 
      } else {
        setIsMobile(false);
      }
    };

    checkIfMobile();
  }, []);

  let rev = "11.16.1"

  return (

    <div className="App">
        <WeekDay setCurrYear={setCurrYear} setCurrWeek={setCurrWeek} setCurrDay={setCurrDay}/>
      <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}  
        <Routes>


          <Route path="/"            element={<MainFBS rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} />} /> 
          <Route path="/FBS"         element={<MainFBS rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} />} /> 
          <Route path="/SEC"         element={<MainConf rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confID={1} confName="SEC"/>} /> 
          <Route path="/B1G"         element={<MainConf rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confID={2} confName="B1G" />} />
          <Route path="/XII"         element={<MainConf rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confID={5} confName="XII" />} />
          <Route path="/ACC"         element={<MainConf rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confID={3} confName="ACC" />} />
          <Route path="/AAC"         element={<MainConf rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confID={6} confName="AAC" />} />
          <Route path="/MWC"         element={<MainConf rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confID={8} confName="MWC" />} />
          <Route path="/SUN"         element={<MainConf rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confID={9} confName="SUN" />} />
          <Route path="/CUSA"         element={<MainConf rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confID={7} confName="CUSA" />} />
          <Route path="/MAC"          element={<MainConf rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confID={10} confName="MAC" />} />
          <Route path="/Independents" element={<MainConf rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confID={11} confName="Independents" />} />

          <Route path="/SEC/Alabama" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={4} teamName="Alabama"/>} /> 
          <Route path="/SEC/Arkansas" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={12} teamName="Arkansas"/>} /> 
          <Route path="/SEC/Auburn" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={16} teamName="Auburn"/>} /> 
          <Route path="/SEC/Florida" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={53} teamName="Florida"/>} /> 
          <Route path="/SEC/Georgia" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={58} teamName="Georgia"/>} /> 
          <Route path="/SEC/Kentucky" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={83} teamName="Kentucky"/>} /> 
          <Route path="/SEC/LSU" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={91} teamName="LSU"/>} /> 
          <Route path="/SEC/Mississippi State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={105} teamName="Mississippi State"/>} /> 
          <Route path="/SEC/Missouri" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={106} teamName="Missouri"/>} /> 
          <Route path="/SEC/Oklahoma" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={132} teamName="Oklahoma"/>} /> 
          <Route path="/SEC/Ole Miss" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={135} teamName="Ole Miss"/>} /> 
          <Route path="/SEC/South Carolina" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={154} teamName="South Carolina"/>} /> 
          <Route path="/SEC/Tennessee" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={172} teamName="Tennessee"/>} /> 
          <Route path="/SEC/Texas" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={175} teamName="Texas"/>} /> 
          <Route path="/SEC/Texas%20A%26M" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={176} teamName="Texas%20A%26M"/>} /> 
          <Route path="/SEC/Vanderbilt" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={198} teamName="Vanderbilt"/>} /> 
          <Route path="/B1G/Illinois" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={70} teamName="Illinois"/>} /> 
          <Route path="/B1G/Indiana" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={73} teamName="Indiana"/>} /> 
          <Route path="/B1G/Iowa" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={75} teamName="Iowa"/>} /> 
          <Route path="/B1G/Maryland" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={94} teamName="Maryland"/>} /> 
          <Route path="/B1G/Michigan" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={101} teamName="Michigan"/>} /> 
          <Route path="/B1G/Michigan State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={102} teamName="Michigan State"/>} /> 
          <Route path="/B1G/Minnesota" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={104} teamName="Minnesota"/>} /> 
          <Route path="/B1G/Nebraska" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={112} teamName="Nebraska"/>} /> 
          <Route path="/B1G/Northwestern" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={127} teamName="Northwestern"/>} /> 
          <Route path="/B1G/Ohio State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={131} teamName="Ohio State"/>} /> 
          <Route path="/B1G/Oregon" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={136} teamName="Oregon"/>} /> 
          <Route path="/B1G/Penn State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={138} teamName="Penn State"/>} /> 
          <Route path="/B1G/Purdue" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={142} teamName="Purdue"/>} /> 
          <Route path="/B1G/Rutgers" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={146} teamName="Rutgers"/>} /> 
          <Route path="/B1G/USC" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={162} teamName="USC"/>} /> 
          <Route path="/B1G/UCLA" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={189} teamName="UCLA"/>} /> 
          <Route path="/B1G/Washington" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={205} teamName="Washington"/>} /> 
          <Route path="/B1G/Wisconsin" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={214} teamName="Wisconsin"/>} /> 
          <Route path="/ACC/Boston College" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={22} teamName="Boston College"/>} /> 
          <Route path="/ACC/California" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={26} teamName="California"/>} /> 
          <Route path="/ACC/Clemson" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={35} teamName="Clemson"/>} /> 
          <Route path="/ACC/Duke" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={41} teamName="Duke"/>} /> 
          <Route path="/ACC/Florida State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={54} teamName="Florida State"/>} /> 
          <Route path="/ACC/Georgia Tech" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={61} teamName="Georgia Tech"/>} /> 
          <Route path="/ACC/Louisville" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={90} teamName="Louisville"/>} /> 
          <Route path="/ACC/Miami" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={99} teamName="Miami"/>} /> 
          <Route path="/ACC/North Carolina" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={118} teamName="North Carolina"/>} /> 
          <Route path="/ACC/NC State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={121} teamName="NC State"/>} /> 
          <Route path="/ACC/Pittsburgh" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={139} teamName="Pittsburgh"/>} /> 
          <Route path="/ACC/SMU" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={152} teamName="SMU"/>} /> 
          <Route path="/ACC/Stanford" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={166} teamName="Stanford"/>} /> 
          <Route path="/ACC/Syracuse" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={169} teamName="Syracuse"/>} /> 
          <Route path="/ACC/Virginia" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={200} teamName="Virginia"/>} /> 
          <Route path="/ACC/Virginia Tech" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={201} teamName="Virginia Tech"/>} /> 
          <Route path="/ACC/Wake Forest" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={204} teamName="Wake Forest"/>} /> 
          <Route path="/XII/Arizona" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={10} teamName="Arizona"/>} /> 
          <Route path="/XII/Arizona State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={11} teamName="Arizona State"/>} /> 
          <Route path="/XII/Baylor" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={19} teamName="Baylor"/>} /> 
          <Route path="/XII/BYU" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={25} teamName="BYU"/>} /> 
          <Route path="/XII/Cincinnati" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={34} teamName="Cincinnati"/>} /> 
          <Route path="/XII/Colorado" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={38} teamName="Colorado"/>} /> 
          <Route path="/XII/Houston" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={65} teamName="Houston"/>} /> 
          <Route path="/XII/Iowa State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={76} teamName="Iowa State"/>} /> 
          <Route path="/XII/Kansas" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={79} teamName="Kansas"/>} /> 
          <Route path="/XII/Kansas State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={80} teamName="Kansas State"/>} /> 
          <Route path="/XII/Oklahoma State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={133} teamName="Oklahoma State"/>} /> 
          <Route path="/XII/TCU" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={170} teamName="TCU"/>} /> 
          <Route path="/XII/Texas Tech" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={179} teamName="Texas Tech"/>} /> 
          <Route path="/XII/UCF" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={188} teamName="UCF"/>} /> 
          <Route path="/XII/Utah" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={194} teamName="Utah"/>} /> 
          <Route path="/XII/West Virginia" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={208} teamName="West Virginia"/>} /> 
          <Route path="/AAC/Army" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={15} teamName="Army"/>} /> 
          <Route path="/AAC/Charlotte" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={32} teamName="Charlotte"/>} /> 
          <Route path="/AAC/East Carolina" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={43} teamName="East Carolina"/>} /> 
          <Route path="/AAC/FAU" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={51} teamName="FAU"/>} /> 
          <Route path="/AAC/Memphis" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={97} teamName="Memphis"/>} /> 
          <Route path="/AAC/Navy" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={111} teamName="Navy"/>} /> 
          <Route path="/AAC/North Texas" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={123} teamName="North Texas"/>} /> 
          <Route path="/AAC/Rice" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={144} teamName="Rice"/>} /> 
          <Route path="/AAC/South Florida" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={158} teamName="South Florida"/>} /> 
          <Route path="/AAC/Temple" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={171} teamName="Temple"/>} /> 
          <Route path="/AAC/Tulane" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={184} teamName="Tulane"/>} /> 
          <Route path="/AAC/Tulsa" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={185} teamName="Tulsa"/>} /> 
          <Route path="/AAC/UAB" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={186} teamName="UAB"/>} /> 
          <Route path="/AAC/UT San Antonio" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={197} teamName="UT San Antonio"/>} /> 
          <Route path="/AAC/UTSA" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={197} teamName="UT San Antonio"/>} /> 
          <Route path="/CUSA/FIU" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={52} teamName="FIU"/>} /> 
          <Route path="/CUSA/Kennesaw State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={81} teamName="Kennesaw State"/>} /> 
          <Route path="/CUSA/Liberty" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={87} teamName="Liberty"/>} /> 
          <Route path="/CUSA/Louisiana Tech" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={89} teamName="Louisiana Tech"/>} /> 
          <Route path="/CUSA/Middle Tennessee" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={103} teamName="Middle Tennessee"/>} /> 
          <Route path="/CUSA/New Mexico State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={116} teamName="New Mexico State"/>} /> 
          <Route path="/CUSA/UTEP" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={196} teamName="UTEP"/>} /> 
          <Route path="/CUSA/Western Kentucky" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={211} teamName="Western Kentucky"/>} /> 
          <Route path="/CUSA/Sam Houston State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={217} teamName="Sam Houston State"/>} /> 
          <Route path="/CUSA/Jacksonville State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={234} teamName="Jacksonville State"/>} /> 
          <Route path="/MWC/Air Force" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={2} teamName="Air Force"/>} /> 
          <Route path="/MWC/Boise State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={21} teamName="Boise State"/>} /> 
          <Route path="/MWC/Colorado State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={39} teamName="Colorado State"/>} /> 
          <Route path="/MWC/Fresno State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={56} teamName="Fresno State"/>} /> 
          <Route path="/MWC/Hawai'i" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={63} teamName="Hawai'i"/>} /> 
          <Route path="/MWC/Nevada" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={113} teamName="Nevada"/>} /> 
          <Route path="/MWC/New Mexico" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={115} teamName="New Mexico"/>} /> 
          <Route path="/MWC/San Diego State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={149} teamName="San Diego State"/>} /> 
          <Route path="/MWC/San Jose State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={150} teamName="San Jose State"/>} /> 
          <Route path="/MWC/UNLV" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={192} teamName="UNLV"/>} /> 
          <Route path="/MWC/Utah State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={195} teamName="Utah State"/>} /> 
          <Route path="/MWC/Wyoming" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={215} teamName="Wyoming"/>} /> 
          <Route path="/SUN/Appalachian State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={9} teamName="Appalachian State"/>} /> 
          <Route path="/SUN/Arkansas State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={14} teamName="Arkansas State"/>} /> 
          <Route path="/SUN/Coastal Carolina" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={36} teamName="Coastal Carolina"/>} /> 
          <Route path="/SUN/Georgia Southern" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={59} teamName="Georgia Southern"/>} /> 
          <Route path="/SUN/Georgia State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={60} teamName="Georgia State"/>} /> 
          <Route path="/SUN/James Madison" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={78} teamName="James Madison"/>} /> 
          <Route path="/SUN/Louisiana Monroe" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={88} teamName="Louisiana Monroe"/>} /> 
          <Route path="/SUN/Marshall" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={93} teamName="Marshall"/>} /> 
          <Route path="/SUN/Old Dominion" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={134} teamName="Old Dominion"/>} /> 
          <Route path="/SUN/South Alabama" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={153} teamName="South Alabama"/>} /> 
          <Route path="/SUN/Southern Miss" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={164} teamName="Southern Miss"/>} /> 
          <Route path="/SUN/Texas State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={178} teamName="Texas State"/>} /> 
          <Route path="/SUN/Troy" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={183} teamName="Troy"/>} /> 
          <Route path="/SUN/Louisiana" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={191} teamName="Louisiana"/>} /> 
          <Route path="/MAC/Akron" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={3} teamName="Akron"/>} /> 
          <Route path="/MAC/Ball State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={18} teamName="Ball State"/>} /> 
          <Route path="/MAC/Bowling Green" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={23} teamName="Bowling Green"/>} /> 
          <Route path="/MAC/Buffalo" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={24} teamName="Buffalo"/>} /> 
          <Route path="/MAC/Central Michigan" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={30} teamName="Central Michigan"/>} /> 
          <Route path="/MAC/Eastern Michigan" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={47} teamName="Eastern Michigan"/>} /> 
          <Route path="/MAC/Kent State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={82} teamName="Kent State"/>} /> 
          <Route path="/MAC/Miami (OH)" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={100} teamName="Miami (OH)"/>} /> 
          <Route path="/MAC/Northern Illinois" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={125} teamName="Northern Illinois"/>} /> 
          <Route path="/MAC/Ohio" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={130} teamName="Ohio"/>} /> 
          <Route path="/MAC/Toledo" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={181} teamName="Toledo"/>} /> 
          <Route path="/MAC/Western Michigan" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={212} teamName="Western Michigan"/>} /> 
          <Route path="/Independents/UMass" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="Independents" TID={95} teamName="UMass"/>} /> 
          <Route path="/Independents/Notre Dame" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="Independents" TID={129} teamName="Notre Dame"/>} /> 
          <Route path="/Independents/UConn" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="Independents" TID={190} teamName="UConn"/>} /> 
          <Route path="/Independents/Oregon State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="Independents" TID={137} teamName="Oregon State"/>} /> 
          <Route path="/Independents/Washington State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="Independents" TID={206} teamName="Washington State"/>} /> 


          <Route path="/Alabama" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={4} teamName="Alabama"/>} /> 
          <Route path="/Arkansas" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={12} teamName="Arkansas"/>} /> 
          <Route path="/Auburn" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={16} teamName="Auburn"/>} /> 
          <Route path="/Florida" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={53} teamName="Florida"/>} /> 
          <Route path="/Georgia" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={58} teamName="Georgia"/>} /> 
          <Route path="/Kentucky" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={83} teamName="Kentucky"/>} /> 
          <Route path="/LSU" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={91} teamName="LSU"/>} /> 
          <Route path="/Mississippi State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={105} teamName="Mississippi State"/>} /> 
          <Route path="/Missouri" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={106} teamName="Missouri"/>} /> 
          <Route path="/Mizzou" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={106} teamName="Missouri"/>} /> 
          <Route path="/Oklahoma" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={132} teamName="Oklahoma"/>} /> 
          <Route path="/Ole Miss" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={135} teamName="Ole Miss"/>} /> 
          <Route path="/South Carolina" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={154} teamName="South Carolina"/>} /> 
          <Route path="/Tennessee" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={172} teamName="Tennessee"/>} /> 
          <Route path="/Texas" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={175} teamName="Texas"/>} /> 
          <Route path="/Texas AM" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={176} teamName="Texas%20A%26M"/>} /> 
          <Route path="/Texas A_M" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={176} teamName="Texas%20A%26M"/>} /> 
          <Route path="/Vanderbilt" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SEC" TID={198} teamName="Vanderbilt"/>} /> 
          <Route path="/Illinois" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={70} teamName="Illinois"/>} /> 
          <Route path="/Indiana" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={73} teamName="Indiana"/>} /> 
          <Route path="/Iowa" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={75} teamName="Iowa"/>} /> 
          <Route path="/Maryland" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={94} teamName="Maryland"/>} /> 
          <Route path="/Michigan" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={101} teamName="Michigan"/>} /> 
          <Route path="/Michigan State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={102} teamName="Michigan State"/>} /> 
          <Route path="/Minnesota" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={104} teamName="Minnesota"/>} /> 
          <Route path="/Nebraska" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={112} teamName="Nebraska"/>} /> 
          <Route path="/Northwestern" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={127} teamName="Northwestern"/>} /> 
          <Route path="/Ohio State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={131} teamName="Ohio State"/>} /> 
          <Route path="/Oregon" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={136} teamName="Oregon"/>} /> 
          <Route path="/Penn State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={138} teamName="Penn State"/>} /> 
          <Route path="/Purdue" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={142} teamName="Purdue"/>} /> 
          <Route path="/Rutgers" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={146} teamName="Rutgers"/>} /> 
          <Route path="/USC" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={162} teamName="USC"/>} /> 
          <Route path="/UCLA" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={189} teamName="UCLA"/>} /> 
          <Route path="/Washington" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={205} teamName="Washington"/>} /> 
          <Route path="/Wisconsin" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="B1G" TID={214} teamName="Wisconsin"/>} /> 
          <Route path="/Boston College" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={22} teamName="Boston College"/>} /> 
          <Route path="/California" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={26} teamName="California"/>} /> 
          <Route path="/Clemson" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={35} teamName="Clemson"/>} /> 
          <Route path="/Duke" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={41} teamName="Duke"/>} /> 
          <Route path="/Florida State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={54} teamName="Florida State"/>} /> 
          <Route path="/Georgia Tech" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={61} teamName="Georgia Tech"/>} /> 
          <Route path="/Louisville" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={90} teamName="Louisville"/>} /> 
          <Route path="/Miami" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={99} teamName="Miami"/>} /> 
          <Route path="/North Carolina" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={118} teamName="North Carolina"/>} /> 
          <Route path="/NC State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={121} teamName="NC State"/>} /> 
          <Route path="/Pittsburgh" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={139} teamName="Pittsburgh"/>} /> 
          <Route path="/SMU" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={152} teamName="SMU"/>} /> 
          <Route path="/Stanford" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={166} teamName="Stanford"/>} /> 
          <Route path="/Syracuse" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={169} teamName="Syracuse"/>} /> 
          <Route path="/Virginia" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={200} teamName="Virginia"/>} /> 
          <Route path="/Virginia Tech" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={201} teamName="Virginia Tech"/>} /> 
          <Route path="/Wake Forest" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="ACC" TID={204} teamName="Wake Forest"/>} /> 
          <Route path="/Arizona" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={10} teamName="Arizona"/>} /> 
          <Route path="/Arizona State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={11} teamName="Arizona State"/>} /> 
          <Route path="/Baylor" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={19} teamName="Baylor"/>} /> 
          <Route path="/BYU" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={25} teamName="BYU"/>} /> 
          <Route path="/Cincinnati" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={34} teamName="Cincinnati"/>} /> 
          <Route path="/Colorado" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={38} teamName="Colorado"/>} /> 
          <Route path="/Houston" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={65} teamName="Houston"/>} /> 
          <Route path="/Iowa State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={76} teamName="Iowa State"/>} /> 
          <Route path="/Kansas" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={79} teamName="Kansas"/>} /> 
          <Route path="/Kansas State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={80} teamName="Kansas State"/>} /> 
          <Route path="/Oklahoma State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={133} teamName="Oklahoma State"/>} /> 
          <Route path="/TCU" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={170} teamName="TCU"/>} /> 
          <Route path="/Texas Tech" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={179} teamName="Texas Tech"/>} /> 
          <Route path="/UCF" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={188} teamName="UCF"/>} /> 
          <Route path="/Utah" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={194} teamName="Utah"/>} /> 
          <Route path="/West Virginia" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="XII" TID={208} teamName="West Virginia"/>} /> 
          <Route path="/Army" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={15} teamName="Army"/>} /> 
          <Route path="/Charlotte" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={32} teamName="Charlotte"/>} /> 
          <Route path="/East Carolina" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={43} teamName="East Carolina"/>} /> 
          <Route path="/FAU" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={51} teamName="FAU"/>} /> 
          <Route path="/Memphis" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={97} teamName="Memphis"/>} /> 
          <Route path="/Navy" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={111} teamName="Navy"/>} /> 
          <Route path="/North Texas" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={123} teamName="North Texas"/>} /> 
          <Route path="/Rice" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={144} teamName="Rice"/>} /> 
          <Route path="/South Florida" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={158} teamName="South Florida"/>} /> 
          <Route path="/Temple" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={171} teamName="Temple"/>} /> 
          <Route path="/Tulane" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={184} teamName="Tulane"/>} /> 
          <Route path="/Tulsa" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={185} teamName="Tulsa"/>} /> 
          <Route path="/UAB" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={186} teamName="UAB"/>} /> 
          <Route path="/UT San Antonio" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="AAC" TID={197} teamName="UT San Antonio"/>} /> 
          <Route path="/FIU" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={52} teamName="FIU"/>} /> 
          <Route path="/Kennesaw State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={81} teamName="Kennesaw State"/>} /> 
          <Route path="/Liberty" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={87} teamName="Liberty"/>} /> 
          <Route path="/Louisiana Tech" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={89} teamName="Louisiana Tech"/>} /> 
          <Route path="/Middle Tennessee" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={103} teamName="Middle Tennessee"/>} /> 
          <Route path="/New Mexico State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={116} teamName="New Mexico State"/>} /> 
          <Route path="/UTEP" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={196} teamName="UTEP"/>} /> 
          <Route path="/Western Kentucky" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={211} teamName="Western Kentucky"/>} /> 
          <Route path="/Sam Houston State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={217} teamName="Sam Houston State"/>} /> 
          <Route path="/Jacksonville State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="CUSA" TID={234} teamName="Jacksonville State"/>} /> 
          <Route path="/Air Force" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={2} teamName="Air Force"/>} /> 
          <Route path="/Boise State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={21} teamName="Boise State"/>} /> 
          <Route path="/Colorado State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={39} teamName="Colorado State"/>} /> 
          <Route path="/Fresno State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={56} teamName="Fresno State"/>} /> 
          <Route path="/Hawai'i" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={63} teamName="Hawai'i"/>} /> 
          <Route path="/Nevada" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={113} teamName="Nevada"/>} /> 
          <Route path="/New Mexico" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={115} teamName="New Mexico"/>} /> 
          <Route path="/San Diego State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={149} teamName="San Diego State"/>} /> 
          <Route path="/San Jose State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={150} teamName="San Jose State"/>} /> 
          <Route path="/UNLV" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={192} teamName="UNLV"/>} /> 
          <Route path="/Utah State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={195} teamName="Utah State"/>} /> 
          <Route path="/Wyoming" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MWC" TID={215} teamName="Wyoming"/>} /> 
          <Route path="/Appalachian State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={9} teamName="Appalachian State"/>} /> 
          <Route path="/Arkansas State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={14} teamName="Arkansas State"/>} /> 
          <Route path="/Coastal Carolina" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={36} teamName="Coastal Carolina"/>} /> 
          <Route path="/Georgia Southern" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={59} teamName="Georgia Southern"/>} /> 
          <Route path="/Georgia State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={60} teamName="Georgia State"/>} /> 
          <Route path="/James Madison" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={78} teamName="James Madison"/>} /> 
          <Route path="/Louisiana Monroe" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={88} teamName="Louisiana Monroe"/>} /> 
          <Route path="/Marshall" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={93} teamName="Marshall"/>} /> 
          <Route path="/Old Dominion" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={134} teamName="Old Dominion"/>} /> 
          <Route path="/South Alabama" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={153} teamName="South Alabama"/>} /> 
          <Route path="/Southern Miss" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={164} teamName="Southern Miss"/>} /> 
          <Route path="/Texas State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={178} teamName="Texas State"/>} /> 
          <Route path="/Troy" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={183} teamName="Troy"/>} /> 
          <Route path="/Louisiana" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="SUN" TID={191} teamName="Louisiana"/>} /> 
          <Route path="/Akron" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={3} teamName="Akron"/>} /> 
          <Route path="/Ball State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={18} teamName="Ball State"/>} /> 
          <Route path="/Bowling Green" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={23} teamName="Bowling Green"/>} /> 
          <Route path="/Buffalo" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={24} teamName="Buffalo"/>} /> 
          <Route path="/Central Michigan" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={30} teamName="Central Michigan"/>} /> 
          <Route path="/Eastern Michigan" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={47} teamName="Eastern Michigan"/>} /> 
          <Route path="/Kent State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={82} teamName="Kent State"/>} /> 
          <Route path="/Miami (OH)" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={100} teamName="Miami (OH)"/>} /> 
          <Route path="/Northern Illinois" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={125} teamName="Northern Illinois"/>} /> 
          <Route path="/Ohio" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={130} teamName="Ohio"/>} /> 
          <Route path="/Toledo" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={181} teamName="Toledo"/>} /> 
          <Route path="/Western Michigan" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="MAC" TID={212} teamName="Western Michigan"/>} /> 
          <Route path="/UMass" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="Independents" TID={95} teamName="UMass"/>} /> 
          <Route path="/Notre Dame" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="Independents" TID={129} teamName="Notre Dame"/>} /> 
          <Route path="/UConn" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="Independents" TID={190} teamName="UConn"/>} /> 
          <Route path="/Oregon State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="Independents" TID={137} teamName="Oregon State"/>} /> 
          <Route path="/Washington State" element={<MainTeam rev={rev} mobile={isMobile} currYear={currYear} currWeek={currWeek} currDay={currDay} activeMenu={activeMenu} setActive={setActive}  GID={GID}  setGID={setGID} confName="Independents" TID={206} teamName="Washington State"/>} /> 

        </Routes>


    </Router>
    </div>
  );
}

              

export default App;
