import { useNavigate } from "react-router-dom"
import { FaCaretLeft } from "react-icons/fa6";

import * as Images from '../images';

export default function ConfBar({placement, layout, setGID, rev, mobile, toggleLeft})
{
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Go back in history
    }    

    const pickConf = (val) => {
        console.log ("pickConf" + val)
        navigate("/"+val)
        setGID(0)
        // toggleLeft();
    }
    let dummy = {layout}+" blackbk";

    return (
    <div className="graybk">
        {/* <div className={dummy}><h3 className='white'>{rev} {mobile?"mob":"dsk"}</h3></div> */}
        {/* <button className="btn-item whitebk" onClick={() =>  toggleLeft()} ><FaCaretLeft size={36}/></button> */}
        <button className="graybk"></button>
        <button className="graybk"></button>
        <button className="image-button graybk border-black "><img onClick={() =>  pickConf("SEC")} src={Images.sec} alt="Button Image"/></button>
        <button className="image-button graybk border-black "><img onClick={() =>  pickConf("B1G")} src={Images.b1g} alt="Button Image"/></button>
        <button className="image-button graybk border-black "><img onClick={() =>  pickConf("XII")} src={Images.xii} alt="Button Image"/></button>
        <button className="image-button graybk border-black "><img onClick={() =>  pickConf("ACC")} src={Images.acc} alt="Button Image"/></button>
        <button className="graybk"></button>
        <button className="image-button graybk border-black "><img onClick={() =>  pickConf("Independents")} src={Images.indy} alt="Button Image"/></button>
        <button className="graybk"></button>
        <button className="image-button graybk border-black "><img onClick={() =>  pickConf("AAC")} src={Images.aac} alt="Button Image"/></button>
        <button className="image-button graybk border-black "><img onClick={() =>  pickConf("MWC")} src={Images.mwc} alt="Button Image"/></button>
        <button className="image-button graybk border-black "><img onClick={() =>  pickConf("SUN")} src={Images.sun} alt="Button Image"/></button>
        <button className="image-button graybk border-black "><img onClick={() =>  pickConf("CUSA")} src={Images.cusa} alt="Button Image"/></button>
        <button className="image-button graybk border-black "><img onClick={() =>  pickConf("MAC")} src={Images.mac} alt="Button Image"/></button>
        <button className="graybk"></button>
        <button className="graybk"></button>
        <button className="graybk"></button>
        <button className="graybk"></button>
        <button className="graybk"></button>
        <button className="graybk"></button>

    </div>
    )   
}