import MainMobile from './MainMobile'
import MainDesktop from './MainDesktop'

import {RetrieveConfTeams} from "../dungeonapi"


import {LoadConfTeams} from "../data"

import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

    

export default function MainConf({ rev, mobile, confID, confName, activeMenu, setActive, GID, setGID, currYear, currWeek, currDay})
{
    const [teamName, setTeamName] = useState("TEAM");

    const [teamsRetrieved, setTeamsRetrieved] = useState(false);
    
    const [scoresLevel, setScoresLevel] = useState(1);
                  
    const navigate = useNavigate();

    const justSet = (val) => {
        setActive(val)
    }
    
    const setAndMoveUp = (nm, val) => {
          navigate("/"+nm)
          setActive(val)
    }


useEffect(() => { 

    RetrieveConfTeams (confID, LoadConfTeams, setTeamsRetrieved);	

}, [confID]);    
     

return (
    <>
    {mobile?
        <MainMobile 
                    parent         = {"MainFBS"}
                    currYear       = {currYear}
                    currWeek       = {currWeek}
                    currDay       =  {currDay}
                    activeMenu     = {activeMenu}
                    setActive      = {setActive}
                    setAndMoveUp   = {setAndMoveUp}
                    setGID         = {setGID}
                    GID            = {GID}
                    initStatLevel  = {0} 
                    confID         = {confID}
                    TID            = {0}
                    confLabel      = {confName}
                    teamLabel      = {"TEAM"}
                    rev={rev}
                    mobile={mobile} 
                    />

    :
       <MainDesktop 
                    parent         = {"MainFBS"}
                    currYear       = {currYear}
                    currWeek       = {currWeek}
                    currDay       =  {currDay}
                    activeMenu     = {activeMenu}
                    setActive      = {setActive}
                    setGID         = {setGID}
                    GID            = {GID}
                    initStatLevel  = {0} 
                    confID         = {confID}
                    TID            = {0}
                    confLabel      = {confName}
                    teamLabel      = {"TEAM"}
                    rev={rev}
                    mobile={mobile} 
               />

    }

  </>
  )
}