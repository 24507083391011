import { GetThisGame } from "../data"
import React, { useState, useEffect } from "react"
import * as GlobalData from '../data';


export default function LivePlayers({GID})
{
    const [avgData, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [error, setError] = useState(null);
    const [tmSelected, setTmSelected] = useState('');
    const [tidSelected, setTidSelected] = useState(0);
    const [awayTeam, setAwayTeam] = useState(0);
    const [awayTID, setAwayTID] = useState(0);
    const [homeTeam, setHomeTeam] = useState(0);
    const [homeTID, setHomeTID] = useState(0);


    const saveTeam = (tid,name)  => {
        setTidSelected(tid);
        setTmSelected(name);
    }

    useEffect(() => {
        // Function to fetch data
        const fetchData = async () => {
          try {
              const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_GAME_INFO&GID="+GID;
              console.log (url)
              const response = await fetch(url, {
                method: 'GET', // or 'POST', 'PUT', etc.
                headers: {
                    'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                    'Content-Type': 'application/json'
                }
            });
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              const results = await response.json();
              {
                  setAwayTeam( results.AwayTeam);
                  setAwayTID( results.AwayTID);
                  setHomeTeam( results.HomeTeam);
                  setHomeTID( results.HomeTID);
                  saveTeam(results.HomeTID, results.HomeTeam)
                }
          } 
          catch (error) {
            setError(error.message);
          } 
          finally {
            setLoadingInfo(false);
          }
        };
    
        fetchData();
    
      }, [GID]);  
  
    useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
            const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_STAT_LEADERS_BY_TEAM&TID="+tidSelected;
            console.log (url)
            const response = await fetch(url, {
                method: 'GET', // or 'POST', 'PUT', etc.
                headers: {
                    'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const results = await response.json();
            {
                setData( results);
            }
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      fetchData();
  
    }, [tidSelected]);  

    function AvgTable({label, avg})
    {
        const rows = avg.map((item)=>{
            const today = TodaysStats(label, item.CommonName);
            const color = today > item.PerGame ? "bluebk" : "greenbk";
            const color2 = today < item.PerGame*.75 ? "redbk" : color;
            return (
                <tr className="small-row" key={item.id}>
                  <td>{item.CommonName}</td>
                  <td>{item.PerGame}</td>
                  <td className={color2}>{today}</td>
                </tr>
             );
            });

        return (
            <>
            <h3 className='no-margin'>{label}</h3>
            <table>
            <tbody>
                <tr className="small-row"><th>Player</th><th>Average</th><th>Today</th></tr>
                {rows}
            </tbody>
            </table>
            </>
        )
    }
    
    const gamePassingStats = [ { Name: "Jacurri Brown", Yards: 50},
                               { Name: "EJ Colson", Yards: 1}];
    const gameRushingStats = [ {Name: "RJ Harvey", Yards: 128},
                               {Name: "Jacurri Brown", Yards: 60}];

    const gameReceivingStats = [ {Name: "Kobe Hudson", Yards: 20},
                                {Name: "RJ Harvey", Yards: 30},
                                {Name: "Randy Pittman Jr.", Yards: 15}];

    function TodaysStats(cat, name)
    {
        if (cat == "Passing")
            for (let i=0; i<gamePassingStats.length; i++)
            {
                if (gamePassingStats[i].Name == name)
                    return gamePassingStats[i].Yards;
            }

        else if (cat == "Rushing")
            for (let i=0; i<gameRushingStats.length; i++)
            {
                if (gameRushingStats[i].Name == name)
                    return gameRushingStats[i].Yards;
            }

        else if (cat == "Receiving")
            for (let i=0; i<gameReceivingStats.length; i++)
            {
                if (gameReceivingStats[i].Name == name)
                    return gameReceivingStats[i].Yards;
            }

        return 0;
    } 
    
    
    if (loading) 
        return (<h2>Loading...</h2>);
    else if (error) 
        return (<h2>Error: {error}</h2>);
    else

    return (
        <>
        <div className="box btn-container-desktop">
            <button className={"noButton"} ></button>
            <button className={`${tmSelected===awayTeam? "ltblueButtonInv" : "whiteButtonInv"}`} 
                              onClick={() =>  saveTeam(awayTID, awayTeam)} >{awayTeam}</button>
            <button className={`${tmSelected===homeTeam? "ltblueButtonInv" : "whiteButtonInv"}`} 
                              onClick={() =>  saveTeam(homeTID, homeTeam)} >{homeTeam}</button>
            <button className={"noButton"} ></button>
        </div>
        <div className="full-width scroll"  style={{height: '50%'}}>
                <AvgTable label="Passing" avg={avgData.Passing} />
                <AvgTable label="Rushing" avg={avgData.Rushing} />
                <AvgTable label="Receiving" avg={avgData.Receiving} />
        </div>
        </>
    )
}

