import { MenuChoices } from '../MenuChoices';
import About from './About';
import Rankings from "./RankingsView";
import ScoresComplete from "./ScoresComplete";
import TeamsOnBye from "./TeamsOnBye";
import StandingsNew from "./StandingsNew";
import Roster from "./RosterView";
import DepthChartView from "./DepthChartView";
import MenuTeam from "./MenuTeam";
import TeamInfo from "./TeamInfo";
import HomeTeam from "./HomeTeam";
import HomeTeamNext from "./HomeTeamNext";
import TvSchedule from "./TvScheduleView";
import ScheduleView from './ScheduleView';
import Statistics2 from "./StatisticsView2";
import DriveGraphic from './DriveGraphic';
import HomeStretch from './HomeStretch';
import HomeStretchTeam from './HomeStretchTeam';
import TeamColors from './TeamColors';

import StatsSelector from "./StatsSelector";
import GameSelector from './GameSelector';
import WeekSelector from './WeekSelector';
import ScoresSelector from "./ScoresSelector";
import RosterSelector from "./RosterSelector";
import StretchSelector from './StretchSelector';

import LiveScores from './LiveScores';
import LiveGame from './LiveGame';
import LiveDrives from './LiveDrives';
import LivePlays from './LivePlays';
import LiveTeams from './LiveTeams';
import LivePlayers from './LivePlayers';

import React, { useState, useEffect } from "react"
import PossessionInfo from './PossessionInfo';
import * as GlobalData from '../data';


export default function HandleCommon({parent, mobile, rev, currYear, currWeek, currDay, chosenWeek, setChosenWeek, updaterTime, 
  activeMenu, setActive, popupState, setPopup, PID, setPID, GID, setGID, scoresLevel, setScoresLevel, confID, confLabel, TID, teamLabel})
{

  const [statLevel, setStatLevel] = useState((TID>0 ? 2 : (confID>0? 1 : 0)));
  const [stretchLevel, setStretchLevel] = useState((TID>0 ? 2 : (confID>0? 1 : 0)));
  const [rosterLevel, setRosterLevel] = useState(0);
  const [possInfo, setPossInfo] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const [liveGame, setLiveGame] = useState({
    gameInfo: null,
    possInfo: null,
    lastPlay: null,
  });

  const updateGameInfo = (gameInfo) => {
    setLiveGame((liveGame) => ({
      ...liveGame,
      gameInfo: gameInfo,
    }));
  };
  const updatePossInfo = (possInfo) => {
    setLiveGame((liveGame) => ({
      ...liveGame,
      possInfo: possInfo,
    }));
  };
  const updateLastPlay = (lastPlay) => {
    setLiveGame((liveGame) => ({
      ...liveGame,
      lastPlay: lastPlay,
    }));
  };


  

    useEffect(() => {
            // Function to fetch data
        const fetchData = async () => {
              try {
                const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_POSS_INFO&GID="+GID;
                const response = await fetch(url, {
                    method: 'GET', // or 'POST', 'PUT', etc.
                    headers: {
                        'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                        'Content-Type': 'application/json'
                    }
                });
            
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setLiveGame({
                    gameInfo: result.GameInfo,
                    possInfo: result.PossInfo,
                    lastPlay: result.LastPlay,
                });

                console.log ("GID "+GID+" "+result.GameInfo.AwayScore+"-"+result.GameInfo.HomeScore+" "+result.GameInfo.Status+" "+result.PossInfo.clock)
              } 
              catch (error) {
                setError(error.message);
              } 
              finally {
                setLoading(false);
              }
            };
        
            if (GID > 0) fetchData();
        
        }, [GID,updaterTime]);  
          
    // console.log (parent+" HandleCommon: Parent="+parent+ " ActiveMenu="+activeMenu + " ConfID="+confID + " TID="+TID  + " GID="+GID)
if (GID > 0 && loading) 
    return (<h2>loading data...</h2>)
else if (error) 
return (<h2>error loading data...</h2>)
else
{
  return (
        <>
            {activeMenu === MenuChoices.ABOUT ?
            <>
            <About currYear={currYear} mobile={mobile?"mobile":"desktop"} rev={rev}/>
            </>
            :
            <></>
            }

            {activeMenu===MenuChoices.HOME_STRETCH  && confID === 0 ? 
            <>
                    {/* <StretchSelector 
                            mobile={mobile}
                            stretchLevel={stretchLevel} 
                            setStretchLevel={changeStretchLevel} 
                            confLabel={confLabel}
                            teamLabel={teamLabel}
                            /> */}

                    <div className="full-width scroll" style={{height: '90%'}}>
                        <h2 className=' '>SEC</h2>
                        <HomeStretch currWeek={currWeek} confID={1} confName={"SEC"} />
                        <h2 className=' '>Big Ten</h2>
                        <HomeStretch currWeek={currWeek} confID={2} confName={"Big Ten"} />
                        <h2 className=' '>Big 12</h2>
                        <HomeStretch currWeek={currWeek} confID={5} confName={"Big 12"} />
                        <h2 className=' '>ACC</h2>
                        <HomeStretch currWeek={currWeek} confID={3} confName={"ACC"} />
                        <h2 className=' '>American</h2>
                        <HomeStretch currWeek={currWeek} confID={6} confName={"American"} />
                        <h2 className=' '>CUSA</h2>
                        <HomeStretch currWeek={currWeek} confID={7} confName={"CUSA"} />
                        <h2 className=' '>Mountain West</h2>
                        <HomeStretch currWeek={currWeek} confID={8} confName={"Mountain West"} />
                        <h2 className=' '>SUNBELT</h2>
                        <HomeStretch currWeek={currWeek} confID={9} confName={"SUNBELT"} />
                        <h2 className=' '>MAC</h2>
                        <HomeStretch currWeek={currWeek} confID={10} confName={"MAC"} />
                        <h2 className=' '>Independents</h2>
                        <HomeStretch currWeek={currWeek} confID={11} confName={"Independents"} />
                    </div> 
            </>
            : <></> }

            {/* {activeMenu===MenuChoices.HOME_STRETCH  && confID > 0 ? 
            <>
                    <StretchSelector 
                            mobile={mobile}
                            stretchLevel={stretchLevel} 
                            setStretchLevel={changeStretchLevel} 
                            confLabel={confLabel}
                            teamLabel={teamLabel}
                            />
                <div className={"full-width near-full-height scroll"}>
                        <HomeStretchTeam 
                               currWeek={currWeek}
                               TID={TID}
                              />
                </div>
            </>
            : <></> } */}

            {activeMenu===MenuChoices.HOME_STRETCH  && confID > 0 ? 
            <>
                    {/* <StretchSelector 
                            mobile={mobile}
                            stretchLevel={stretchLevel} 
                            setStretchLevel={changeStretchLevel} 
                            confLabel={confLabel}
                            teamLabel={teamLabel}
                            /> */}

                <div className={"full-width near-full-height scroll"}>
                        <HomeStretch 
                               currYear={currYear}
                               currWeek={currWeek}
                               confID={confID}
                              />
                </div>
            </>
            : <></> }
            {activeMenu===MenuChoices.RANKINGS ? 
            <>
                <WeekSelector chosenWeek={chosenWeek} setChosenWeek={setChosenWeek} />
                <div className="full-width scroll" style={{height: '80%'}}>
                    <Rankings 
                                label="AP POLL - WEEK "
                                mobile={mobile}
                                currYear={currYear}
                                chosenWeek={chosenWeek}
                                setActive={setActive}
                                setPopup={setPopup}
                                />
                </div>
            </>
            : <></>}

                
            {activeMenu===MenuChoices.LIVE_SCORES ? 
            <>
                <ScoresSelector  mobile={mobile} scoresLevel={scoresLevel} setScoresLevel={setScoresLevel} confLabel={confLabel}/>

                <div className="full-width scroll" style={{height: '80%'}}>

                    <LiveScores  
                        label="FBS" 
                        confID={confID} 
                        confLabel={confLabel}
                        currWeek={currWeek} 
                        currDay= {currDay}
                        scoresLevel={scoresLevel}
                        activeMenu={activeMenu}
                        setActive={setActive}
                        setPopup={setPopup}
                        setGID={setGID}
                        updaterTime={updaterTime}
                        possInfo={PossessionInfo}
                        />
                    <TeamsOnBye week={currWeek} confID={confID} scoresLevel={scoresLevel} />
                </div>
            </>
            : <></> }


            {activeMenu===MenuChoices.PREV_WEEK  ? 
                <>
                    <WeekSelector chosenWeek={chosenWeek} setChosenWeek={setChosenWeek} />

                    <ScoresSelector  scoresLevel={scoresLevel} setScoresLevel={setScoresLevel} confLabel={confLabel}/>

                    <div className="full-width scroll" style={{height: '75%'}}>

                        <ScoresComplete  
                                label={"FBS"}
                                chosenWeek={chosenWeek}
                                confID={confID} 
                                confLabel={'conf'}
                                activeMenu={activeMenu}
                                setActive={setActive}
                                setPopup={setPopup}
                                setGID={setGID}
                                scoresLevel={scoresLevel}
                                />
                        <TeamsOnBye week={chosenWeek} confID={confID} scoresLevel={scoresLevel} />
                    </div>
                </>
            : <></> }
                    
            {activeMenu===MenuChoices.STATS ?
            <>

                <StatsSelector 
                            mobile={mobile}
                            statLevel={statLevel} 
                            setStatLevel={setStatLevel} 
                            confLabel={confLabel}
                            teamLabel={teamLabel}
                            />
                <div className={"full-width three-quarter-height scroll"}>

                    <Statistics2 
                        TID    ={TID}
                        confID = {confID}
                        confLabel = {confLabel} 
                        teamLabel={teamLabel}
                        statLevel={statLevel} 
                        setStatLevel={setStatLevel} 
                        />
                </div>
            </>
            : <></> } 

            {activeMenu===MenuChoices.TV ? 
                <TvSchedule 
                             placement={"full-width scroll"}  
                             />
            : <></> }        
                         
            {activeMenu===MenuChoices.SCHEDULE ? 
            <>
                {TID > 0 ?
                <>
  
                <div className={"full-width near-full-height scroll"}>
                        <ScheduleView 
                               setActive={setActive}
                               setPopup={setPopup}
                               setGID={setGID}
                               TID={TID}
                               teamLabel={teamLabel}
                               currYear={currYear}
                               currWeek={currWeek}
                              />
                </div>
                </>
                :
                  <h2>NO TEAM SELECTED</h2>
                }
              </>
            : <></> }

            {activeMenu===MenuChoices.STANDINGS && confID === 0 ? 
                <>
                    <div className="full-width scroll" style={{height: '90%'}}>
                        <h2 className=' '>SEC</h2>
                        <StandingsNew placement={" "} confID={1} confName={"SEC"} />
                        <h2 className=' '>Big Ten</h2>
                        <StandingsNew placement={" "} confID={2} confName={"Big Ten"} />
                        <h2 className=' '>Big 12</h2>
                        <StandingsNew placement={" "} confID={5} confName={"Big 12"} />
                        <h2 className=' '>ACC</h2>
                        <StandingsNew placement={" "} confID={3} confName={"ACC"} />
                        <h2 className=' '>American</h2>
                        <StandingsNew placement={" "} confID={6} confName={"American"} />
                        <h2 className=' '>CUSA</h2>
                        <StandingsNew placement={" "} confID={7} confName={"CUSA"} />
                        <h2 className=' '>Mountain West</h2>
                        <StandingsNew placement={" "} confID={8} confName={"Mountain West"} />
                        <h2 className=' '>SUNBELT</h2>
                        <StandingsNew placement={" "} confID={9} confName={"SUNBELT"} />
                        <h2 className=' '>MAC</h2>
                        <StandingsNew placement={" "} confID={10} confName={"MAC"} />
                        <h2 className=' '>Independents</h2>
                        <StandingsNew placement={" "} confID={11} confName={"Independents"} />
                    </div> 
                </>
                : <></> }

            {activeMenu===MenuChoices.STANDINGS && confID !== 0 ? 
                <>
                    <div className="full-width scroll" style={{height: '90%'}}>
                        <StandingsNew placement={" "} 
                                confID={confID} 
                                confName={confLabel} //GlobalData.ConfNames[confID]}
                        />
                     </div>
                </>
                
            : <></> }

            {activeMenu===MenuChoices.ROSTER ? 
                  <>
                  {TID > 0 ?
                  <>
                <RosterSelector mobile={mobile} rosterLevel={rosterLevel} setRosterLevel={setRosterLevel} />

                <div className="full-width scroll" style={{height: '80%'}}>
                    <Roster 
                                    placement={""}  
                                    label="ROSTER"
                                    rosterLevel={rosterLevel}
                                    setRosterLevel={setRosterLevel}
                                    TID={TID}
                                    teamLabel={teamLabel}
                                    setPID={setPID}
                                    setPopup={setPopup}
                                    />
                </div>
                </>
                :
                  <h2>NO TEAM SELECTED</h2>
                }
                </>
              
            : <></> }
            {activeMenu===MenuChoices.DEPTH ? 
                <>
                  {TID > 0 ?
                  <>

                <div className={"full-width near-full-height scroll"}>
                    <DepthChartView 
                                    placement={" "} 
                                    TID={TID} 
                                    teamLabel={teamLabel}
                                    setPID={setPID}
                                    setPopup={setPopup}
                                    />
                </div>
                </>
                :
                  <h2>NO TEAM SELECTED</h2>
                }
                </>
            : <></> }

            {activeMenu===MenuChoices.HOME ? 
            <>
            {TID > 0 ?
            <>
                <div className="full-width scroll" style={{height: '88%'}}>
                    <TeamInfo TID={TID} />

                    <HomeTeam 
                        placement={"full-width three-quarter-height scroll charbk"} 
                        confName={confLabel}
                        teamName={teamLabel}
                        TID={TID}
                        setActive={setActive}
                        setGID={setGID}
                        setPopup={setPopup}
                        updaterTime={updaterTime}
                        currWeek={currWeek}
                        />

                    <HomeTeamNext 
                        placement={"full-width three-quarter-height scroll charbk"} 
                        confName={confLabel}
                        teamName={teamLabel}
                        TID={TID}
                        setActive={setActive}
                        setGID={setGID}
                        setPopup={setPopup}
                        updaterTime={updaterTime}
                        currWeek={currWeek}
                        />
            </div>
            </>
            :
              <h2>NO TEAM SELECTED</h2>
            }
            </>
            : <></> }

            {activeMenu===MenuChoices.LIVE_GAME ?
            <>
                <div className='fixed-height-100 full-width'>
                    <WeekSelector chosenWeek={chosenWeek} setChosenWeek={setChosenWeek} />
                    <br></br>
                    <GameSelector currWeek={chosenWeek} GID={GID} setGID={setGID} />
                </div>
                {GID > 0 && liveGame.gameInfo != null && liveGame.possInfo != null && liveGame.lastPlay != null ?
                    <>
                    <LiveGame  GID={GID} 
                               gameInfo={liveGame.gameInfo}
                               possInfo={liveGame.possInfo} 
                               lastPlay={liveGame.lastPlay} 
                               updaterTime={updaterTime}/>
                    </>
                :
                <h2>NO GAME SELECTED</h2>
                }
            </>
            : <></> }
            {activeMenu===MenuChoices.LIVE_DRIVES ? 
            <>
                <div className='fixed-height-100 full-width'>
                    <WeekSelector chosenWeek={chosenWeek} setChosenWeek={setChosenWeek} />
                    <br></br>
                    <GameSelector currWeek={chosenWeek} GID={GID} setGID={setGID} />
                </div>
                {GID > 0 ?
                    <>
                    <LiveDrives  GID={GID} driveCount={0}/>
                    </>
                :
                <h2>NO GAME SELECTED</h2>
                }
            </>
            : <></> }


            {activeMenu===MenuChoices.LIVE_PLAYS ? 
            <>
                <div className='fixed-height-100 full-width'>
                    <WeekSelector chosenWeek={chosenWeek} setChosenWeek={setChosenWeek} />
                    <br></br>
                    <GameSelector currWeek={chosenWeek} GID={GID} setGID={setGID} />
                </div>

                {GID > 0 ?
                    <>
                    <LivePlays GID={GID} playCount={0}/>
                    </>
                :
                <h2>NO GAME SELECTED</h2>
                }
            </>
            : <></> }


            {activeMenu===MenuChoices.LIVE_TEAMS ? 
            <>
                <div className='fixed-height-100 full-width'>
                    <WeekSelector chosenWeek={chosenWeek} setChosenWeek={setChosenWeek} />
                    <br></br>
                    <GameSelector currWeek={chosenWeek} GID={GID} setGID={setGID} />
                </div>
                {GID > 0 ?
                    <>
                    <LiveTeams  currWeek={chosenWeek} GID={GID} />
                    </>
                :
                <h2>NO GAME SELECTED</h2>
                }
            </>
            : <></> }


            {activeMenu===MenuChoices.LIVE_PLAYERS ? 
            <>
                <div className='fixed-height-100 full-width'>
                    <WeekSelector chosenWeek={chosenWeek} setChosenWeek={setChosenWeek} />
                    <br></br>
                    <GameSelector currWeek={chosenWeek} GID={GID} setGID={setGID} />
                </div>

                {GID > 0 ?
                    <>
                    <LivePlayers  currWeek={chosenWeek} GID={GID} />
                    </>
                :
                <h2>NO GAME SELECTED</h2>
                }
            </>
            : <></> }


            {activeMenu===MenuChoices.TEST ? 
                <div className="full-width scroll" style={{height: '80%'}}>
                    <TeamColors />
                </div>
            : <></> }

        </>
    )
    }
}


