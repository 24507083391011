import { FaFootballBall } from "react-icons/fa";
import * as Images from '../images';
import React, { useState } from "react"

//
// SIMPLE BOX SCORE
//
export default function GameInfoLive2({gameInfo, homePoss, updaterTime })
{
  const [possession, setPossession] = useState(null);
    let GID=gameInfo.GID;

    let awayPath=Images.TeamImagePaths[gameInfo.AwayTID];
    let awayRank=gameInfo.AwayRank;
    let awayTeam=gameInfo.AwayTeam;
    let homePath=Images.TeamImagePaths[gameInfo.HomeTID];;
    let homeTeam=gameInfo.HomeTeam;
    let homeRank=gameInfo.HomeRank;

return (
  <>
      <table className="blacktbl">
      <colgroup>
        <col style={{ width: '10%', textAlign: 'left'}} />
        <col style={{ width: '10%', textAlign: 'left'}} />
        <col style={{ width: '5%',  textAlign: 'right'}} />
        <col style={{ width: '30%', textAlign: 'left' }} />
        <col style={{ width: '20%', textAlign: 'right' }} />
        <col style={{ width: '25%', textAlign: 'right' }} />
      </colgroup>
      <tbody>
      <tr className='small-row'>
            <td><img className='icon-tiny2' src={awayPath} alt={""}></img></td>
            <td style={{textAlign: 'center'}}>{homePoss === false?<FaFootballBall />:''}</td>
            {awayRank <= 25?<td className='gray px10'>{awayRank}</td>:<td></td>}
            <td>{awayTeam}</td>
            <td>{gameInfo.AwayScore}</td> 
            <td style={{color: 'khaki'}}>{gameInfo.Status}</td> 
      </tr>
      <tr className='small-row'>
      <td><img className='icon-tiny2' src={homePath} alt={""}></img></td> 
      <td style={{textAlign: 'center'}}>{homePoss === true?<FaFootballBall />:''}</td>
      {homeRank <= 25?<td className='gray px10'>{homeRank}</td>:<td></td>}
      <td>{homeTeam}</td>
      <td>{gameInfo.HomeScore}</td> 
      </tr>
      </tbody>
      </table> 
  </>
  )
}

