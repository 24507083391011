import React, { useState, useEffect } from "react"
import * as Images from '../images';
import * as GlobalData from '../data';

export default function TeamColors ()
{
    const [teamColors, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_TEAM_COLORS";
          console.log (url)
          const response = await fetch(url, {
              method: 'GET', // or 'POST', 'PUT', etc.
              headers: {
                  'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                  'Content-Type': 'application/json'
              }
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
              setData(result);
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };

      fetchData();
  
  
  }, []);  
  
    if (loading) 
      return (<h2>Loading...</h2>);
    else if (error) 
      return (<h2>Error: {error}</h2>);
    else  
    return (
        <table>
            <tbody>
            {teamColors.map((item, index) => (
                    <tr key={index}>
                        <td><img className='icon-tiny' src={Images.TeamImagePaths[item.TID]} alt={""}></img></td>
                        <td style={{backgroundColor: item.bgColor, color: item.fgColor, fontSize: '20px', fontStyle: 'oblique', fontWeight: 'bold'}}>{item.Name}</td>
                        <td style={{backgroundColor: item.bgColor, color: item.fgColor}}>{item.ShortName}</td>
                    </tr>
                  ))}

            </tbody>
        </table>
    )
}