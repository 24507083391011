import * as Images from '../images';
import * as GlobalData from '../data';

import React, { useState, useEffect } from "react"

export default function TeamsOnBye({placement, week, confID, scoresLevel})
{
    const [teamsonbye, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    var confteamsonbye = [];

    useEffect(() => {
      // Function to fetch data
      const fetchData = async () => {
        try {
          const url = "https://www.cfbdungeon.com/php/cfbx.php?Xaction=GET_TEAMS_ON_BYE&Week=" + week;
          // console.log (url)
          const response = await fetch(url, {
            method: 'GET', // or 'POST', 'PUT', etc.
            headers: {
                'Authorization': `Bearer ${GlobalData.apiKey}`, // Include the API key
                'Content-Type': 'application/json'
            }
        });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          
           setData(result);
        } 
        catch (error) {
          setError(error.message);
        } 
        finally {
          setLoading(false);
        }
      };
  
      if (week > 0) fetchData();
  
  }, [week]);  
  
  useEffect(() => {
    confteamsonbye = FilterByesByConf (teamsonbye, confID);
    // console.log ("Bye useEffect...FilterByesByConf id="+confID+" byes "+confteamsonbye.length)
  }, [confID, scoresLevel]);  

    if (loading) 
      return (<h2>Loading...</h2>);
    else if (error) 
      return (<h2>Error: {error}</h2>);
    else 
    {
      confteamsonbye = FilterByesByConf (teamsonbye, confID);
      // console.log ("Bye else...FilterByesByConf id="+confID+" byes "+confteamsonbye.length)

          return (
        <div className={placement}>
            <br></br>
            <h2 className="cyan">TEAMS ON BYE</h2>
            <br></br>
            <table className="table-dark half-width centered-table" >
            {scoresLevel===1?
            <tbody>
                {confteamsonbye.map((item, index) => (
                <tr key={index}><td><img className='icon-tiny' src={Images.TeamImagePaths[item.TID]} alt={""}></img></td><td>{item.Name}</td></tr>
              ))}
            </tbody>
            :
            <tbody>
                {teamsonbye.map((item, index) => (
                    <tr key={index}><td><img className='icon-tiny' src={Images.TeamImagePaths[item.TID]} alt={""}></img></td><td>{item.Name}</td></tr>
                  ))}
            </tbody>
            }
            </table>
            <br></br>

        </div>
    )
    }
}

function FilterByesByConf (byes, confID)
{
    var list = []
    if (byes != null)
      for (var i=0; i<byes.length; i++) 
        if (byes[i].ConfID === confID)  list.push (byes[i]);

    return list;
}